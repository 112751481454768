<template>
    <v-card flat style="width: 100%;">
        <div v-if="editmode==='html'"  style="width:100%">
            <div ref="codeedit" id="codeeditwrapper"  style="width:100%">
                <SharedEditorCode :data="content" :handleChange="handleUpdate" />
            </div>
        </div>
        <div v-else  style="width:100%">
            <div ref="richedit" id="richeditwrapper"  style="width:100%">
                <SharedEditorTiptap :value="content" :handleChange="handleUpdate" />
            </div>
        </div>
        <v-divider class="mx-4"></v-divider>
        <v-card-actions v-if="handleSave">
            <v-btn text outlined @click="handleSaveClick">{{ loc('Save') }}</v-btn>
            <v-btn text outlined color="primary" @click="handleSaveClick('close')">{{ loc('Save and Close') }}</v-btn>
            <v-btn text outlined color="secondary" @click="handleClose">{{ loc('Cancel') }}</v-btn>
            <v-btn text outlined v-if="editmode==='html'" @click="toggleMode">{{ loc('View Mode') }}</v-btn>
            <v-btn text outlined v-else @click="toggleMode">HTML</v-btn>
            <v-spacer></v-spacer>
            <v-btn v-if="handleRemove" text outlined @click="handleRemove" color="red">{{ loc('Remove Block') }}</v-btn>
        </v-card-actions>
        <v-card-actions v-else>
            <v-spacer></v-spacer>
            <v-btn text outlined v-if="editmode==='html'" @click="toggleMode">{{ loc('View Mode') }}</v-btn>
            <v-btn text outlined v-else @click="toggleMode">HTML</v-btn>
        </v-card-actions>        
    </v-card>
</template>

<script>
import { mapState } from 'vuex';
// import SharedEditorQuill from './Shared_Editor_Quill';
import SharedEditorTiptap from './Shared_Editor_TipTap2.vue';
import SharedEditorCode from './Shared_Editor_Code';

export default {
    name: "SharedEditor",
    props: {
        record: {
            type: [Object,String],
            default: ""
        }, 
        handleChange: {
            type: [Function, Boolean]
        },
        handleSave: {
            type: [Function, Boolean]
        },
        handleClose: {
            type: [Function, Boolean]
        },
        handleRemove: {
            type: [Function, Boolean]
        }  ,
        field: {
            type: [String, Object]
        }      
    },
    data() {
        return {
            editmode: 'rich',
            videourl: this.record.video,
            videoplacement: this.record.videoplacement || "right",
            content: (typeof this.record === "string") ? this.record : (this.field) ? this.record[this.field] : this.record.body, // this is the "original" content.
            livecontent: (typeof this.record === "string") ? this.record : (this.field) ? this.record[this.field] : this.record.body, // this is the content we'll mutate
        };
    },
    components: { SharedEditorTiptap, SharedEditorCode },
    computed: {
        ...mapState(['users','localize', 'siteconfig'])
    },
    methods: {
        handleCancel () {
            this.handleSave(this.content);
            this.handleClose();
        },
        handleUpdate (value) {
            if(!value || !value.length) return;
            value = value.replace(/rel="noopener noreferrer nofollow"/g,'');
            this.livecontent = value;
            // console.log("Shared_Editor.handleUpdate", value);
            this.handleChange && this.handleChange(value, this.field);
        },
        handleSaveClick (action) {
            this.handleSave && this.handleSave(this.livecontent, this.field);
            if(action === 'close') this.handleClose()
        },
        toggleMode () {
            this.content = this.livecontent;
            this.editmode = this.editmode === "html" ? "rich" : "html";
        },
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },        
    },
    mounted() {
        // console.log("Shared_Editor mounted", this.record, this.field);
        // console.log(this.siteconfig)
     },
    beforeDestroy() { }
};
</script>

<style scoped>
</style>
<style>
</style>