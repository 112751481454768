<template>
    <div class="commentList my-3 pt-12" >
        <div v-if="isLoading">
            <v-container  v-html="loc('Loading Comments...')"></v-container>
        </div>
        <v-row v-else-if="!recordList || !recordList.length" class="mb-10">
            <h3>{{ loc("No records found.") }}</h3>
        </v-row>
        <div v-else >
            <v-row v-for="(item) in recordList" :key="item._id" class="mb-10">
                <v-col>
                    <v-row v-if="!isSelf">
                        <div class='label'>{{ loc("Author") }}</div>
                        <div class='value pb-10'>{{ item.author+" - "+item.authorname }}</div>
                    </v-row>                
                    <v-row v-if="item.courseId">
                        <div class='label'>{{ loc("Course") }}</div>
                        <div class='value'>{{ courseTitle(item) }}</div>
                    </v-row>
                    <v-row v-if="item.courseId">
                        <div class='label'>{{ loc("Context") }}</div>
                        <div class='value'><router-link :to="contextPath(item)">{{contextPath(item)}}</router-link></div>
                    </v-row>                
                    <v-row v-if="item.courseId">
                        <div class='label'>{{ loc("Class") }}</div>
                        <div class='value'>{{ (classes.list && classes.list[item.context] && classes.list[item.context].name) || item.context }}</div>
                    </v-row>
                    <v-row v-if="!item.courseId">
                        <div class='label'>{{ loc("Context") }}</div>
                        <div class='value'>{{ loc('The context is missing for this comment')}} </div>
                    </v-row>
                    <v-row>
                        <div class='label'>{{ loc("Time") }}</div>
                        <div class='value'>{{ item.timestamp }}</div>
                    </v-row>
                    <v-row>
                        <div class='label'>{{ loc("Comment") }}</div>
                        <div class='value pb-10'>{{ item.body }}</div>
                    </v-row>
                    <v-row>
                        <div class='label'>{{ loc("Replies") }}</div>
                        <div class='value pb-10'>{{ (item.replies && item.replies.length) || loc("None") }}</div>
                    </v-row>                
                    <v-divider ></v-divider>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import _orderBy from 'lodash/orderBy';

export default {
    name: "UserCommentList",
    props: {
        userId: { type: String },
        record: { type: [Object, Boolean], default: false }

    },
    data() {
        return {
            isLoading: true,
            userRecord: this.record, // obj
            isEditor: false
        }
    },
    computed: {
        ...mapState(["authError", "users", "comments", "classes", "courses", "localize"]),
        recordList: function() {
            if(!this.comments.list) return [];
            // let list = this.comments.list.slice(0);
            // list = _orderBy(list, ['timestamp'], ['desc']);
            // console.log("Making comments recordList", list);
            // return list;
            return _orderBy(this.comments.list, ['timestamp'], ['desc']);
        },
        classList: function() {
            return this.classes.list
        },
        isSelf() {
            return this.userId === this.users.currentUser._id;
        }
    },
    methods: {
        getComments: function(){
            this.isLoading = true;

            // const foo = () => { console.log("foo");};

            this.$store.dispatch("comments/getList", {author: this.userId })
                .then((result) => {
                    this.isLoading = false; 
                    console.log("User Comments List result", this.isLoading, result);
                    
                })
                .catch((e) => { console.log("ERROR in user comments getComments", e); });
        },
        courseTitle: function(item){
            if(!this.courses || !this.courses.list) return "";
            if(!this.classes || !this.classes.list) return "";

            if(item.courseId && this.courses.list[item.courseId]) return  this.courses.list[item.courseId].reftitle + " - " + this.courses.list[item.courseId].title;

            return (
                (
                    item.context && 
                    this.classes.list[item.context] && 
                    this.classes.list[item.context].course && this.courses.list[this.classes.list[item.context].course] &&
                    this.courses.list[this.classes.list[item.context].course].reftitle + " - " + this.courses.list[this.classes.list[item.context].course].title
                ) || ""
            )

        },
        contextPath: function(item){
            // return "/comments/" + item.parent + "/" + item.context + "/" + item._id;
            return `/lessons/${item.courseId}/${item.lessonId}/${item.classId}/${item.parent}`;
        },
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },
        init: function(path) {
            path = path || this.$route.params.pathMatch;
            console.log('user comments init', path)
            this.getComments();
            if(!this.courses.list) this.$store.dispatch("courses/getList")
            if(!this.classes.list) this.$store.dispatch("classes/getList")
        }
    },
    created () {
        this.init();
    },
    watch: {
        '$route.params.id': {
            handler: function(s){
                let id = s || (this.$route.params && this.$route.params.pathMatch);
                if(this.userId !== id) this.init(id);
            },
            deep: true,
            immediate: false
        }        
    }
}
</script>        
