import Vue from 'vue';
import Router from 'vue-router';
import store from './store'

import Login from '../components/User_Login.vue';
import Page from '../components/Page.vue';
import Classes from '../components/Classes.vue';
import Comments from '../components/Comments.vue';
import Course from '../components/Course.vue';
import Lesson from '../components/Lesson.vue';
import Register from '../components/User_Register.vue';
import PasswordReset from '../components/User_Password_Reset.vue';
import User from '../components/User.vue';
import Util from '../components/Util.vue';

Vue.use(Router);

let router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/login',
            name: "login",
            alias: '/user/login',
            component: Login,
            meta: { requiresNoAuth: true }
        },
        {
            path: '/register',
            name: "register",
            alias: ['/signup', '/user/register'],
            component: Register,
            meta: { requiresNoAuth: true }
        },     
        {
            path: '/passwordreset',
            name: 'passwordreset',
            alias: '/user/passwordreset',
            component: PasswordReset
        }, 
        { 
            path: '/admin',
            name: 'admin-home',
            component: () => import(/* webpackChunkName: "admin" */ '../components/Admin.vue'),
        },
        {
            path: '/admin/:panel',
            name: 'admin-panel',
            component: () => import(/* webpackChunkName: "admin" */ '../components/Admin.vue')
        },
        {
            path: '/admin/:panel/*',
            name: 'admin',
            component: () => import(/* webpackChunkName: "admin" */ '../components/Admin.vue')
        },    
        {
            path: '/lessons/:courseid/:lessonid/:classid?/:materialid?/:commentid?',
            // alias: '/lessons/:courseid/:lessonid',
            name: 'lesson',
            component: Lesson
        },
        {
            path: '/Course/:courseid',
            name: 'course',
            alias: ['/Courses/:courseid', '/lessons/:courseid'],
            component: Course
        }, 
        {
            path: '/Course/:courseid/:classid',
            name: 'course-class',
            component: Course
        },    
        {
            path: '/Course/:courseid/:classid/*',
            name: 'course-class-extended',
            component: Course
        },               
        {
            path: '/comments',
            name: 'Comments-root',
            alias: ['/Comments', '/comment'],
            component: Comments
        },  
        {
            path: '/comments/*',
            name: 'comments',
            alias: ['/discussion/:id'],
            component: Comments
        },       
        // {
        //     path: '/comments/:id/*',
        //     name: 'comments_class',
        //     alias: ['/discussion/:id/*'],
        //     component: Comments
        // },       
          
        // {
        //     path: '/Classes',
        //     name: 'class',
        //     alias: '/myclasses',
        //     component: Course
        // },     

        {
            path: '/class/:panel/:classid?',
            name: 'class',
            alias: '/classes/:panel/:classid?',
            component: Classes
        },    

        // {
        //     path: '/class/:panel?',
        //     name: 'class',
        //     alias: '/classes/:panel?',
        //     component: Classes
        // },    
        {
            path: '/user/:panel/*',
            name: 'user-extended',
            alias: '/users/:panel/*',
            component: User,
        },  
        {
            path: '/user/:panel?',
            name: 'user',
            alias: ['/users/:panel?' ],
            component: User,
        },  
        {
            path: '/util/:panel',
            name: 'util',
            component: Util,
        },  

        {
            path: '/:id/:subid?',
            name: 'page',
            component: Page
        },
        {
            path: '*',
            name: 'default',
            component: Page
        }
    ],
    scrollBehavior(to, from, savedPosition) {
        console.log('scrollBehavior', to);
        if(to.hash){
            console.log('routing has hash', to.hash)
            let hash = to.hash.slice(1);
            let el = document.getElementById(hash)
            if(el){ 
                el.scrollIntoView({ behavior: 'smooth' });
            } else {
                el = document.getElementById('coramdeo')
                if(el) el.scrollIntoView({ behavior: 'smooth' });
            }
            // return { 
            //     el: to.hash, 
            //     behavior: 'smooth'
            // }
        } else if(savedPosition){
            return savedPosition
        } else {
            let el = document.getElementById('coramdeo');
            if(el) el.scrollIntoView({behavior: 'smooth'});
            // return {
            //     el: "#coramdeo",
            //     behavior: "smooth"
            // }
        }
        // document.getElementById('coramdeo').scrollIntoView({ behavior: 'smooth' });
    }
});


router.beforeEach((to, from, next) => {
    // console.log('routing', window._nppn, to);

    const checkroute = function () {
        if (store.state.initDone){
            if((to.path === "/" || to.path === "/app") && window._nppn && window._nppn.length){
                window.location = window._nppn;
            } else if (to.matched.some(record => record.meta.requiresAuth)) {
                if (store.getters['users/currentUser']) {
                    next();
                } else {
                    next({
                        path: '/login',
                        query: { redirect: to.fullPath }
                    });
                }
            } else if (to.matched.some(record => record.meta.requiresNoAuth) && store.getters['users/currentUser']){
                next({
                    path: '/'
                });
            } else {
                console.log('Route to.path:',to.path);
                next();
            }
        } else {
            setTimeout(checkroute, 333);
        }
    }
    checkroute();
});
// setInterval(function () { console.log('checking for currentUser', (Date.now() - starting), store.getters.currentUser) }, 300);
export default router;