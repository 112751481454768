<template>
    <div :class="'User user-'+panel">
        <div :id="'pagetop-' + panel" class="pagetop">
            <div :id="'pagelead-' + panel" class="pagelead">
                <AppBanner />
            </div>
            <div class="firstblock fullwrapper" id="userpageintroblock" >
                <v-container class="pt-6 contentwrapper"  >
                    <ContentBlockSimple  :record="{type:'snippet', _id:'userpageintro'}"  />
                </v-container>
            </div>
        </div>
        <div v-if="panel === 'enroll'">
            <UserEnroll :record="userRecord" :userId="userRecord._id" :subpanel="subpanel" />
        </div>
        <div v-else-if="!userRecord && panel !== 'enroll'" id="isLoading" class="isLoading">
            <v-container  v-html="loc('Loading...') + ' no user record'">
            </v-container>
        </div>
        <div v-else>
            <v-container class="pt-6 contentwrapper userpanel"  >
                <v-row>
                    <v-col cols="6"><h1>{{ panelTitle(panel) }}</h1></v-col>
                    <v-col class='align-end' cols="6" v-if="!noProfileNav" >
                        <v-btn v-if="panel && panel !== 'profile'" color="primary" class="ml-4 mb-4" :to="'/user/profile/'+ userRecord._id">{{ panelTitle('profile') }}</v-btn>
                        <v-btn v-if="panel !== 'comments'" color="primary" class="ml-4 mb-4" :to="'/user/comments/'+ userRecord._id">{{ panelTitle('comments') }}</v-btn>
                        <v-btn v-if="panel !== 'enrollment'" color="primary" class="ml-4 mb-4" :to="'/user/enrollment/'+ userRecord._id">{{ panelTitle('enrollment') }}</v-btn>
                        <v-btn v-if="isSelf && panel !== 'changepassword'" color="primary" class="ml-4 mb-4" to="/user/changepassword">{{ panelTitle('changepassword') }}</v-btn>
                    </v-col>
                </v-row>  
                <div v-if="panel === 'enrollment'">
                    <v-row>
                        <UserEnrollmentList :record="userRecord" :userId="userRecord._id" />
                    </v-row>
                    <v-row v-if="instructor">
                        <UserInstructorList :userRecord="userRecord" :classList="instructor" />
                    </v-row>
                    <v-row v-if="userRecord.certifications || (siteconfig && siteconfig.createclasswithoutcerts)">
                        <v-btn color="primary" to="/Class/create" >{{ loc('Create a New Class') }}</v-btn>
                    </v-row>

                </div>
                <div v-else-if="panel === 'enroll'">
                    <UserEnroll :record="userRecord" :userId="userRecord._id" :subpanel="subpanel" />
                </div>
                <div v-else-if="panel === 'comments'">
                    <UserCommentList :record="userRecord" :userId="userRecord._id" />
                </div>
                <div v-else-if="panel === 'changepassword'">
                    <UserPasswordChange :record="userRecord" :userId="userRecord._id" />
                </div>                
                <div v-else>
                    <UserProfile :record="userRecord" :userId="userRecord._id" />
                </div>
            </v-container>
        </div>
        <div v-if="users.currentUser && false">
            <v-container class="mt-5">
                <v-row><v-divider></v-divider></v-row>
                <v-row>Panel: {{ panel }}</v-row>
                <v-row>SubPanel: {{ subpanel }}</v-row>
                <v-row>User: {{ userId }}</v-row>
                <v-row v-if="userRecord.roles">Roles: {{ userRecord.roles }} </v-row>
                <v-row><pre>{{siteconfig}}</pre></v-row>
            </v-container>
        </div>
    </div>

</template>

<script>
import { mapState } from 'vuex';
import UserProfile from './User_Profile';
import UserEnroll from './User_Enroll';
import UserEnrollmentList from './User_Enrollment_List';
import UserInstructorList from './User_Instructor_List';
import UserCommentList from './User_Comments_List';
import UserPasswordChange from './User_Password_Change';
import ContentBlockSimple from './Content_Block_Simple';
import AppBanner from './App_Banner';
import _upperFirst from 'lodash/upperFirst';
import _sortBy from 'lodash/sortBy';
import _filter from 'lodash/filter';

const schema = {
    enroll: true,
    enrollment: true,
    comments: true,
    profile: true,
    changepassword: true
};

export default {
    name: "User",
    metaInfo () {
        return {
            title: "Account " + ((this.panel && _upperFirst(this.panel)) || "Profile")
        }
    },
    data () {
        return {
            isLoading: true,
            pathRecord: false,
            pathUserId: false,
            panel: false,
            subpanel: false,
            noProfileNav: false,
            isLoggedInRetries: 0
        }
    },
    components: { AppBanner, UserEnroll, UserEnrollmentList, UserInstructorList, UserCommentList, UserProfile, UserPasswordChange, ContentBlockSimple },
    computed: {
        ...mapState(["users", "classes", "enrollment", "localize", "classes", "siteconfig"]),
        // panel() {
        //     return this.$route.params.panel;
        // },
        userId() {
            return (this.pathUserId || this.users.currentUser._id);
        },
        isSelf(){ return this.pathUserId === this.users.currentUser._id },
        userRecord() {
            return (this.pathUserId && this.pathRecord) || false;
        },
        instructor: function() {
            let classlist = false;
            if(this.classes.list){
                classlist = _sortBy(_filter(this.classes.list, {instructor: this.userRecord._id}), 'startdate')
            }
            return classlist || false;
        }        
    },
    methods: {
        panelTitle: function(panel){
            panel = panel || this.panel;

            switch(panel){
                case 'enroll':
                    return this.loc("Enroll in a Course");
                case 'enrollment':
                    return (this.isSelf) ? this.loc("My Classes") : this.loc("Classes");
                case 'comments':
                    return (this.isSelf) ? this.loc("My Comments") : this.loc("User Comments");
                case 'changepassword':
                    return this.loc("Change Password");
                default:
                    return this.loc('User Profile');
            }
        },
        init: function(options){
            console.log("User.init", options)
            options = options || {};
            let panel, id;



            if(options.panel && !options.pathMatch){
                if(schema[options.panel]){
                    this.panel = options.panel;
                    this.pathUserId = undefined;
                } else {
                    this.panel = "profile";
                    this.pathUserId = options.panel;
                }
            } else if(options.pathMatch && options.pathMatch.indexOf('/') > -1){
                this.panel = options.panel || 'profile';
                let p = options.pathMatch.split('/');
                this.pathUserId = p.pop();
                this.subpanel = p.pop();
            } else if(options.pathMatch && schema[options.pathMatch]){
                this.panel = options.pathMatch;
                if(this.panel === "enroll") this.subpanel = "enroll";
                this.pathUserId = false;
            } else {
                this.panel = options.panel || 'profile';
                if(this.panel === "enroll"){
                    this.pathUserId = false;
                    this.subpanel = options.pathMatch;
                } else {
                    this.pathUserId = options.pathMatch;
                }
            }

            if(this.users && this.users.currentUser && !this.pathUserId){
                this.pathUserId = this.users.currentUser._id;
                this.pathRecord = this.users.currentUser;
                console.log("init user fallback");
            }

            this.noProfileNav = !!(this.panel === "enroll");

            if(!this.users || !this.users.currentUser){
                if(this.isLoggedInRetries < 2){
                    this.isLoggedInRetries++;
                    console.log("Let's try that again in half a second");
                    setTimeout(() => { this.init(options) }, 500)
                    return;
                } else if(this.panel !== 'enroll') {
                    console.log("Redirecting to login", this.users && this.users.currentUser)
                    this.$router.push('/login');
                    return;
                }
            } else if(this.pathUserId !== this.users.currentUser._id && !this.users.currentUser.isAdmin){
                    let newpath = "/user/" + this.panel;
                    this.$router.push(newpath);
                    return;
            } else {
        
                console.log("User.init set", this.panel, this.pathUserId, this.pathRecord);
                if(!this.pathRecord && this.pathUserId  ){
                    return this.$store.dispatch("users/get", {_id: this.pathUserId})
                        .then((result) => {
                            console.log("users.get result", options, result);
                            this.pathRecord = result;
                            this.isLoading = false;
                        })
                }
            }
            
        },
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        }
    },
   mounted () {
       console.log("User module mounted", this.$route.params);
       this.init(this.$route.params); // this.init(this.$route.params.pathMatch || this.$route.params.panel);
    },
    watch: {
        '$route.params.id': {
            handler: function(val, oldVal){
                console.log("User.watch.route", val, oldVal);
                let id = val || this.$route.params; // (this.$route.params && (this.$route.params.pathMatch || this.$route.params.panel));
                this.init(id);
                // if(this.pathUserId !== id) this.init(id);
            },
            deep: true,
            immediate: false
        }
    }
}
</script>
