<template>
    <div id="createclass">
        <v-container v-if="courseList && courseList.length">
            <v-row v-if="errorMessage">
                <v-col id="editclass-intro" style="min-height:130px;" >
                    <v-alert dense type="error">{{errorMessage}}</v-alert>
                </v-col>
            </v-row> 
            <v-row v-else>
                <v-col id="editclass-intro" style="min-height:130px;" class='feature' >
                    <ContentBlockSimple  :record="(!classRecord._id || classRecord._id === 'new') ? 'classcreateintro' : 'classeditintro' "  />
                </v-col>
            </v-row>        
            <v-row>
                <h1>{{  (!classRecord._id || classRecord._id === 'new') ? loc('Create a New Class') : (loc('Edit Class') + ' - ' + classRecord.name) }}</h1>
            </v-row>
            <v-row>
                <v-text-field name="name" v-model="record.name"  :label="loc('Class Name')"></v-text-field>
            </v-row>
            <v-row>
                <v-select name="course" v-model="record.course"  :items="courseList" :label="loc('Course')"></v-select>
            </v-row>
            <v-row>
                <v-text-field name="startdate" v-model="record.startdate"  :label="loc('Start Date')"></v-text-field>
            </v-row>
            <v-row>
                <v-text-field name="weeks" v-model="record.weeks"  :label="loc('How many weeks to allow persons to register after the class Start Date.')"></v-text-field>
            </v-row>            
            <v-row>
                <v-btn color="primary" @click="handleSave" >{{ loc('Save') }}</v-btn>
            </v-row>
        </v-container>
        <v-container v-else>
            <v-row>{{ loc('Checking courses') }}</v-row>
        </v-container>

    </div>
</template>

<script>
import { mapState } from 'vuex';
import ContentBlockSimple from './Content_Block_Simple';
import { makeId } from '../lib/filter.js';
import _sortBy from 'lodash/sortBy';

export default {
    name: "ClassCreate",
    metaInfo () {
        return {
            title: "Create a class"
        }
    },
    props: {
        classRecord: { 
            type: Object, 
            default: () => ({})
        }
    },
    data () {
        return {
            record: this.classRecord,
            errorMessage: false
        };
    },
    components: { ContentBlockSimple },
    computed: {
        ...mapState(["users", "classes", "courses", "localize", "siteconfig"]),
        courseList: function(){
            let certs = this.users && this.users.currentUser && this.users.currentUser.certifications;
            let isAdmin = this.users && this.users.currentUser && this.users.currentUser.isAdmin;
            let openCreate = this.siteconfig && this.siteconfig.createclasswithoutcerts;
            if(!certs && !isAdmin && !openCreate) return [];

            let out = [];
            for(let k in this.courses.list){
                // if(isAdmin || certs[k]){
                if(this.courses.list[k].status !== 'Inactive' && (openCreate || certs[k]) && this.courses.list[k].lang === (this.users.currentUser.lang || 'en')){
                    out.push({
                        text: this.courses.list[k].title,
                        value: k,
                        order: this.courses.list[k].order
                    })
                }
            }
            out = _sortBy(out, ['order'])
            return out;
        }

    },
    methods: {
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },
        handleSave: function () {

            if(!this.record.instructor) this.record.instructor = this.users.currentUser._id;
            if(!this.classRecord._id || this.classRecord._id === 'new'){
                this.record.regcode = this.record.regcode || makeId(8);
            }

            this.$store.dispatch("classes/save", this.record )
                .then((result) => {
                    console.log("result from save in ClassEdit", result)
                    this.record = result;
                    if(!result || !result._id){
                        this.errorMessage = this.loc("There was a problem saving the class.  Please see the logs or check with Support for details.")
                    } else if(!this.classRecord._id || this.classRecord._id === 'new'){
                        this.$emit("actionhandler", "setpanel", "confirmation", result);
                    } else {
                        this.$router.push("/Course/"+this.record.course+"/"+this.record._id);
                    }
                })
                .catch((e) => {
                    console.log(e);
                    this.errorMessage = this.loc("There was a problem saving the class.  Please see the logs or check with Support for details.")
                })
        }
    }
}
</script>