<template>
    <v-container class="LMSAdmin">
        <v-row v-if="users.loggedout || !users.currentUser">
             <router-link to="/login">{{ loc('Please Login') }}</router-link>
        </v-row>
        <div v-else-if="panel === 'store'">
            <UtilStoreExplorer />
        </div>
        <div v-else>
            {{ panel}}
        </div>
    </v-container>
</template>

<script>
import { mapState } from "vuex";
import UtilStoreExplorer from './Util_Store_Explorer';
export default {

    data () {
        return {
            panel: false
        }
    },
    components: {
        UtilStoreExplorer
    },
    computed: {
        ...mapState(["users", "localize"])
    },
    methods: {
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },
    },
    created () {
        this.panel = this.$route.params.panel;
    },
    watch: {
        '$route.params.id': {
            handler: function(s){
                if(this.$route.params.panel && this.panel !== this.$route.params.panel) this.panel = this.$route.params.panel;
            },
            deep: true,
            immediate: false
        }
    }

}
</script>        
