import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
// import store from './store';

const axiosconfig = { headers: {} };

export default {
    namespaced: true,
    state: {
        languages: {
            en: {
                // "_sitelogo": "/logos/coramdeo-en.png"
                "_sitelogo": "/logos/coramdeo-en-wht.png"
            }
        },
        timestamp: 0
    },
    mutations: {
        setLanguage (state, val) {
            if(!val || !val.key || !val.list) return;
            console.log("store_localize.setLanguage mutation", val);
            val.list._timestamp = val.list._timestamp || Date.now();
            let nopersist = false;
            if(val.nopersist){
                nopersist = true;
                delete val.nopersist;
            }
            if(!state.languages[val.key]) state.languages[val.key] = {};
            Vue.set(state.languages, val.key, val.list);
            if(!state.timestamp || val.list._timestamp > state.timestamp) state.timestamp = val.list._timestamp || Date.now();
            if(!nopersist && state.languages) localStorage.setItem('lms_loc', JSON.stringify(state.languages) );
        },
        setList (state, val) {
            if(!val) return;
            for(let k in val){
                if( k === "_sys" || k.match(/^\w{2}$/) ){ 
                    if(!state.languages[k]) Vue.set(state.languages, k, {});
                    // state.languages[k] = {};
                    for(let f in val[k]){
                        Vue.set(state.languages[k], f, val[k][f]);
                    }
                }
            }
        }
    },
    actions: {
        init ( {commit, state }, data) {
            let loc = localStorage.getItem('lms_loc');
            if(loc && loc != 'undefined'){  
                loc = JSON.parse(loc);
                commit('setList', loc);
            }
        },
        getLanguage ({ commit , state }, data) {
            return axios.post("/app_admin/getLanguage", data, axiosconfig)
            .then((response) => {
                let result = response && response.data;
                if(result){ 
                    commit('setList', result); 
                }
                return result;
            })
        },
        save ({ commit, state, dispatch }, data) {
            return axios.post('/app_admin/setLanguage', data, axiosconfig)
                .then((response) => {
                    let result = response && response.data;
                    // if(result) commit('setSiteConfigValues', result);
                    // if(result) commit('setConfigItem');
                    dispatch('setLanguage', result);
                    return result;
                })            
        },

        /** 
         * Set language strings.  Setting the user language preference is done in store_users
         * */
        setLanguage ({ commit, state }, data) {
            if(data){
                for(let k in data){
                    if( k.match(/^\w{2}$/) ) commit('setLanguage', { key: k, list: data[k] })
                }
            }
        }
    }
}