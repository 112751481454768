<template>
    <div>
        <v-row justify="space-between" class="progressrow ml-4" >
            
            <v-col v-if="courseRecord.timerequired" md="auto" sm="12">
                <v-icon small>timer</v-icon> <span class='coursetime' v-html="loc(courseRecord.timerequired)" />
            </v-col>
            <v-col md="auto" sm="12" v-else style="display:none"> </v-col>

            <v-col md="auto" sm="12">
                {{lessonCount}} {{ loc('Lessons') }} 
                <span v-if="progress.prevent && progress.percentcomplete && progress.classes && progress.classes.length === 1">({{loc('You have viewed')}} {{ progress.percentcomplete }}%) </span>
            </v-col>


            <v-col v-if="progress.started && progress.classes && progress.classes.length === 1" md="auto" sm="12" @click="handleClick"  style="display:none">
                <v-icon small>outlined_flag</v-icon> <span :to="lastLessonRoute">{{loc('Last Viewed') + ' ' + progress.lastlessonorder }} </span>
            </v-col>
         
            <v-col v-else-if="isInstructor" md="auto" sm="12">
                {{ loc('Instructor') }}
            </v-col>
            <v-col v-else-if="progress.started && progress.classes && progress.classes.length"  md="auto" sm="12" @click="handleClick"  style="display:none">
                <v-icon small>outlined_flag</v-icon> <span class='courseaction' v-html="progress.classes.length + ' ' + loc('Classes')" />
                <div style="display:none"><pre>{{progress}}</pre></div>
            </v-col>
            <v-col v-else-if="progress.classes.length" md="auto" sm="12" @click="handleClick"  style="display:none">
                <v-icon small>outlined_flag</v-icon> <span class='courseaction'>{{ loc('Not Started Yet') }}</span>
            </v-col>
            <v-col v-else md="auto" sm="12" style="display:none">
                <v-icon small>outlined_flag</v-icon> <span class='courseaction' @click="handleClick('enroll')">{{ loc('Join the Class') }}</span>
            </v-col>

        </v-row>
        <pre style="display:none">{{ progress }}</pre>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import _filter from 'lodash/filter';
import { filterDate } from '../lib/filter';

var progressretry = 0;

export default {
    name: "CourseProgress",
    props: {
        courseRecord: {
            type: Object,
            default: () => ({})
        },
        options: {
            type: Object,
            default: () => ({})
        }
    },
    data () {
        return {}
    },
    components: {},
    computed: {
        ...mapState(['siteconfig', 'users', 'localize', 'courses', 'enrollment', 'lessons']),
        progress: function(){
            let userid = this.users.currentUser._id;
            // let enrollment = this.enrollment;
            let courseid = this.courseRecord._id;

            let out = {
                userid: userid,
                courseid: courseid,
                classes: [],
                started: false,         // date
                lastlesson: false,      // id
                lastlessonorder: false,
                lastlessontime: false,  // date
                lastquiz: false,        // id
                lastquiztime: false,    // date
                lessonsvisited: 0,      // number
                quizzes: 0,             // number
                exampassed: "",          // date or false
                prevent: true
            };
            // if(!userid || !this.enrollment || !this.enrollment.list || !this.enrollment.list[userid]){ 
            //     if(progressretry > 4){
            //         console.log("CourseProgress enrollment data not accessible", this.enrollment.list)
            //         return out; 
            //     } else {
            //         console.log("Going to retry progress in .5 seconds")
            //         setTimeout(this.progress, 500);
            //         progressretry++;
            //         return;
            //     }
            // }

            let list = _filter(this.enrollment.list[userid], {course: courseid});
            let ignoreids = ['null', 'false', 'none'];
            if(this.options && this.options.classid && ignoreids.indexOf(this.options.classid) < 0) list = _filter(list, {classid: this.options.classid});

            for(let i in list){
                // i is array id, don't use it.
                let record = list[i];
                // console.log('progress records', i, record);
                out.classes.push(list[i].classid);
                if(list[i].regdate){
                    out.regdate = list[i].regdate;
                } else if(list[i].timestamp) {
                    out.regdate = filterDate(list[i].timestamp);
                }
                if( list[i].percentcomplete && (!out.percentcomplete || list[i].percentcomplete > out.percentcomplete) ) out.percentcomplete = list[i].percentcomplete;

                if(!record.progress) continue;

                for(let k in record.progress){
                    // k is lesson id
                    // console.log("inspecting", k, record.progress[k])
                    
                    if(record.progress[k].firstview && (!out.started || record.progress[k].firstview > out.started)){
                        out.started = record.progress[k].firstview;
                    }
                    if(record.progress[k].lastview && (!out.lastlessontime || record.progress[k].lastview > out.lastlessontime)){
                        out.lastclass = i;
                        out.lastlesson = k;
                        out.lastlessontime = record.progress[k].lastview;
                        out.lastlessonorder = (this.lessons && this.lessons.list && this.lessons.list[k] && this.lessons.list[k].order) || ""
                    }
                    if(record.progress[k].quizdate && (!out.lastquiztime || record.progress[k].quizdate > out.lastquiztime)){
                        out.lastquiz = k;
                        out.lastquiztime = record.progress[k].quizdate;
                    }
                }
                out.lessonsvisited = _filter(record.progress, 'firstview').length;

                // console.log("building progress", this.options.classid, out)
                // if(this.options.classid && out.lessonsvisited) out.percentcomplete = record.progress[this.options.classid] && record.progress[this.options.classid].percentcomplate || 
                
                out.quizzes = _filter(record.progress, 'quizdate').length;
                if(record.exampassed) out.exampassed = record.examdate || record.exampassed || false;
            }
            // console.log("Progress out", out);
            return out;
        },
        lastLessonRoute: function(){
            return "/lessons/" + this.courseRecord._id + "/" + this.progress.lastlesson + "/" + this.progress.lastclass
        },
        lessonCount: function(){
            let list = _filter(this.lessons.list, (o) => { return (o.course === this.courseRecord._id) && o.type })// {course: this.courseRecord._id});
            // console.log(list);
            return list.length;
        },
        isInstructor: function(){
            let classId = this.options.classid || (this.progress.classes && this.progress.classes.length === 1 && this.progress.classes[0] && this.progress.classes[0]._id);
            return !!(classId && this.users && this.users.currentUser && this.users.currentUser.instructor && this.users.currentUser.instructor[classId]) 
        }   
    },
    methods: {
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },  
        handleClick: function (e) {
            let url = (e === 'enroll') ? '/users/enroll/' + this.courseRecord._id : '/lessons/' + this.courseRecord._id + '/next';
            this.$router.push(url);
        }   
        
    },
    created () {

    }
    
}
</script>