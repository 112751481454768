<template>
    <div :class="'Lesson lesson-'+courseid">
        <div v-if="classPicker">
            <div  :id="'pagetop-' + lessonid" class="pagetop">     
                <div :id="'pagelead-' + lessonid" class="pagelead">
                    <AppBanner />
                </div>
                <div v-if="lesson.title" class="firstblock fullwrapper" :id="'lead-' + lessonid">
                    <v-container class="pt-6 contentwrapper lessonlead"  >
                        <div class="contentblock">
                            &nbsp;
                        </div>
                    </v-container>
                </div>                
            </div>
            <div class="firstblock fullwrapper">
                <v-container class="pt-6 contentwrapper">
                    <h2 class='mb-12'>{{ loc("Please select a class")}}</h2>
                    <v-row>
                        <v-list flat>
                            <v-list-item v-for="(item, idx) in enrolledlist" v-bind:key="idx">
                                <v-list-item-content>
                                    <v-list-item-title ><router-link :to="item.to">{{item.name}}</router-link></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-row>
                </v-container>
            </div>                       
        </div>  
        <div v-else-if="!lesson || !lessonid" id="isLoading" class="isLoading">
            <v-container  v-html="loc('Loading...')"></v-container>
        </div>
        
        <div v-else>
            <div  :id="'pagetop-' + lessonid" class="pagetop">
                <div :id="'pagelead-' + lessonid" class="pagelead">
                    <AppBanner />
                </div>
                <div v-if="lesson.title" class="firstblock fullwrapper" :id="'lead-' + lessonid">
                    <v-container class="pt-6 contentwrapper lessonlead"  >
                        <div class="contentblock">
                            <h1>{{lesson.title}}</h1>
                        </div>
                    </v-container>
                </div>
            </div>
            <div v-if="lesson.description" class="firstblock fullwrapper lessondescriptionwrapper" :id="'description-' + lessonid">
                <div style="position:relative" v-if="!preview && isEditor">
                    <v-btn color="secondary" fab absolute small top right :to="'/admin/lessons/'+courseid +'/'+lessonid" >
                        <v-icon>settings</v-icon>
                    </v-btn>
                </div>  
                <v-container class="pt-6 contentwrapper lessondescription"  >
                    <div class="contentblock" v-html="lesson.description" v-if="!materialid || !(siteconfig && siteconfig.showeachmaterial)"> </div>
                </v-container>
            </div>
            <div v-if="siteconfig && siteconfig.showeachmaterial && !materialid" class="materialswrapper fullwrapper">
                <v-row v-if="isLoading || !materialsList" id="isLoading" class="isLoading">
                    <div v-html="loc('Loading...')"></div>
                </v-row>  
                <v-container v-else>
                    <v-list>          
                        <v-list-item v-for="(item, key) in materialsList" :id="key" :key="key">
                            <v-list-item-content>
                                <v-list-item-title>
                                    <router-link :to="'/lessons/' + courseid + '/' + lessonid + '/' + classid + '/' + key">{{item.title}}</router-link>
                                </v-list-item-title> 
                            </v-list-item-content>                   
                        </v-list-item>
                    </v-list>
                </v-container>
            </div>            
            <div v-else class="materialswrapper fullwrapper">
                <v-row v-if="isLoading || !materialsList" id="isLoading" class="isLoading">
                    <div v-html="loc('Loading...')"></div>
                </v-row>       
                <div v-else>
                    <v-container v-for="(item, key) in materialsList" :id="key" :key="key">
                    <div v-if="sectionTypes(item) === 'block'" :class="'lesson' + item.section">
                        <h2 v-if="item.title" v-html="item.title"></h2>
                        <ContentBlock :record="item" :options="materialsOptions(item)" v-on:actionhandler="handleEditorActions" />
                        <v-btn v-if="item.usebutton === 'yes' && showDiscuss(item)" small :to="'/comments/' + item._id + '/' + classRecord._id">{{ loc('Join the Discussion') }}</v-btn>
                        <CommentsEmbed v-else-if="showDiscuss(item)" class="mt-8"/>
                    </div>
                    <div v-else-if="item.section === 'Quiz'">
                        <h2 v-if="item.title" v-html="item.title"></h2>
                        <LessonQuiz :record="item" :lesson="lesson" :classid="classid" :progress="progress"  v-on:triggerinit="init" />
                    </div>
                    <div v-else-if="item.section === 'Exam'">
                        <h2 v-if="item.title" v-html="item.title"></h2>
                        <LessonExam :record="item" :lesson="lesson" :classid="classid" :progress="progress" v-on:triggerinit="init" />
                    </div>                    
                    <div v-else-if="item.section === 'Survey'">
                        <ContentBlockSurvey :record="lesson" context="Lesson" :contextid="classid" :options="{button: 'lesson'}" :sid="item.subid"  />
                    </div>                                        
                    <div v-else-if="item" :class="'lesson' + item.section">
                        <h2 v-if="item.title" v-html="item.title"></h2>
                        <pre style="display:block">{{item}}</pre>
                    </div>
                </v-container>

                </div>     
            </div>
        </div>
        <div>
            <v-container style="border-top: 1px solid #ccc;" class="mt-6">
                <v-row>
                    <v-col cols="auto">
                        {{course.title}} - {{lesson.title}}
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="4" class="d-md-none">
                        <v-icon v-if="notfirst" @click="handleMarkUnread">mdi-eye-off</v-icon>
                    </v-col>
                    <v-col  cols="8" style="text-align: right" class="d-md-none">
                        <v-icon v-if="notfirst" @click="handlePrevious">mdi-arrow-left-bold-circle</v-icon>
                        <v-icon v-if="notlast" @click="handleNext" class="mr-6">mdi-arrow-right-bold-circle</v-icon>
                    </v-col>
                    <v-col cols="auto" class="d-none d-md-flex">
                        <v-btn v-if="notfirst" @click="handleMarkUnread">{{ loc('Mark Unread') }} <v-icon class="ml-3">mdi-eye-off</v-icon></v-btn>
                    </v-col>
                    <v-col  cols="auto" style="text-align: right" class="d-none d-md-flex">
                        <v-btn v-if="notfirst" outlined @click="handlePrevious" class="mr-4"><v-icon class="mr-3">mdi-arrow-left-bold</v-icon> {{loc('Previous')}}</v-btn>
                        <v-btn v-if="notlast" outlined @click="handleNext">{{ loc('Next') }} <v-icon class="ml-3">mdi-arrow-right-bold</v-icon></v-btn>
                    </v-col>                    
                </v-row>
            </v-container>
        </div>
        <div v-if="isEditor">
            <v-container style="border-top: 1px solid #ccc;">
                <v-btn @click="handleAdd" color="primary" class="mr-4">Add Material to this Lesson</v-btn>
                <v-btn :to="'/admin/lessons/'+courseid +'/'+lessonid" color="primary" class="mr-4">Manage Lesson</v-btn>
            </v-container>
        </div>
        <!-- <div v-if="enrolledlist && enrolledlist.length > 1">
            <v-container class="mt-6 pt-4" style="border-top: 1px solid #ccc;">
                <v-row>
                    <v-spacer></v-spacer>
                    <v-col align-self="end" class="col-md-2"><v-select :items="classselect(enrolledlist)" v-model="classid" :label="loc('Switch Classes')" style="width: 16em;" @change="setRoute({classid: classid})"></v-select></v-col>
                </v-row>
            </v-container>
        </div> -->
        <div v-if="(enrolledlist && enrolledlist.length > 1) || (users.currentUser && classRecord && classRecord.instructor === users.currentUser._id)">
            <v-container class="pt-6 contentwrapper classmetawrapper" >
                <ClassMetaBlock :classRecord="classRecord" :courseRecord="course" :enrolledList="enrolledlist" />
            </v-container>
        </div>


    </div>
</template>

<script>
import { mapState } from 'vuex';
import AppBanner from './App_Banner';
// import ContentBlockSimple from './Content_Block_Simple';
import ContentBlock from './Content_Block';
import ContentBlockSurvey from './Content_Block_Survey.vue';
import LessonQuiz from './Lesson_Quiz';
import LessonExam from './Lesson_Exam';
import ClassMetaBlock from './Class_Meta.vue';
import CommentsEmbed from './Comments_Embed.vue';

// import siteconfig from '../config/site.js';
// import { filterId } from '../lib/filter';
import _filter from 'lodash/filter';
import _remove from 'lodash/remove';
import _sortBy from 'lodash/sortBy';
import _findIndex from 'lodash/findIndex';
import _last from 'lodash/last';
import _trim from 'lodash/trim';

var initlock, initretries = 0;
var loadLessonLocked, loadLessonRetries = 0;

export default {
    name: "Lesson",
    metaInfo () {
        let meta = {};
        let info = this.pageInfo;
        let siteconfig = this.siteconfig;
        meta.title = info.title;
        if(siteconfig && siteconfig.sitetitle) meta.titleTemplate = siteconfig.sitetitle;
        if(info.style) meta.style = [{ cssText: info.style }];
        if(info.description) meta.meta = [{vmid: 'description', name: 'description', content: info.description}];
        // console.log('meta', info, meta);
        return meta;
    },
    data () {
        return {
            courseid: false,
            course: false,
            // class: false,
            classid: false,
            classPicker: false,
            classRecord: false,
            enrolledlist: false,
            lessonid: false,
            lesson: false,
            lessonlist: false,
            materialid: false,
            // progress: false,
            isEditing: false,
            isExam: false,
            isInstructor: false,
            isLoading: true,
            preview: false,
            // userlang: false, // localvalue set by computed prop and watcher.
            componenttime: Date.now(),

        }
    },
    components: {
        AppBanner, ContentBlock, LessonQuiz, LessonExam, ClassMetaBlock, ContentBlockSurvey, CommentsEmbed //, ContentBlockSimple
    },
    computed: {
        ...mapState(['users', 'courses', 'content', 'classes', 'lessons', 'localize', 'enrollment', 'siteconfig']),
        pageInfo: function(){ 
            let info = {...this.course};
            info.title = info.title + " | " + this.lesson.title;
            return info
        },
        isEditor: function(){
            return this.users && this.users.currentUser && this.users.currentUser.isEditor
        },
        materialsList: function() {
            if(this.lesson && this.lesson.materials){
                if(this.materialid && this.lesson.materials[this.materialid]){
                    let list = {};
                    list[this.materialid] = this.lesson.materials[this.materialid];
                    return list;
                } else {
                    return this.lesson.materials;
                }
            } else {
                return {};
            }
            // return (this.lesson && this.lesson.materials) || {};
        },
        notfirst: function () { 
            if(!this.lessonlist || !this.lessonlist[0]) return true;
            // is this the first lesson?  this.lessonlist is sorted by sortorder in init()
            if(this.lessonlist[0]._id !== this.lessonid){ 
                // console.log("NOTFIRST.  Not first lesson", this.lessonlist[0], this.lessonid); 
                return true; 
            }
            // do we care about materials
            let lessonmats = this.lessons.materials && this.lessons.materials[this.lessonid];
            if(this.materialid && lessonmats && Object.keys(lessonmats)[0] !== this.materialid){ 
                // console.log("NOTFIRST.  Not first material", lessonmats, this.materialid); 
                return true; 
            }
            // console.log("NOTFIRST.  Evidently first?", this.materialsList)
            return false;
        },
        notlast: function () { 
            if(!this.lessonlist || !this.lessonlist.length) return true;
            // is this the last lesson?  this.lessonlist is sorted by sortorder in init()

            let lastid = _last(this.lessonlist)._id
            if(lastid !== this.lessonid){ 
                // console.log("NOTLAST.  Not last lesson", lastid, this.lessonid); 
                return true; 
            }
            // do we care about materials
            let lessonmats = this.materialid && this.lessons.materials && this.lessons.materials[this.lessonid];
            if(this.materialid && lessonmats && Object.keys(lessonmats).pop() !== this.materialid){ 
                // console.log("NOTLAST.  Not last material", lessonmats, this.materialid); 
                return true; 
            }
            // console.log("NOTLAST.  Evidently last?", this)
            return false;            
        },        
        userenrollment: function() {
            if(!this.users || !this.users.currentUser) return {};
            let out = (this.enrollment && this.enrollment.list && this.enrollment.list[this.users.currentUser._id]) || {}; // user enrollment list.
            if(this.users.currentUser.instructor) out = Object.assign({}, out, this.users.currentUser.instructor);
            // console.log("generating userenrollment", out);
            return out;
        },
        usersLang: function() {
            return this.users && this.users.lang;
        },
        progress: function() {
            return (this.enrollment.list && this.enrollment.list[this.users.currentUser._id] && this.enrollment.list[this.users.currentUser._id][this.classid] && this.enrollment.list[this.users.currentUser._id][this.classid].progress) || {};
        }

    },
    methods: {
        loadCourse: function(opts){
            // console.log('Lessons.loadCourse', opts, this.courseid);
            // if(!this.courseid) return;
            this.$store.dispatch("courses/get", {_id: this.courseid} )
                .then((result) => {
                    this.course = result;
                    this.isLoading = false;
                })
        },
        loadClass: function(opts){
            // console.log('Lessons.loadClass', opts, this.classid);
            if(!this.classid) return Promise.reject();
            let query = {_id: this.classid};
            if(this.$route.query && this.$route.query.lang) query.lang = this.$route.query.lang;
            return this.$store.dispatch("classes/get", query )
                .then((result) => {
                    this.classRecord = result;
                    this.isLoading = false;
                    // console.log("Loaded class", this.classRecord);
                })
        },
        loadLesson: function(opts){
            // console.log('Lessons.loadLesson', opts, this.lessonid);
            if(loadLessonLocked){ 
                // console.log("loadLesson is locked"); 
                return Promise.reject();
            }
            if(!this.lessonid || this.lessonid === 'next' || loadLessonLocked) return Promise.reject();
            loadLessonLocked = true;
            // let query = {_id: this.lessonid, course: this.courseid, classid: this.classid};
            let query = {course: this.courseid, classid: this.classid, materials: true};
            if(this.$route.query && this.$route.query.lang) query.lang = this.$route.query.lang;
            if(opts) query.nocache = opts.nocache;

            return this.$store.dispatch("lessons/getList", query )
                .then((result) => {
                    // console.log("lesson", result);
                    if(!result || result.error){
                        console.log("error getting lesson", query, result);
                        this.lessonid = false;
                        this.materialid = false;
                        // this.$router.push("/Course/"+this.courseid + (this.classid ? ('/'+this.classid) : '') );
                        loadLessonLocked = false;
                        return this.setRoute();
                    }
                    if(result && result[this.lessonid]) result = {...result[this.lessonid]};
                    console.log("Loaded lesson", this.lessonid, result)
                    if(result && result.materials){
                        for(let k in result.materials){
                            if(result.materials[k].section === "Quiz" && result.materials[k].quiz){
                                for(let q in result.materials[k].quiz){
                                    result.materials[k].quiz[q].youranswer = false;
                                }
                            }
                        }
                    }
                    this.lesson = result;
                    this.isLoading = false;
                    this.componenttime = Date.now();
                    if(this.classid && this.lessonid){
                        let viewed = {
                            user: this.users.currentUser._id, 
                            course: this.courseid,
                            class: this.classid, 
                            lesson: this.lessonid,
                            cached: result.cached
                        };
                        if(this.materialid) viewed.materialid = this.materialid;
                        loadLessonLocked = false;
                        this.$store.dispatch("enrollment/setViewed", viewed);      
                        return result;              
                    }
                    if(this.courseid){ 
                        this.$store.dispatch("lessons/preload", this.courseid)
                        .catch((e) => {
                            if(e) console.log(e);    
                        });
                    }
                })

        },
        handleAdd: function(){
            return this.$router.push('/admin/lessonmaterials/'+this.courseid+'/'+this.lessonid+'/new');
        },
        handleEditorActions: function (evt, opts) {
            // console.log("Lesson.handleEditorActions", evt, opts)
            switch(evt.action){
                case 'save':
                    (
                        this.$store.dispatch('lessons/saveMaterials', opts)
                            .then((result) => {
                                // console.log("Lesson.handleEditorActions Result from lessons/saveMaterials ", result);
                            })
                    )
                    break;
                case 'remove':
                    // console.log("Remove materials", evt, opts);
                    if(!opts && evt && evt.record && evt.record._id) opts = evt.record;
                    this.$store.dispatch('lessons/removeMaterials', opts)
                    this.lesson.materials[opts._id] && this.$delete(this.lesson.materials, opts._id);
                    break;

            }
        },
        handlePrevious: function () {
            // console.log("Routing to previous")

            // if we are looking at a materialid and it is not the first in the lesson,
            // just go back by one idx.
            if(this.materialid){
                // console.log("Looking for previous material from", this.lessonid, this.materialid, this.lessons.materials);
                let lessonmats = this.lessons.materials && this.lessons.materials[this.lessonid];
                lessonmats = _sortBy(lessonmats, ['order']);
                // let idx = Object.keys(lessonmats).indexOf(this.materialid);
                let idx = _findIndex(lessonmats, {_id: this.materialid});
                // console.log("lessonmats, idx", lessonmats, idx);
                if(idx > 0){
                    // console.log("Routing to new materialid", idx - 1)
                    return this.setRoute({materialid: lessonmats[idx - 1]._id});
                }
            }

            // otherwise fine the previous lesson and go there.
            if(this.lessonlist[0]._id !== this.lessonid){
                let idx = _findIndex(this.lessonlist, {_id: this.lessonid});
                let newlessonid = this.lessonlist[idx - 1] && this.lessonlist[idx - 1]._id;

                // console.log('NEWID', idx, newlessonid,this.lessonid)
                let newroute = { lessonid: newlessonid }

                // if we're looking at a materialid, we want the last one in the new lesson.
                if(this.materialid && this.lessons.materials) newroute.materialid = _last(Object.keys(this.lessons.materials[newlessonid]))

                if(newlessonid || newlessonid === 0){
                    return this.setRoute(newroute)
                }
            }
            // console.log("Cannot go to previous from first.", this.lessonid, this.lessonlist)
            return;
        },
        handleNext: function () {
            // console.log("Routing to next")
            
            let lessonmats = this.lessons.materials && this.lessons.materials[this.lessonid];
            if(lessonmats) lessonmats = _sortBy(lessonmats, ['order']);
            console.log("handleNext Lesson materials", lessonmats)
            // if we are looking at a materialid and it is not the last in the lesson,
            // just go forward by one idx.
            if(this.materialid){
                // let lessonmats = this.lessons.materials && this.lessons.materials[this.lessonid];
                // lessonmats = _sortBy(lessonmats, ['order']);
                // let keys = Object.keys(lessonmats);
                // let idx = keys.indexOf(this.materialid);
                let idx = _findIndex(lessonmats, {_id: this.materialid});
                if(lessonmats[idx + 1]){
                    // console.log("Routing to Next", idx+1, lessonmats[idx+1]);
                    return this.setRoute({ materialid: lessonmats[idx + 1]._id });
                }
            } else if(this.siteconfig && this.siteconfig.showeachmaterial && lessonmats[0]){
                return this.setRoute({ materialid: lessonmats[0]._id });
            }
            // console.log("We are not routing materialid, or we are moving to the next lesson", this.lessonid, this.materialid, _last(this.lessonlist));
            // otherwise fine the next lesson and go there.

            if(this.lessonlist && _last(this.lessonlist) && _last(this.lessonlist)._id !== this.lessonid){
                let idx = _findIndex(this.lessonlist, {_id: this.lessonid});
                let newlessonid = this.lessonlist[idx + 1] && this.lessonlist[idx + 1]._id;

                if(newlessonid){
                    // console.log('NEWID', idx, newlessonid,this.lessonid)
                    let newroute = { lessonid: newlessonid }

                    // if we're looking at a materialid, we want the first one in the new lesson.
                    if(this.materialid){ 
                        let lessonmats = this.lessons.materials && this.lessons.materials[newlessonid];
                        lessonmats = _sortBy(lessonmats, ['order']);
                        newroute.materialid = lessonmats[0]._id; 
                    }

                    return this.setRoute(newroute)
                }

            }
            // console.log("Cannot go to previous from first.")
            return;            
        },
        handleMarkUnread: function (){
            // console.log('handleMarkUnread')
            let viewed = {
                user: this.users.currentUser._id, 
                course: this.courseid,
                class: this.classid, 
                lesson: this.lessonid
            };
            if(this.materialid) viewed.materialid = this.materialid;
            this.$store.dispatch('enrollment/setUnviewed', viewed)
                .then((result) => {
                    console.log("Marked unread", result);
                    setTimeout(() => {this.setRoute({lessonid: false, materialsid: false})}, 300)
                })
        },
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },  
        classselect: function (list) {
            
            let out = [];
            for(let i in list){
                out.push({
                    text: list[i].name || (this.classes && this.classes.list && this.classes.list[list[i].classid] && this.classes.list[list[i].classid].name) || list[i].classid , 
                    value: list[i].classid || list[i]._id
                })
            }
            // console.log("classselect", list, out);
            return out;
        } ,
        sectionTypes: function (item) {
            switch (item.section) {
                case undefined:
                case '':
                case 'Introduction':
                case 'Presentation':
                case 'Text':
                case 'Article':
                case 'Application':
                    return 'block';
            }
        },
        materialsOptions: function () {
            let types = this.siteconfig && this.siteconfig.materialTypes && this.siteconfig.materialTypes.slice(0) || [];
            for(let i in types){
                types[i] = { text: types[i], value: types[i] }
            }

            return {
                emitsave: true, 
                field: 'description', 
                width:'800',  // video width
                trunc: this.siteconfig && this.siteconfig.truncatecontent,
                subtypeSelect: types,
                editableSubtypes: [ 'Introduction','Presentation', 'Article', 'Application', 'Text']
            }
        },
        showDiscuss: function (item) {
            const omit = ["Introduction", 'Quiz'];
            return (item && item.question && omit.indexOf(item.section) < 0);
        },
        nextMaterial: function (opts){
            console.log("nextMaterial", opts);
            opts = opts || {};
            if(!this.lesson && !this.lesson.materials){
                this.loadLesson()
                return setTimeout(() => {this.nextMaterial(opts)}, 300);
            }
            let next;
            let mats = Object.keys(this.lesson.materials)
            let last = opts.materialid || this.materialid;
            if(!last && this.progress[this.lessonid].materials){
                last = []
                for(let k in this.progress[this.lessonid].materials){
                    last.push({key: k, lastview: this.progress[this.lessonid].materials[k]});
                }
                last = _last(_sortBy(last, 'lastview')).key;
               
                console.log("last material:", last)
            }
            if(last){
                let idx = mats.indexOf(last);
                next = (opts.forward) ? mats[idx+1] : mats[idx];
            } else {
                next = mats[0];
            }
            opts.materialid = next;
            // this.materialid = next;
            return this.setRoute(opts);
        },
        setRoute: function (opts) {
            opts = opts || {};
            opts.courseid = (opts.courseid !== undefined )   ? opts.courseid : this.courseid;
            opts.lessonid = (opts.lessonid !== undefined )   ? opts.lessonid : this.lessonid;
            opts.classid = (opts.classid !== undefined )    ? opts.classid   : this.classid;
            opts.materialid = (opts.materialid !== undefined ) ? opts.materialid : this.materialid;

            const sendroute = (newpath) => {
                if(newpath !== this.$route.path) this.$router.push(newpath);
            }

            // path: '/lessons/:courseid/:lessonid/:classid?/:materialid?'

            if(!opts.courseid) return sendroute('/Courses');
            if(!opts.lessonid) return sendroute('/Course/' + opts.courseid + ((opts.classid) ? ('/' + opts.classid) : ''));
            let path = '/lessons/' + opts.courseid + '/' + opts.lessonid;
            if(!opts.materialid && !opts.classid) return sendroute(path)
            if(!opts.materialid) return sendroute(path + '/' + opts.classid);
            return sendroute(path + '/' + opts.classid + '/' + opts.materialid);

            // this.$router.push('/lessons/'+this.courseid+'/'+lessonlist[0]._id+'/'+this.classid);
        },
        /**
         * This is called on page load, if the lang changes, and if the route changes.
         * The possible exits from this method are 
         *      setRoute if the course, lesson, material, or class is changing
         *      nextMaterial if we need to find the next material to go to.  This should always call setRoute
         *      
         *      if we need to set the class, we set this.classPicker = true; which triggers the template to ask for a class.

         *      loadLesson - if none of the above gets fired we go here, which gets the lesson and materials (sometimes again) and calls setViewed
         *      
        */
        init: function (opts) {
            console.log("Lesson.init", this.$route.params, initlock, this.$route.hash)
            if(initlock){  return; }
            initlock = true;
            

            this.courseid = this.$route.params.courseid;

            if(!this.users || !this.users.currentUser || !this.enrollment || !this.enrollment.list || !this.courses || !this.courses.list){
                if(initretries > 3){
                    if(!this.users || !this.users.currentUser){
                        console.log("user appears to not be logged in, rerouting", this.courseid, this.users && this.users.currentUser)
                        this.$router.push('/login'); 
                    } else {
                        this.setRoute({lessonid: undefined})
                        // this.$router.push('/Course/'+this.courseid);
                    }
                   
                } else {
                //    console.log("retrying user login on lesson page.")
                   initretries++;
                   setTimeout(() => {return this.init(opts)}, 300);
                }
                initlock = false;
                return;
            }

            this.lessonid = this.$route.params.lessonid;
            this.lessonlist = _filter(this.lessons.list, {course: this.courseid});
            this.materialid = this.$route.params.materialid;
            this.classid = this.$route.params.classid; // || 'selfpaced'; // @TODO verify that this works across courses.
            if(this.classid === 'none') this.classid = false;
            // if(this.classid && !this.userenrollment[this.classid]) this.classid = false;
            let matappend = (this.materialid) ? ('/' + this.materialid) : ''
            
            let sections = false;
            if(this.courses.list[this.courseid] && this.courses.list[this.courseid].sections){
                sections = this.courses.list[this.courseid].sections.split(',');
                for(let s in sections){ sections[s] = _trim(sections[s]); }
                for(let i in this.lessonlist){
                    this.lessonlist[i].sectionorder = (sections) ? (sections.indexOf(this.lessonlist[i].sorder) + 1) : 0;
                }
            }
            this.lessonlist = _sortBy(this.lessonlist, ['sectionorder', 'sortorder']);

            // an array of all classes the user is in for this course.
            this.enrolledlist = _filter(this.userenrollment, {course: this.courseid});

            // make sure we're in the right class.
            if(this.classid && this.userenrollment[this.classid]){
                // we have class
                console.log("lessons.init we are in the right class", this.classid, this.userenrollment[this.classid], this.enrollment.list[this.users.currentUser._id]);
                // this.progress = (this.enrollment.list[this.users.currentUser._id] && this.enrollment.list[this.users.currentUser._id][this.classid] && this.enrollment.list[this.users.currentUser._id][this.classid].progress) || {};
                this.classPicker = false;
            } else {
                console.log("We need some class for the lesson", this.courseid, this.classid, this.lessonid);
                // console.log("userenrollment:", this.userenrollment, "enrolledlist:", this.enrolledlist);
                // we need a class.
                
                if(this.enrolledlist && this.enrolledlist.length === 1){
                    // user is enrolled in one class for the specified course, so we can use that.
                    console.log("lessons.init I found a class that will work");
                    this.classid = this.enrolledlist[0].classid;
                    this.classPicker = false;
                    initlock = false;
                    return this.setRoute({ classid: this.enrolledlist[0].classid });
                    // this.$router.push('/lessons/' + this.courseid + '/' + this.lessonid + '/' + this.classid + matappend);
                    // return;
                } else if(this.enrolledlist && this.enrolledlist.length > 1) {
                    // console.log("lessons.init we will need to ask about the class.", this.enrolledlist);
                    for(let i in this.enrolledlist){
                        let classid = this.enrolledlist[i].classid || this.enrolledlist[i]._id;
                        if(this.classes.list && this.classes.list[classid]){
                            this.enrolledlist[i].name = this.classes.list[classid].name;
                            this.enrolledlist[i].to = "/lessons/"+this.courseid+"/"+this.lessonid+"/"+classid + matappend;
                        } else {
                            // console.log("processing enrolledList, remove", this.enrolledlist[i]._id, this.enrolledlist[i].name);
                            this.enrolledlist[i].remove = true;
                            this.enrolledlist[i].name = this.loc("This class no longer exists");
                            this.enrolledlist[i].to =  "/lessons/"+this.courseid+"/"+this.lessonid;
                        }
                    }
                    let removed = _remove(this.enrolledlist, (o) => { return o.remove });
                    // console.log("Removed", removed)
                    this.classPicker = true;
                    initlock = false;
                    // console.log("Sending to class picker");
                    return;
                } else {
                    // user is not enrolled in the URL params classid
                    // console.log("lessons.init user is not registered for any classes that match the current URL params.", this.courseid, this.classid, this.siteconfig && this.siteconfig.maincourses);
                    this.classid = false;
                }
            }

            if(this.course && this.course.lang && this.users && this.users.lang && this.course.lang !== this.users.lang && !this.siteconfig.nolangcoursechange){
                // @TODO change the course and class to one in the current user lang.
                let coursematch, classmatch;
                if(this.siteconfig && this.siteconfig.maincourses){
                    for(let k in this.siteconfig.maincourses){
                        if(this.siteconfig.maincourses[k] && this.siteconfig.maincourses[k].selfpaced && this.siteconfig.maincourses[k].selfpaced[this.classid]){
                            // we are in a selfpaced class in this main course.
                            // console.log('we are in a selfpaced class,', this.classid ,',in', k);
                            for(let courseid in this.siteconfig.maincourses[k].courses){
                                if (this.siteconfig.maincourses[k].courses[courseid] === this.users.lang){
                                    for(let classid in this.siteconfig.maincourses[k].selfpaced){
                                        if(this.siteconfig.maincourses[k].selfpaced[classid] === courseid){
                                            this.courseid = courseid;
                                            this.classid = classid;
                                            this.lessonid = false; // is there a good way to get this?
                                            this.materialid = false;
                                            initlock = false;
                                            return this.setRoute()
                                        }
                                    }
                                }
                            }

                        }

                    }
                }

            }
            // at this point in the process we have a course and a class.

            if(this.lessonid === "next"){
                console.log('lessons.init we are looking for the next lesson.', this.lessonlist)
                if( this.userenrollment[this.classid] && this.userenrollment[this.classid].progress ) {
                    let last = false;
                    for(let k in this.progress){
                        // console.log("Checking progress for lessons", k, this.progress[k]);
                        if(!last || last.lastview < this.progress[k].lastview){ 
                            // console.log("Setting last to",k, this.progress[k]);
                            last = this.progress[k]; 
                            last._id = k;
                            this.lessonid = k;
                        } else {
                            // console.log("This is not the last one", last.lastview, '>=', this.progress[k].lastview)
                        }
                    }
                    if(last && last._id){ 
                        if(last.quizdate){
                            let lastindex = _findIndex(this.lessonlist, {_id: last._id});
                            // console.log('lastindex', lastindex)
                            if(lastindex === -1){ 
                                this.lessonid = last._id 
                            } else {
                                this.lessonid = (this.lessonlist[lastindex + 1] && this.lessonlist[lastindex + 1]._id) || last._id
                            }
                        } else {
                            this.lessonid = last._id;
                        }
                        initlock = false;
                        
                    }
                    return this.nextMaterial({forward: false});

                    // get the most recently viewed lesson.
                    // get the lesson after that one.
                    // set the route to that id.
                    // this.progress = this.userenrollment[this.classid].progress[this.lessonid] || {};
                } else {
                    // console.log("Let's go with the first one");

                    initlock = false;
                    this.lessonid = this.lessonlist[0]._id;
                    return this.nextMaterial({lessonid: this.lessonlist[0]._id}); // fall back to the first lesson
                }

            }

            initlock = false;
            this.componenttime = Date.now();
            // console.log("init ready to load", this.componenttime)
            this.loadCourse();
            this.loadClass();
            this.loadLesson({nocache: true})
                .then(() => {
                    setTimeout(() => {
                        if(this.$route.hash){
                            let hash = this.$route.hash.slice(1);
                            let el = document.getElementById(hash)
                            if(el){ 
                                el.scrollIntoView({ behavior: 'smooth' });
                            }
                        }
                    })
                    // console.log(this.$route)
                })
                .catch((e) => { if(e){ console.log("ERROR in Lesson.init", e)}});
        }
    },
    // mounted () {
    //     console.log('Lesson mounted')
    //     if(this.componenttime < Date.now() - 10000){ 
    //         this.init(); 
    //     } else {
    //         console.log('too soon to re-init', this.componenttime - Date.now() - 10000)
    //     }
    // },
    created () {
        // console.log("Lesson", this.$route.params);
        this.init({nocache: true});
    },
    watch: {
        '$route.params': {
            handler: function(s){
                // console.log('nav.watcher params triggered', s);
                this.init();
            },
            deep: true,
            immediate: false
        },
        // 'usersLang': {
        //     handler: function(s){
        //         // This is a convoluted way to do this, and probably "wrong", but 
        //         // using a vuex getter wouldn't easily get us what we need here.
        //         console.log('nav.watcher usersLang triggered', s);
        //         this.userlang = s;
        //         this.init();
        //     },
        //     deep: true,
        //     immediate: false
        // }
        'users.lang': {
            handler: function(s){
                // console.log('nav.watcher users.lang triggered', s);
                this.init();
            },
            deep: true,
            immediate: false            
        }

        // '$route.params.courseid': {
        //     handler: function(s){
        //         console.log("lessons.watch triggered, courseid", s);
        //         if(s !== this.courseid) this.init();
        //     },
        //     deep: true,
        //     immediate: false
        // },
        // '$route.params.classid': {
        //     handler: function(s){
        //         console.log("lessons.watch triggered, courseid", s);
        //         if(s !== this.classid) this.init();
        //     },
        //     deep: true,
        //     immediate: false
        // },        
        // '$route.params.lessonid': {
        //     handler: function(s){
        //         console.log("lessons.watch triggered, lessonid", s);
        //         if(s !== this.lessonid) this.init();
        //     },
        //     deep: true,
        //     immediate: false
        // },
        // '$route.params.materialid': {
        //     handler: function(s){
        //         console.log("lessons.watch triggered, materialid", s);
        //         if(s !== this.materialid) this.init();
        //     },
        //     deep: true,
        //     immediate: false
        // },        
    }// @TODO add watcher here and in courses for language changs

        
}
</script>