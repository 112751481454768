<template>
    <v-footer dark class="mt-12">
        <v-container >
            <v-row class="pt-8">
                <v-col md="4" cols="12" class="footeraddress" >
                    <div class="mb-4"><a :href="orgurl"><img :src="loc('_sitelogo')" height="80" /></a></div>
                    <div v-for="(item) in navlist.footer1" v-bind:key="item.key" class="mb-4">
                        <v-icon v-if="item.icon && item.icon.length" class="mr-3">{{ item.icon }}</v-icon>
                        <a v-if="item.url && item.url.length" :href="item.url">{{item.title}}</a>
                        <span v-else v-html="item.title"></span>
                    </div>
                    <div v-if="navlist.social && navlist.social.length" class="mb-3 footersocial">
                        <a v-for="(item) in navlist.social" v-bind:key="item.key" :href="item.url" target="_blank" >
                            <v-icon v-if="item.icon && item.icon.length">{{ item.icon }}</v-icon>
                        </a>
                    </div>
                </v-col>
                <v-col md="3" sm="4" class="footerabout" v-if="navlist.footer2 && navlist.footer2.length">
                    <div class="mb-4"><span class='foottitle'>{{ loc('Online Training') }}</span></div>
                    <div v-for="(item) in navlist.footer2" v-bind:key="item.key" class="mb-4">
                        <v-icon v-if="item.icon && item.icon.length" class="mr-3">{{ item.icon }}</v-icon> 
                        <a v-if="item.url && item.url.length" :href="item.url">{{item.title}}</a>
                        <span v-else v-html="item.title"></span>
                    </div>
                </v-col>
                <v-col md="3" sm="4" class="footercta" v-if="navlist.footer3 && navlist.footer3.length">
                    <div class="mb-4"><span class='foottitle'>{{ loc('About') }}</span></div>
                    <div v-for="(item) in navlist.footer3" v-bind:key="item.key" class="mb-4">
                        <v-icon v-if="item.icon && item.icon.length" class="mr-3">{{ item.icon }}</v-icon> 
                        <a v-if="item.url && item.url.length" :href="item.url">{{item.title}}</a>
                        <span v-else v-html="item.title"></span>
                    </div>
                </v-col>
                <v-col md="2" sm="4" class="footercta" v-if="navlist.footer4 && navlist.footer4.length">
                    <div class="mb-4"><span class='foottitle'>{{ loc('Get Involved') }}</span></div>
                    <div v-for="(item) in navlist.footer4" v-bind:key="item.key" class="mb-4">
                        <v-icon v-if="item.icon && item.icon.length" class="mr-3">{{ item.icon }}</v-icon> 
                        <a v-if="item.url && item.url.length" :href="item.url">{{item.title}}</a>
                        <span v-else v-html="item.title"></span>
                    </div>
                </v-col>  
            </v-row>
            <v-row class="pt-4">          
                <v-col cols="9">© 2021 {{ loc('Disciple Nations Alliance') }}. {{ loc('All rights reserved') }}.</v-col>
                <v-col cols="3" style="text-align: right" ><a href="https://x31tech.com" target="_blank" class='poweredby'>{{ loc('Powered by') }} X31 Technologies</a></v-col>
            </v-row>
        </v-container>
    </v-footer>
</template>

<script >
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState(['localize', 'users', 'navitems', 'siteconfig']),
        navlist () {
            return this.navitems;
        },
        orgurl () {
            return this.siteconfig.organizationurl && ((this.users && this.users.lang && this.siteconfig.organizationurl[this.users.lang]) || this.siteconfig.organizationurl.en) || '/';
        }
    },
    methods: {
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        }, 

    },
    mounted () {
        console.log('navitems', this.navitems)
        setTimeout(()=>{console.log('navitems1', this.navitems)}, 500)
    }
}
</script>
<style scopes type="text/css">
    .poweredby:hover { font-weight: normal;  }
</style>
