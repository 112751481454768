import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

const axiosconfig = { headers: {} };

/*
cost schema = {
    _id:        { f: filter.filterId },
    subject:    { f: filter.filterText },
    body:       { f: filter.xss },
    parent:     { f: filter.filterId },
    context:    { f: filter.filterId },
    lang:       { f: filter.filterId },
    author:     { f: filter.filterId },
    authorname: { f: filter.filterWords },
    access:     { f: filter.filterId },
    timestamp:  { f: filter.toDatetime }
}
*/

export default {
    namespaced: true,
    state: {
        list: false
    },
    mutations: {
        setList (state, val) {
            if(val === false){ 
                console.log("Clearing comment state.")
                state.list = false; 
                return;
            }
            if(!state.list) state.list = {};
            for(let i in val){
                Vue.set(state.list, val[i]._id, val[i]);
            }
        },
        setRecord (state, val) {
            if(val && val._id) Vue.set(state.list, val._id, val);
            if(val.replies && val.replies.length){
                for(let i in val.replies){
                    Vue.set(state.list[val._id].replies, i, val.replies[i]);
                }
            }
        },
        removeRecord (state, val) {
            val = val && (val._id || val.removed) || val;
            if(val  && state.list && state.list[val]) Vue.delete(state.list, val);
        }
    },
    actions: {
        clearAll ({commit, state}) {
            console.log("comments clearAll")
            commit('setList', false);
            return Promise.resolve();
        },
        getList ({commit, state}, data){
            data = data || {};
            let uri = (data.parent) ? ( "/app_comments/get/" + data.parent ) : "/app_comments/list";
            if(data.parent) delete data.parent;
            console.log('getting comments from', uri)
            return axios.post(uri, data, axiosconfig)
                .then((response) => {
                    let result = response && response.data;
                    if(result){
                        console.log("Setting comments list", result);
                        let list = result.comments || result;
                        let llength = list.length;
                        if(llength){
                            for(let i=0;i<llength;i++){
                                if(list[i].parent){ 
                                    let pieces = list[i].parent.split('-');
                                    pieces.pop();  // materials key
                                    if(pieces){
                                        list[i].lessonId = pieces.join('-');
                                        pieces.pop();
                                        list[i].courseId = pieces.join('-');
                                    }
                                } 
                                list[i].classId = list[i].context;
                                // comments.list is an array, not key-value
                                // list[list[i]._id] = list[i];
                            }          
                        } else {
                            list = false;
                        }          
                        commit('setList', list);
                    }
                    return result;
                })
                .catch((e) => {
                    console.log('error getting course list', e)
                })
        },
        /** We don't actually use this anywhere? */
        // get ({commit, state}, data){
        // },
        remove ({commit, state}, data){
            if(!data || !data._id || !data.author) return Promise.reject("Missing post data");

            let uri = "/app_comments/remove";
            return axios.post(uri, data, axiosconfig)
                .then((response) => {
                    let result = response && response.data;
                    console.log(result, state.list[result.removed]);
                    if(result && result.removed) commit('removeRecord', result);
                    return result;
                })
                .catch((e) => {
                    console.log('error getting course list', e)
                });


        },
        savePost ({commit, state}, data){
            if(!data || !data.body || !data.parent) return Promise.reject("Missing post data");

            let uri = "/app_comments/save";
            return axios.post(uri, data, axiosconfig)
                .then((response) => {
                    let result = response && response.data;
                    console.log(result);
                    if(result) commit('setRecord', result);
                    return result;
                })
                .catch((e) => {
                    console.log('error getting course list', e)
                });
            
        },
        save ({commit, state}, data){

        }
    }
};