<template>
    <div class="isinstructorcontainer mb-4" v-if="classList && classList.length" >
        <h2 class="mb-3">{{ loc("Hosted Classes") }}</h2>
        <div class="note pb-4">
            {{ loc("This user is the instructor for") }}
            {{ classList.length }} {{ loc("classes") }}.
        </div>
        <div>
            <div v-for="item in classList" :key="item._id" class="mb-5 classrow" >
                <v-row>
                    <div class="label">{{ loc("Course") }}</div>
                    <div class="value">
                        {{ courseRecord(item, "reftitle") }} -
                        {{ courseRecord(item, "title") }}
                    </div>
                </v-row>
                <v-row>
                    <div class="label">{{ loc("Class") }}</div>
                    <div class="value">
                        {{ item.name }} (
                        <router-link :to="'/admin/classes/' + item.course + '/' + item._id" v-if="users.currentUser.isAdmins" >Admin</router-link > 
                        <router-link :to="'/classes/edit/' + item._id" v-else >Edit</router-link > ,
                        <router-link :to="'/Course/' + item.course + '/' + item._id" >Visit</router-link > , 
                        <router-link :to="'/classes/enrollment/' + item._id">Participants</router-link>
                        )
                    </div>
                </v-row>
                <v-row>
                    <div class="label">{{ loc("Start Date") }}</div>
                    <div class="value">{{ item.startdate }}</div>
                </v-row >
                <v-row v-if="item.regcode">
                    <div class="label">{{ loc("Reg Code") }}</div>
                    <div class="value">{{ item.regcode }}</div>
                </v-row >
                                
            </div>
            <v-row class="note pt-6 d-none">{{ loc("Instructor information is managed on the class record.")}}</v-row>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import _sortBy from 'lodash/sortBy';
import _filter from 'lodash/filter';

export default {
    name: "UserInstructorList",
    props: {
        classList: {
            type: Array
        },
        userRecord: { 
            type: Object
        }
    },
    data () {
        return {

        }
    },
    computed: {
        ...mapState([ 'courses', 'users', 'localize', 'classes' ])
    },
    methods: {
        courseRecord: function(item, field){
            console.log("instructor list courseRecord", item, field)
            if(this.courses && this.courses.list && this.courses.list[item.course]){
                return (field) ? this.courses.list[item.course][field] : this.courses.list[item.course]
            } else {
                return field ? false : {}; 
            }                
        },
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        }, 

    },
    
}
</script>