<template>
    <div>
        <v-row><h1>{{courseTitle}}</h1></v-row>
        <v-row>
            <router-link :to="'/Course/' + classRecord.course + '/' + classRecord._id">{{ loc("Go to Class") }}</router-link>
            <router-link class="ml-4" v-if="this.users.currentUser.isAdmin" :to="'/admin/classes/'+classRecord.course + '/' + classRecord._id">{{ loc("Admin") }}</router-link>
        </v-row>
        <v-row><v-col class='label'>{{ loc('Start Date') }}:</v-col><v-col>{{classRecord.startdate || ''}}</v-col><v-spacer /></v-row>
        <v-row><v-col class='label'>{{ loc('Reg Code') }}:</v-col><v-col>{{classRecord.regcode || ''}}</v-col><v-spacer /></v-row>
        <v-row><v-col class='label'>{{ loc('Instructor') }}:</v-col><v-col>{{classRecord.instructorname || ''}}</v-col><v-spacer /></v-row>
        <v-row><v-col class='label'>{{ loc('Created') }}:</v-col><v-col>{{classRecord.created || ''}}</v-col><v-spacer /></v-row>
        <v-row><v-col class='label'>{{ loc('Description') }}:</v-col><v-col v-html="classRecord.description"></v-col><v-spacer /></v-row>
        <v-row><pre>{{ this.classRecord && this.courses.list && this.courses.list[this.classRecord._id] }}</pre></v-row>
        <div v-if="enrollmentList && enrollmentList.length" class="pt-8" >
            <v-row class="searchrow" style="width: 98%">
                <h2>{{ loc('Participants') }}</h2>
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="search"
                    :label="loc('Search')"
                    single-line
                    hide-details
                ></v-text-field>
            </v-row>            
            <v-row class='mb-6'>
                <v-data-table
                    class="elevation-2"
                    style="width: 98%"
                    :items-per-page="itemsPerPage"
                    :headers="headers" 
                    :items="enrollmentList"
                    :search="search"
                >
                    <template v-slot:[`item.email`]="{ item }">
                        <a :href="'mailto:'+item.email" v-if="item.email">
                            {{ item.email }}
                        </a>
                    </template>                
                </v-data-table>
            </v-row>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

let initretries = 0;

export default {
    name: "ClassesEnrollment",
    props: {
        classRecord: { type: Object }
    },
    data () { 
        return {
            isLoading: false,
            enrollmentList: false,
            itemsPerPage: 15,
            tablePrefs: false,
            search: "",
            // headers: {
            //     firstname: {
            //         text: "First Name",
            //         value: "firstname",
            //         sortable: true, 
            //         align: "start",
            //         show: true
            //     },
            //     firstname: {
            //         text: "First Name",
            //         value: "firstname",
            //         sortable: true, 
            //         align: "start",
            //         show: true
            //     }

            // }
        }
    },
    components: {},
    computed: {
        ...mapState(['siteconfig', 'users', 'courses', 'enrollment', 'localize']),
        courseTitle: function() {
            if(!this.classRecord || !this.classRecord.course) return "";

            let course = this.courses && this.courses.list && this.courses.list[this.classRecord.course] && (( this.courses.list[this.classRecord.course].title + ' - ' ) || '');
            course = (course || '') + ' ' + ( this.classRecord.name || '');
            return course;
        },
        isInstructorOrAdmin: function(){
            return (
                (this.users.currentUser && this.users.currentUser.isAdmin) || 
                (this.users.currentUser._id === this.classRecord.instructor)
            )
        },
        headers: function(){
            let out = {
                firstname: {
                    text: "First Name",
                    value: "firstname",
                    sortable: true, 
                    align: "start",
                    show: true
                },
                lastname: {
                    text: "Last Name",
                    value: "lastname",
                    sortable: true, 
                    align: "start",
                    show: true
                }
            }
            if(this.isInstructorOrAdmin){
                out.regdate = {
                    text: "Registration Date",
                    value: "regdate",
                    sortable: true, 
                    align: "start",
                    show: true
                };
                out.email = {
                    text: "Email",
                    value:"email",
                    sortable: true,
                    align: "start",
                    show: true,
                };
                out.lastview = {
                    text: "Active",
                    value:"lastview",
                    sortable: true,
                    align: "start",
                    show: true,
                };
                out.lessoncount = {
                    text: "Lessons",
                    value:"lessoncount",
                    sortable: true,
                    align: "end",
                    show: true,
                };
            }
            return Object.values(out);
        }
    },
    methods: {
        getEnrollment: function () {
            if(!this.classRecord || !this.classRecord._id){
                this.enrollmentList = false;
            } else {
                let opts = {classid: this.classRecord._id};
                this.$store.dispatch("enrollment/getClassEnrollment", opts)
                    .then((result) => {
                        console.log("classes.getEnrollment returned", result);
                        if(result) this.enrollmentList = result;
                    })
            }

        },
        loc: function (str) {
            return (this.localize && this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },           

    },
    mounted () {
        this.getEnrollment();
    },
    watch: {
        classRecord(value1, value2){
            this.getEnrollment();
        }
    }
}
</script>

<style scoped>
    .label { max-width: 230px;} 
</style>