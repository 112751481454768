<template>
    <div class='contentblock quizwrapper elevation-2 mt-4' id="quiz-wrapper">
        <div v-if="showing === 'posting'">
            <v-row v-html="loc('Processing...')"></v-row>
        </div>
        <div v-if="showing === 'quiz'">
            <v-row class='quizdescription'  ><v-col cols="12" v-html="record.description"></v-col></v-row>
            <v-row v-for="(item, idx) in questions" :key="idx" :id="'quiz-'+idx" class="quizquestionwrapper">
                <v-col class="quizquestion" cols="12">
                    {{item.question}}
                </v-col>
                <v-col class="quizanswerswrapper" cols="12">
                    <v-radio-group v-model="item.youranswer" class="quizanswers">
                        <v-radio 
                            v-for="(answer, n) in item.answers" 
                            class='quizanswer' 
                            :key="'quiz-'+idx+'-'+n" 
                            :label="Alpha[n] + ':\xa0\xa0\xa0' + answer"
                            :value="Alpha[n]"
                        > 
                        </v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
            <v-row class="ma-4 pb-4">
                <v-btn color="primary" @click="submitquiz" class="mr-3" >{{ loc("Submit Answers")}}</v-btn>
                <v-btn @click="showing = 'intro';scrollToQuiz();" >{{ loc("Cancel")}}</v-btn>
            </v-row>
        </div>
        <div v-else-if="showing === 'answers'">
            <v-row v-if="record.description2" class='quizdescription' ><v-col cols="12" v-html="record.description2"></v-col></v-row>
            <v-row v-for="(item, idx) in questions" :key="idx" :id="'quiz-'+idx" class="quizquestionwrapper">
                <v-col class="quizquestion" cols="12">
                    {{item.question}}
                </v-col>
                <v-col class="quizanswerswrapper" cols="12">
                    <v-row>
                        <v-col cols="2"><h4>{{ loc('Your Answer') }}</h4></v-col> 
                        <v-col cols="10" :class="(lessonprogress.answers[idx] != lessonprogress.correctanswers[idx]) ? 'error--text' : '' ">{{ item.answers[Alpha.indexOf(lessonprogress.answers[idx])]}}</v-col>
                    </v-row>
                    <v-row v-if="lessonprogress.answers[idx] != lessonprogress.correctanswers[idx]">
                        <v-col cols="2"><h4>{{ loc('Correct Answer') }}</h4></v-col> 
                        <v-col cols="10" class="font-weight-bold">{{ item.answers[Alpha.indexOf(lessonprogress.correctanswers[idx])]}}</v-col>
                    </v-row>
                    <v-row v-else class="success--text">
                        <v-col cols="2">{{ loc('Correct!') }}</v-col> 
                    </v-row>
                </v-col>
            </v-row>            
            <!-- <v-row>
                <pre>
                    {{progress}}
                </pre>
            </v-row> -->
            <v-row class="ma-4 pb-4">
                <v-btn @click="showing = 'intro'" >{{ loc("Close")}}</v-btn>
            </v-row>
        </div>        
        <div v-else-if="quizstatus === 'passed'" class="pa-4" :key="quizstatus + '-' + lessonprogress.lastview">
            <h3>{{ loc('You have successfully completed the quiz') }}</h3>
            {{ loc("You answered")}} {{lessonprogress.score}} {{ loc('out of') }} {{lessonprogress.possible}} {{ loc('Correct') }} 
            <v-row v-if="record.description1"  class='quizdescription' ><v-col cols="12" v-html="record.description1"></v-col></v-row>
            <v-row class='pa-4'>
                <v-btn color="primary" @click="showing = 'answers'" class="mt-4 mr-4">{{ loc("Show Answers")}}</v-btn> <v-btn color="primary" @click="showing = 'quiz'" class="mt-4">{{ loc("Retake the Quiz")}}</v-btn>
            </v-row>
        </div>        
        <div v-else-if="quizstatus === 'not passed'" class="pa-4">
            <h3>{{ loc('You have completed the quiz') }}</h3>
            {{ loc("You answered")}} {{lessonprogress.score}} {{ loc('out of') }} {{lessonprogress.possible}} {{ loc('Correct') }} 
            <v-row v-if="record.description2" class='quizdescription' v-html="record.description2" ></v-row>
            <v-row class='pa-4'>
                <v-btn color="primary" @click="showing = 'answers'"  class="mt-4 mr-4">{{ loc("Show Answers")}}</v-btn> <v-btn color="primary" @click="showing = 'quiz'" class="mt-4">{{ loc("Retake the Quiz")}}</v-btn>
            </v-row>
        </div>        
        <div v-else class="ma-4 quizintro pa-4">
            <v-row v-if="record.description" class='quizdescription'><v-col cols="12" v-html="record.description"></v-col></v-row>
            <v-row class='pa-4'>
                <v-btn color="primary" @click="showing = 'quiz'" >{{ loc("Start Quiz")}}</v-btn>
            </v-row>
            <!-- <div><pre>{{progress}}</pre></div> -->
        </div>
            <div style="position:relative" v-if="!options.preview && users.currentUser && users.currentUser.isEditor">
                <v-btn color="secondary" fab absolute small bottom right :to="'/admin/lessonmaterials/' + lesson.course + '/' + record.lesson + '/' + record._id" >
                    <v-icon>settings</v-icon>
                </v-btn>
            </div>        
    </div>
    
</template>

<script>
import { mapState } from 'vuex';
// import ContentBlockSimple from './Content_Block_Simple';
// import ContentBlock from './Content_Block';

export default {
    name: "LessonQuizBlock",
    props: {
        lesson: { type: [Object, Boolean]},
        record: { type: [Object, Boolean]},
        classid: { type: String },
        progress: { type: [Object, Boolean]}
    }, 
    data() {
        return {
            questions: this.record.quiz,
            Alpha: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ', //['A', 'B', 'C','D','E','F', 'G', 'H'],
            showing: 'intro'
        }
    },
    components: {  },
    computed: {
        ...mapState(['localize', 'enrollment', 'users', 'siteconfig']),
        quizstatus: function() {
            // (this.enrollment.list[this.users.currentUser._id] 
            //      && this.enrollment.list[this.users.currentUser._id][this.classid] 
            //      && this.enrollment.list[this.users.currentUser._id][this.classid].progress) || {};
            console.log("quizstatus", this.lessonprogress)
           if(this.lessonprogress && this.lessonprogress.percent ){
            let quizpasspercent = (this.siteconfig && this.siteconfig.quizpasspercent && this.siteconfig.quizpasspercent - 0) || 80
            return this.lessonprogress.percent >= quizpasspercent ? 'passed' : 'not passed';
           } else {
               return false;
           }
        },
        lessonprogress: function(){ 
            let progress = (this.enrollment.list[this.users.currentUser._id] && this.enrollment.list[this.users.currentUser._id][this.classid] && this.enrollment.list[this.users.currentUser._id][this.classid].progress) || {};
            return progress && progress[this.lesson._id] && progress[this.lesson._id];
        },

        options: function() {
            return {}
        }
    },
    methods: {
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },        
        submitquiz: function(){
            console.log("Submitting Quiz", this.questions);
            this.showing === "posting";
            let answers = [];
            for(let i in this.questions){
                answers.push(this.questions[i].youranswer);
            }
            let doc = {
                _id: this.record._id,
                user: this.users.currentUser._id,
                classid: this.classid,
                courseid: this.lesson.course,
                answers: answers
            };
            this.$store.dispatch("enrollment/submitquiz", doc)
                .then((result) => {
                    console.log("Lesson_Quiz submitted", result);
                    // this.$emit("triggerinit");
                    this.showing = false;
                    setTimeout(this.scrollToQuiz, 100)
                })

        },
        scrollToQuiz: function(){
            let el = document.getElementById('quiz-wrapper');
            if(el) el.scrollIntoView({behavior: 'smooth'})
        }
    }
}
</script>