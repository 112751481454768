import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

const axiosconfig = { headers: {} };

import {filterDate, filterId} from './filter';

export default {
    namespaced: true,
    state: {
        list: false
    },
    mutations: {
        removeCourse (state, val) {
            if(typeof val === "string") val = {_id: val};
            if(state.list[val._id]) Vue.delete(state.list, val._id);
        },
        setCourse (state, val) {
            if(val && val._id){
                if(!state.list) state.list = {};
                Vue.set(state.list, val._id, val);
            }
        },
        setList (state, val) {
            state.list = state.list || {};
            for(let k in val){
                Vue.set(state.list, val[k]._id, Object.assign({}, state.list[val[k]._id] || {}, val[k]) );
            }
        }
    },
    actions: {
        getList ({ commit, state }, data) {
            data = data || {};
            if(!data.nocache && state.list){
                return Promise.resolve(state.list);
            }
            if(data.nocache) delete data.nocache;

            return axios.post("/app_courses/list", data, axiosconfig)
                .then((response) => {
                    let result = response && response.data;
                    
                    if(result){
                        for(let k in result){
                            result[k].created = result[k].timestamp ? filterDate(result[k].timestamp, 'YYYY-MM-DD') : "";
                        }
                        console.log('courses.getList', result);
                        commit('setList', result);
                    }
                    return result;
                })
                .catch((e) => {
                    console.log('error getting course list', e)
                })
        },
        get ({ commit, state, dispatch }, data){
            if(!data) return Promise.reject("Missing parameters for getting course");
            if(typeof data === 'string') data = { _id: data };
            data._id = data._id || data.course;
            console.log("getting course", data);

            if(data._id && state.list && state.list[data._id]) return Promise.resolve(state.list[data._id]);

            return dispatch('getList', data)
                .then((result) => {
                    console.log('called getList from courses.get', result)
                    return result[data._id];
                });
        },
        remove ({commit, state}, data) {
            if(!data || !data._id) return Promise.reject("Missing ID");

            console.log("Removing course", data._id);
            commit('removeCourse', data);
            return axios.post("/app_courses/remove", data, axiosconfig)
                .then((response) => {
                    console.log('course removal posted', response);
                    let result = response && response.data;
                    return result;
                })
                .catch((e) => {
                    console.log('error removing course', e);
                })
        },
        save ({commit, state }, data) {
            if(!data._id) Promise.reject("You had one job...");

            let path = '/app_courses/save';
            return axios.post(path, data, axiosconfig)
                .then((result) => {
                    // console.log(result);
                    let record;
                    if(result && result.data && result.data.value){
                        record = result.data.value;
                    } else if(result && result.data){
                        record = result.data;
                    } else {
                        console.log('error in courses.save?', result);
                        return Promise.reject("That apparently didn't work");
                    }
                    if(!record.created && record.timestamp) record.created = filterDate(record.timestamp, 'YYYY-MM-DD');
                    commit('setCourse', record);
                    if(state.list.new) commit('removeCourse', 'new');
                    return record;
                })
                .catch((error) => {
                    console.log('error in courses.save',error, error.response);
                    return error.response;
                })     
        }
    }
}