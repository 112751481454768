<template>
    <div>
        <div v-if="service === 'image'" class='videocontainer'>
            <img :src="url" :height="height" :width="width" :title="title" :alt="title" />
        </div>
        <div v-else-if="service === 'vimeo'" class='videocontainer' @click="trackClick()">
            <iframe :id="'player-'+record._id" frameborder="0" :height="height"  :width="width" class="videoframe" allow="fullscreen"
            :src="vimeoUrl()"
            ></iframe>
        </div>
        <div v-else-if="service === 'youtube' " class='videocontainer' @click="trackClick()">
            <iframe :id="'player-'+record._id" type="text/html" :width="width" :height="height" 
                :src="youtubeUrl" 
                frameborder="0" allowfullscreen
                class="videoframe"
            ></iframe>
        </div>
    </div>
</template>

<script>

const defaults = {
    width: 585,
    height: 330,
    autoplay: 0,
    title: false,
    byline: false,
    url: false
}

export default {
    name: "SharedVideoPlayer",
    props: {
        record: {
            type: [String, Object]
        },
        options: {
            type: Object,
            default: () => {return {}}
        }
    },
    data () {
        return {
            width: this.options.width ||defaults.width,
            height: this.options.height || defaults.height,
            url: (typeof this.record === "object") ? this.record.video : this.record,
            title: false,
            parentwidth: false,
            parentheight: false
        }
    },
    computed: {
        service(){

            if(!this.url) return this.url;

            if(this.url.match(/(jpg|gif|jpeg|png)$/i)) return 'image';

            if(this.url.indexOf('vimeo') > -1) return 'vimeo';

            return 'youtube';

            // v2 logic does the same thing.
            // youtube URLs have the most permutations, so we use it as the fallthrough.

            // let isimage = this.mediaurl.match(/(jpg|gif|jpeg|png)$/i);
            // return (isimage) ? 'image' : this.mediaurl.indexOf('vimeo') ? 'vimeo' : 'youtube';
        },
        youtubeUrl(){
            let regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/; // eslint-disable-line no-useless-escape
            let id = this.url.match(regExp);
            if(!id) return;
            id = id[2];            
            return '//www.youtube.com/embed/' + id + '?enablejsapi=1&autoplay=0&modestbranding=1&showinfo=0&rel=0';
        }
    },
    methods: {
        vimeoUrl: function(){
            let regExp = /(video|vimeo\.com)\/(\d+)/;
            let id = this.url.match(regExp);
            if(!id) return;
            id = id[2];  
            return "//player.vimeo.com/video/" + id + "?title=0&byline=0";
        }
    },
    created () {
        // console.log("Shared Media Player", this.record, this.options);
    },
    mounted () {
        this.parentwidth = this.$parent.$el.offsetWidth;
        let widthfactor = (!this.options.solo && this.parentwidth > 500) ? 0.48 : 0.80;
        let width = this.options.width || (this.parentwidth && this.parentwidth * widthfactor ) || defaults.width
        this.width = (width < 300) ? 330 : width;
        this.parentheight = this.$parent.$el.offsetHeight;
        let height = this.options.height || 
            (this.width * 0.564) ||
            (this.parentheight && this.parentheight ) || 
            defaults.height;
        this.height = (height < 169.2) ? 169.2 : height;
            // console.log("player dimensions", this.$parent.$el, this.parentwidth, this.width, this.parentheight, this.height)
    },
    beforeDestroy() { }
}

</script>