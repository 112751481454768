import Vue from 'vue'
import vuetify from './plugins/vuetify';
import App from './components/App.vue'
import router from './lib/router';
import Meta from 'vue-meta'
import store from './lib/store';

Vue.config.productionTip = false;
Vue.use(Meta, {
    refreshOnceOnNavigation: true
});

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
