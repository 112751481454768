<template>
    <div class="enrollmentList my-3" >
        <div v-if="isLoading">
            <v-container  v-html="loc('Loading...')"></v-container>
        </div>
        <v-row v-else-if="!enrollmentList || !enrollmentList.length " class="mb-10">
            <h3 v-if="!hasOlder">{{ loc("You have not enrolled in any classes yet.") }}</h3>
        </v-row>   
        <div v-else >
            <div v-for="(item) in enrollmentList" :key="item._id">
                <v-row class="pl-3" ><h3>{{ classRecord(item, 'name') }}</h3></v-row>
                <div v-if="editId === item._id" class='enrollmentrow isEditing elevation-2 mt-4' >
                    <v-row>
                        <v-col cols="12" md="9">
                            <v-row class= "enrollmentcourse pt-4"><div class='label'>{{ loc("Course") }}</div><div class='value'>{{ courseRecord(item, 'title') }} - {{ courseRecord(item, 'reftitle') || courseRecord(item, 'main') || ""}}</div></v-row>
                            <v-row><v-text-field v-model="manageRecord.regdate" name="regdate" :label="loc('Enrolled Date')"></v-text-field></v-row>
                            <v-row ><v-text-field v-model="manageRecord.completed" name="completed" :label="loc('Completed Date')"></v-text-field></v-row>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col v-if="isEditor"  class='editEnrollmentButton' sm="12" md="3">
                            <v-btn small color="primary" dark @click="handleSave(item)">{{ loc('Save') }}</v-btn>
                            <v-btn small color="secondary" dark @click="handleEditCancel">{{ loc("Cancel") }}</v-btn>
                        </v-col>  
                    </v-row>
                </div>
                <div v-else-if="removeId === item._id" class='enrollmentrow isEditing elevation-2 my-4 pa-4'>
                    <v-row class="my-3">
                        {{ loc('Please confirm removal of this enrollment record.') }}
                    </v-row>
                    <v-row class='editEnrollmentButton' >
                        <v-btn small color="error" dark @click="handleRemove(item)" >{{ loc("Remove") }}</v-btn> &nbsp;
                        <v-btn small color="secondary" dark @click="handleEditCancel">{{ loc("Cancel") }}</v-btn>
                    </v-row>
                </div>
                <div v-else class='enrollmentrow' >
                    <v-row>
                        <v-col cols="12" md="9">
                            <v-row class= "enrollmentcourse pt-4"><div class='label'>{{ loc("Course") }}</div><div class='value'>{{ courseRecord(item, 'title') }} - {{ courseRecord(item, 'reftitle') || courseRecord(item, 'main') || ""}}</div></v-row>
                            <v-row v-if="item.description && item.description.length"><div class="label">{{ loc("Class Description") }}</div><div class="value" v-html="item.description"></div></v-row>
                            <v-row><div class='label'>{{ loc("Enrolled Date") }}</div><div class='value'>{{filterDate(item.regdate)}}</div></v-row>
                            <v-row v-if="item.completed">
                                <div class='label'>{{ loc("Completed Date!") }}</div>
                                <div class='value'>{{filterDate(item.completed)}} <a :href="'/app_users/certificate/' + item.course" v-if="hasCert(item)" target="_blank" class="ml-6">Certificate</a></div></v-row>
                            <v-row v-if="!item.completed && users.currentUser._id === userId && (courseRecord(item, 'status') !== 'Inactive')">
                                <router-link class='continueClass' :to="'/lessons/'+item.course+'/next/'+item.classid">{{loc("Continue Class")}}</router-link>
                            </v-row>
                            <v-row v-else-if="item.notappearinginthismovie === 'showinactive' && (courseRecord(item, 'status') === 'Inactive')">
                                {{ loc('Inactive Course') }}
                            </v-row>

                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col v-if="isEditor"  class='editEnrollmentButton' sm="12" md="3">
                            <v-btn small color="primary" dark @click="handleEdit(item)">{{ loc('Edit') }}</v-btn>
                            <v-btn small color="error" dark @click="handleConfirmRemove(item)" >{{ loc("Remove") }}</v-btn>

                        </v-col>  
                    </v-row>
                </div>
            </div>
        </div>
        <div v-if="isEditor && !addRecord && !isLoading && !isSelf">
            <v-row class="mb-2 classrow" v-if="availableclasses">
                <div class="label addLabel" style='margin-left:0;'>{{ loc("Add User to Class") }}:</div>
                <div class='value'><v-select :label="loc('Select a Class')" :items="availableclasses" name="addclass" @change="handleAdd($event)" /></div>
            </v-row>
        </div>
        <v-row >
            <v-col cols="12" v-if="hasOlder">
                <v-btn color="tertiary" class="mr-4" @click="hideOlder = !hideOlder">{{ hideOlder ? loc('Show Older') : loc('Hide Older') }}</v-btn> (before {{lastyear}})
            </v-col>
            <v-col cols="12" v-if="isSelf">
                <v-btn color="primary" to="/users/enroll">{{ loc('Enroll in Another Course') }}</v-btn>
            </v-col>
        </v-row>
        
        <!-- <v-row class="ma-10">
            <pre>{{enrollment.list}}</pre>
        </v-row> -->
    </div>
</template>

<script>
import { mapState } from "vuex";
import _sortBy from 'lodash/sortBy';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import {
        filterDate as _filterDate,
        filterDateTime as _filterDateTime,
        isValidDate as _isValidDate
    } from "../lib/filter";

const lastyear = _filterDate(Date.now() - (1000 * 60 * 60 * 24 * 365),  "YYYY-MM-DD");

export default {
    name: "UserEnrollmentList",
    props: {
        userId: { type: String },
        record: { type: [Object, Boolean], default: false }, // this is the user record.
        noEdit: { type: Boolean, default: false }
    },
    data() {
        return {
            isLoading: true,
            lastyear: lastyear,
            userRecord: this.record, // obj
            // userId: this.record && this.record._id, // string id of user record.
            enrollmentRecords: false, // array
            editId: false,
            removeId: false,
            manageRecord: {}, // string enrollment record id being edited.
            addRecord: false,
            hideOlder: true,
            isEditor: false
        }
    },
    computed: {
        ...mapState(["authError", "users", "courses", "classes", "enrollment", "localize"]),
        enrollmentList: function() {
            // let list = {};
            // if(!this.enrollment.list) return list;
            
            let list = Object.assign({}, this.enrollment.list[this.userId]);

            if(this.addRecord && !list[this.addRecord._id]){ 
                list[this.addRecord._id] = this.addRecord;
            }

            list = _filter(list, 'regdate')
            if(this.hideOlder){
                list = _filter(list, (o) => {
                    return !!(o.regdate >= lastyear )
                })
            }

            return list; // Object.values(list);
        },
        classList: function() {
            return this.classes.list;
        },
        availableclasses: function() {
            // console.log(this.classes.list);
            let out = [ ];
            for(let k in this.classes.list){
                if(this.enrollment && this.enrollment.list && this.enrollment.list[this.userId] && this.enrollment.list[this.userId][k]) continue;
                let courseid = this.classes.list[k].course;
                if(!courseid || !this.courses.list || !this.courses.list[courseid]) continue;
                let title = (this.courses.list[courseid].reftitle || this.courses.list[courseid].title || courseid);
                title += ": " + ((this.classes.list[k].name) ? ( this.classes.list[k].name + " (" + k +")" ) : k);
                out.push({value: k, text:  title })
            }
            return _sortBy(out, 'text');
        },
        availableContexts: function(){
            let list = {}; 
            for(let k in this.content.list){
                if(this.content.list[k].context && typeof this.content.list[k].context === "string") list[this.content.list[k].context] = {text: this.content.list[k].context, value: this.content.list[k].context};
            }
            list = _sortBy(Object.values(list), 'text');
            // list.unshift({text:'Select a Context', value: ''});
            // console.log(list);
            return list;
        },   
        isSelf() {
            return this.userId === this.users.currentUser._id;
        },   
        hasOlder() {
            const val = _find(this.enrollment.list[this.userId], (o) => {return !!(o.regdate <= lastyear )});
            // console.log('hasOlder', val, this.enrollment.list[this.userId])
            return val;
        }, 
        
    },
    methods: {
        getEnrollment: function(options){

            let query = options || (this.userId) ? {user: this.userId } : {};
            this.isLoading = true;
            this.$store.dispatch("enrollment/getList", query)
                .then((result) => {
                    console.log("Enrollment List result", result);
                    this.isLoading = false;
                    // this.enrollmentRecords = result;
                    // console.log("enrollmentList", this.enrollmentList)
                })
                .catch((e) => {
                    this.isLoading = false;
                });

        },
        handleEdit: function(item){
            this.editId = item._id;
            this.manageRecord = Object.assign({}, item)
            this.removeId = false;
        },
        handleEditCancel: function(item){
            console.log('handleEditCancel', item);
            this.manageRecord = {};
            this.editId = false;
            this.addRecord = false;
            this.removeId = false;
        },
        handleSave: function(item){
            console.log('handleSave', item);
            this.$store.dispatch("enrollment/save", this.manageRecord);
            this.manageRecord = {};
            this.editId = false;
            this.addRecord = false;
        },
        handleConfirmRemove: function(item){
            console.log('handleConfirmRemove', item);
            this.manageRecord = {};
            this.editId = false;      
            this.addRecord = false;      
            this.removeId = item._id;
        },
        handleRemove: function(item){
            console.log("handleRemove", item);
            this.manageRecord = {};
            this.editId = false;
            this.addRecord = false;
            this.removeId = false;
            this.$store.dispatch("enrollment/remove", item);
        },
        handleAdd: function(classid){
            console.log('handleAdd', classid);
            this.addRecord =  { 
                _id:  this.userRecord._id + '-' + classid,
                course: this.classes.list[classid] && this.classes.list[classid].course,
                classid: classid,
                user: this.userRecord._id
            };
            if(this.classes.list && this.classes.list[classid].regcode) this.addRecord.regcode = this.classes.list[classid].regcode;
            this.editId = this.addRecord._id;
            this.manageRecord = this.addRecord;
            this.removeId = false;
            // let adding = {...(this.addRecord.value || this.addRecord)};
            // adding._id = 'new';
            // this.$store.dispatch("enrollment/save", adding);
        },
        hasCert: function(item) {
            return this.courseRecord(item, 'certificate')  && this.userId === this.users.currentUser._id && (this.userRecord && this.userRecord.certifications && this.userRecord.certifications[item.course]);
        },
        init: function(options){
            console.log("UserEnrollmentList.init", options)
            options = options || {};
            
            if(typeof options === 'string'){    
                 options = {_id: options };
            }

            if(!options._id && this.userId) options._id = this.userId;

            if(!this.userRecord ){
                if(!options._id) return;
                console.log("Enrollment List getting user", this.userRecord);
                return this.$store.dispatch("users/get", options)
                    .then((result) => {
                        this.userRecord = result;
                        this.init(options);
                        // will this be a memory leak?
                    })
            } else {
                this.isEditor = !this.noEdit && this.users.currentUser.isEditor;
                this.getEnrollment({user: this.userId});
            }
        },
        classRecord: function(item, field){
            if(this.classes && this.classes.list && this.classes.list[item.classid]){
                if(field) return this.classes.list[item.classid][field];
            } else {
                console.log("classRecord", this.classes && this.classes.list);
                return this.classes.list[item.classid];
            }
        },
        courseRecord: function(item, field){
            if(this.courses && this.courses.list && this.courses.list[item.course]){
                if(field) return this.courses.list[item.course][field];
            } else {
                return this.courses.list[item.course];
            }
        },
        filterDate: function(val, format){
            format = format || "YYYY-MM-DD";
            return _filterDate(val, format);
        },
        filterDateTime: function(val, format){
            return _filterDateTime(val, format);
        },        
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        }
    },
    
    created () {
        this.init(this.$route.params.pathMatch);
    },
    watch: {
        '$route.params.id': {
            handler: function(s){
                let id = s || (this.$route.params && this.$route.params.pathMatch);
                if(this.userId !== id) this.init(id);
            },
            deep: true,
            immediate: false
        }
    }
}
</script>