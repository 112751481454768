import dayjs from 'dayjs';
import sanitizeHtml from 'sanitize-html';
import _isFinite from 'lodash/isFinite';
import _without from 'lodash/without';
import _random from 'lodash/random';

const beautify_html = require('js-beautify').html;
// const beautify_js = require('js-beautify').js;
// const beautify_css = require('js-beautify').css;

const notstringable = ["object", "function", "symbol"];

/**
 *  FILTER METHODS
 *  These methods typically strip unwanted characters.
 *  They should always return a string.
 *  To use for validation, check that the output == the input.
 */


export function filterColor (val){
    if(val.indexOf("#") !== 0) val = "#" + val;
    return val.match(/^#([0-9a-f]{3}){1,2}$/i) && val.toUpperCase();
}

export function filterDate (val, options) {
    if (!val || (!val.length && val < 1)) { return '' }
    if (typeof val === 'string' && val.match(/^\s*\d{1,2}:\d{1,2}(:\d{1,2}){0,2}\s*$/)) val = dayjs().format('YYYY-MM-DD') + val;
    options = options || 'YYYY-MM-DD HH:mm:ss'
    val = dayjs(val).format(options)

    // if this is a number, we want to return it as an integer.  lodash may be better here?
    let n = parseInt(val, 10)
    if (val == n) val = n; // eslint-disable-line eqeqeq
    return val
}

export function filterDateTime (val, options) {
    if (!val || (!val.length && val < 1)) { return '' }
    // take a dateless time string and add today's date to the front.
    if (typeof val === 'string' && val.match(/^\s*\d{1,2}:\d{1,2}(:\d{1,2}){0,2}\s*$/)) val = dayjs().format('YYYY-MM-DD') + val;
    options = options || 'YYYY-MM-DD HH:mm:ss'
    val = dayjs(val).format(options)
    // console.log(val, f);
    // if this is a number, we want to return it as an integer.  lodash may be better here?
    let n = parseInt(val, 10)
    if (val == n) val = n; // eslint-disable-line eqeqeq
    return val
}

/**
 * see https://github.com/apostrophecms/sanitize-html
 * @param {*} val
 * @param {*} options
 */
export function filterHTML (val, options) {
    if( !val || notstringable.indexOf(typeof val) > -1 ) return "";

    const config = {
        allowedTags: [
            "a",
            "b",
            "blockquote",
            "br",
            "caption",
            "center",
            "code",
            "div",
            "em",
            "head",
            "h1",
            "h2",
            "h3",
            "h4",
            "h5",
            "h6",
            "hr",
            "i",
            "img",
            "li",
            "nl",
            "ol",
            "p",
            "pre",
            "span",
            "strike",
            "strong",
            // "style",
            "table",
            "tbody",
            "td",
            "th",
            "thead",
            "tr",
            "u",
            "ul"
        ],
        disallowedTagsMode: 'discard',
        allowedAttributes: {
            a: ['href', 'name', 'target'],
            img: ['src', 'height', 'width', 'class', 'title', 'alt'],
            "*": [ 'href', 'align', 'alt', 'center', 'bgcolor', 'id', 'name', 'style', 'class']
        },
        // Lots of these won't come up by default because we don't allow them
        selfClosing: ['img', 'br', 'hr', 'area', 'base', 'basefont', 'input', 'link', 'meta'],
        // URL schemes we permit
        allowedSchemes: ['http', 'https', 'mailto','data'],
        allowedSchemesByTag: {},
        allowedSchemesAppliedToAttributes: ['href', 'src', 'cite'],
        allowProtocolRelative: true
        // @TODO add allowedStyles https://github.com/apostrophecms/sanitize-html#allowed-css-styles
    };

    if(options === "strip" || options.strip){
        let allowedTags = [], allowedAttributes = {};
        let strip = options.strip || options;
        if(strip && strip !== true && strip.length){ 
            
            allowedTags = _without(config.allowedTags, ...strip); 
            allowedAttributes = _without(config.allowedAttributes['*'], ...strip);
            console.log("Stripping", strip)
        }

        options = { allowedTags: allowedTags, allowedAttributes: {
            a: config.allowedAttributes.a,
            img: config.allowedAttributes.img,
            "*": allowedAttributes
        }}; 
    }
    options = (options && typeof options === "object") ? Object.assign({}, config, options) : config;
    console.log("filterHTML applying options", options);

    return sanitizeHtml(val, options);

}

export function stripTags(val) {
    return filterHTML(val, {strip: true})
}


export function filterId (val) {
    if( !val || notstringable.indexOf(typeof val) > -1 ) return "";
    return val.toString().replace(/[^\w._-]/g,'');
}

export function filterWordsStrict (val) {
    if( !val || notstringable.indexOf(typeof val) > -1 ) return "";
    return val.toString().replace(/[^\w._ -]/g,'')
}

export function filterWords (val) {
    if( !val || notstringable.indexOf(typeof val) > -1 ) return "";
    return val.toString().replace(/[/!{}\\$%#"@*]/g,'')
}


/**
 *  FORMAT METHODS
 *  These methods take a value and return a different value based on the input.
 *  For example, formatDate accepts something that is parsable as a date, and returns
 *  a formatted date string based on the defined format.
 */


export function formatHTML (val, options) {
    const defaults = {
        indent_size: 4,
        // wrap_line_length: 80,
        html:{
            max_preserve_newlines: 0
        },
        js: {
            "preserve-newlines": true
        }
    };

    return beautify_html(val, Object.assign({}, defaults, options ));
}

export function formatBoolean (val) {
  return (val) ? "True" : "False";
}

export function formatCapitalizeFirst (val) {
    if( !val || notstringable.indexOf(typeof val) > -1 ) return "";
    return val.toString().charAt(0).toUpperCase() + val.slice(1);
}

export function formatDate (val, options) {
    options = options || 'YYYY-MM-DD'
    return dayjs(val).format(options)
}

export function formatDateTime (val, options) {
    options = options || 'YYYY-MM-DD HH:mm:ss'
    return dayjs(val).format(options)
}

export function formatDay (val, options) {
    options = options || 'D MMM';
    return dayjs(val).format(options)
}

/**
 *  IS (Definition) METHODS
 *  These methods accept a value and return a boolean if it matches the 
 *  specified type or other requirement.
 */

export function isNumber (val) {
    return _isFinite(val)
}

/**
 * tests if input is a string.
 * @param {string} val the value to test
 * @param {number or object} options minimum length of string
 * @returns boolean
 */
export function isString(val, options) {
    if(typeof val !== "string") return false;
    if(!options) return true;
    let len = (isNumber(options)) ? options : (options.len || 0);
    return val.length >= len;
}


export function isValidDate (val) {
    return dayjs(val).isValid();
}

export function makeId (len) {
        len = len || 36;

        const chars = ["0","1","2","3","4","5","6","7","8","9","A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"];
        let uuid = [];
        for (let i = 0; i < len; i++) {
            if(len == 36){
                if (i==8 || i==13 ||  i==18 || i==23) {
                    uuid[i] = '-';
                } else if(i==14){
                    uuid[i] = 4;
                } else {
                    uuid[i] = chars[_random(0, 35)];
                }
            } else {
                uuid[i] = chars[_random(0, 35)];
            }
        }
        if(len == 36) uuid[19] = chars[(uuid[19] & 0x3) | 0x8];

        return uuid.join('');
    
}

export function makeDateId(){
    return Date.now().toString(36);
}

export function jsonToString(val){
    try{
        const out = JSON.stringify(val);
        return out;
    } catch(e){
        console.log("Parse error", e);
        return val;
    }
}

export function strToJson(val){
    if(!val || typeof val !== 'string') return val;
    const isObjectOrArray = /^\s*(\{|\[)/.test(val);
    console.log(val, 'is a stringified Object or Array:', isObjectOrArray)
    if(!isObjectOrArray) return val;
    try {
        let out = JSON.parse(val);
        return out;        
    } catch(e){
        console.log("Parse error", e); // console.log is intentional here
        return val;
    }
}

/**
 * Validate input based on rules in schema, using other methods in this module.
 * @param {*} val value to validate based on the schema
 * @param {object} schema definition used for validation
 *      - type - defines the rules to validate against
 *      - invalidmessage - message to return if input is invalid.
 * @returns string or false
 */
export function validate(val, schema, match) {
    if(!schema || !schema.type) return false;
    if(!schema.invalidmessage) schema.invalidmessage = "Not valid";

    const types = {
        words: () => {
            if(!isString(val, schema.validopts || 0)) return schema.invalidmessage;
            if(schema.minlength && val.length < schema.minlength) return schema.invalidmessage;
            if(filterWords(val) != val) return schema.invalidmessage;
            return false;
        }, 
        email: () => {
            // this is very minimal.  It allows whitespace and multiple @, for example.  So it won't
            // prevent intentional garbage, but should help most people not mess it up accidentally.
            if(val && val.length > 4 && val.match(/^.+@.+\..+$/) ) return false;
            return schema.invalidmessage;
        },
        password: () =>{
            // this should be modified to support whatever the actual password policy should be.
            // currently it requires at least 8 characters and at least one non-word character.
            // if(!val || val.length < 8 || !val.toString() || !val.toString().match(/\W/) ) return schema.invalidmessage;
            if(!val || val.length < 8 || !val.toString() ) return schema.invalidmessage;
            return false;
        },
        phone: () => {
            if(!val) return false;
            if( notstringable.indexOf(typeof val) > -1 || val.toString().match(/[^0-9+(). -]/g)) return schema.invalidmessage;
            return false;
            
        },
        matches: () => {
            if(!val) return false;
            if(val == match) return false;
            return schema.invalidmessage;
        }
    }

    if(types[schema.type]) return types[schema.type]();

    return false;
}