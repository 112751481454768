<template>
    <v-card class="pa-3 elevation-0 codeeditorparent"  style="width:100%" >
        <v-row>
            <v-btn @click="setOption('lineWrapping', !wrap)">
                <v-icon>wrap_text</v-icon>
            </v-btn>
            <v-btn @click="format" class="ml-2">
                {{ loc('Format') }}
            </v-btn>               
            <v-btn @click="cleanUp" class="ml-2">
                {{ loc('Clean Up') }}
            </v-btn>        
            <v-btn @click="stripTags" class="ml-2">
                {{ loc('Strip Tags') }}
            </v-btn> 
        </v-row>
        <v-row id="codeeditor" class="codeeditor" >
        </v-row>
    </v-card>    
</template>

<script>
import { mapState } from 'vuex';
import CodeMirror from "codemirror"

import 'codemirror/mode/htmlmixed/htmlmixed.js'
import 'codemirror/mode/css/css.js'
import 'codemirror/mode/javascript/javascript.js'

import 'codemirror/addon/hint/anyword-hint.js';
import 'codemirror/addon/hint/css-hint.js';
import 'codemirror/addon/hint/html-hint.js';
import 'codemirror/addon/hint/javascript-hint.js';
import 'codemirror/addon/hint/show-hint.js';

import 'codemirror/addon/dialog/dialog.js'
import 'codemirror/addon/search/searchcursor.js'
import 'codemirror/addon/search/search.js'
import 'codemirror/addon/search/match-highlighter.js'
import 'codemirror/addon/search/jump-to-line.js'

import { filterHTML, formatHTML, stripTags } from '../lib/filter.js';

var initretries = 0;

export default {
    name: "SharedEditorCode",
    props: {
        data: {
            type: String || Boolean,
            default: ""
        },
        handleChange: {
            type: Function
        },
        mode: { 
            type: [String, Boolean], 
            default: false
        },
        options: {
            type: Object,
            default: () => { return {} }
        }
    },
    data () {
        return {
            editor: false,
            content: this.data,
            wrap: false,
            // mode: false
        }
    },
    computed: {
        ...mapState(['users', 'localize'])
    },
    methods: {
        init () {
            // console.log("Code editor init");
            if(this.data || this.data === ''){
                this.editor = CodeMirror(document.querySelector('#codeeditor'),{
                    value: this.data,
                    lineWrapping: this.wrap,
                    mode: this.mode || 'text/html',
                    lineNumbers: true
                });
                this.editor.on('change', (cm) => {
                    this.setContent(cm.getValue());
                })
                // console.log("Code editor done", this.data.length);
            } else if(initretries < 5) {
                initretries++;
                setTimeout(this.init(), 1000);
            } else {
                console.log("Unable to initialize editor");

            }
        },
        setContent (value) {
            this.handleChange(value, this.options && this.options.key);
            this.content = value;
        },
        setOption (key, value) {
            this.editor.setOption(key, value);
        },
        cleanUp () {
            let content = formatHTML(filterHTML(this.content, {strip: ['span', 'style']}));
            this.editor.getDoc().setValue(content);
        },
        stripTags () {
            let content = formatHTML(filterHTML(this.content, {strip: true}));
            this.editor.getDoc().setValue(content);
        },
        format () {
            let options = {
                indent_size: 4,
                // wrap_line_length: 80,
                html:{
                    max_preserve_newlines: 0
                },
                js: {
                    "preserve-newlines": true
                }
            }

            this.editor.getDoc().setValue(formatHTML(this.content, options));
        },
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },

    },
    mounted () {
        if(this.options && this.options.wrap !== undefined) this.wrap = this.options.wrap;
        this.init();
    }
}
</script>

<style src="codemirror/lib/codemirror.css"></style>
<style src="codemirror/theme/idea.css"></style>
<style src="codemirror/addon/hint/show-hint.css"></style>
<style>
    .CodeMirror {
        margin: 4px;
        width: 100%;
    }
</style>
