<template>
    <v-container>
        <!-- <h1>{{ loc("Register") }}</h1> -->
        <v-row v-if="embed && users.currentUser && users.currentUser.isEditor"  >
            <v-col v-if="isEditing" class="elevation-2 pa-5">
                <v-row><h2>{{ loc('Registration Panel Settings') }}</h2></v-row>
                <v-row>{{loc('Where should the user be sent after registration?  This should be a URL path.')}}</v-row>
                <v-row>
                    <v-col cols="12">
                        <v-text-field outlined v-model="sendto" :label=" loc('Destination after Registration') " ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-select :label="loc('Enroll in Class')" key="classselect" v-model="classId" :items="availableClasses" ></v-select>
                    </v-col>
                </v-row>                
                <v-row>
                    <v-col >
                        <v-btn class="mr-4" @click="handleSettingsChange" color="primary" >{{ loc("Save") }}</v-btn> 
                        <v-btn @click="isEditing = false">{{ loc("Cancel") }}</v-btn>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="text-right">
                        <v-btn color="error" @click="$emit('actionhandler', {action: 'remove', record: record})">{{ loc("Remove From Page") }}</v-btn>
                    </v-col>
                </v-row>
            </v-col>
            <v-col  v-else>
                <div style="position:relative; width: 100%">
                    <v-card><v-card-text>
                        <v-row>
                            <v-col>
                            Registration Block (hidden for authenticated users)
                            </v-col>
                            <v-col class="text-right">
                                <v-btn color="secondary" fab small absolute @click="isEditing = !isEditing" title="Registration Block Properties" >
                                    <v-icon>settings</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text></v-card>

                </div>
            </v-col>
        </v-row>
        <div v-if="(users.loggedout || !users.currentUser)">
            <v-row >
                <v-col id="register-intro" style="min-height:130px;" class='feature' v-if="!embed">
                    <ContentBlockSimple  :record="content_registerintro"  />
                </v-col>            
            </v-row>
            <v-form ref="registration" autocomplete="off" @submit.prevent="handleSubmit" class="register-form">
                <v-row style="height:30px;" class="ma-3">
                    <v-alert v-if="submitalert" dense type="error" outlined>{{submitalert}}</v-alert>
                </v-row>
                <v-row>
                    <v-col cols="12" md="4">
                        <v-text-field outlined v-model="firstname" :label=" loc('First Name') " @change="validMessage('firstname')" :error-messages="firstname_error" ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field outlined v-model="lastname" :label=" loc('Last Name') " @change="validMessage('lastname')" :error-messages="lastname_error" ></v-text-field>
                    </v-col>
                </v-row>    
                <v-row>
                    <v-col cols="12" md="4">
                        <v-text-field outlined v-model="email" :label=" loc('Email Address') " autocomplete="no" @change="validMessage('email')" :error-messages="email_error"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field outlined v-model="confirmemail" :label=" loc('Confirm Email') " autocomplete="no" @change="validMessage('confirmemail')" :error-messages="confirmemail_error" ></v-text-field>
                    </v-col>

                </v-row>
                <v-row>
                    <v-col cols="12" md="4">
                        <v-text-field outlined v-model="phone" :label=" loc('Phone') " @change="validMessage('phone')" :error-messages="phone_error" ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field outlined v-model="city" :label="loc('City')"  @change="validMessage('city')" :error-messages="city_error"></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="4">
                        <v-select outlined v-model="country" :items="countries" :label="loc('Country')" @change="validMessage('country')"  :error-messages="country_error" ></v-select>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field outlined v-model="state" :label="loc('State')" ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="4" >
                        <v-text-field outlined
                            v-model="password"
                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show1 ? 'text' : 'password'"
                            name="password"
                            :label=" loc('Password') "
                            :hint=" loc('At least 8 characters') "
                            counter
                            @click:append="show1 = !show1"
                            autocomplete="new-password"
                            @change="validMessage('password')" :error-messages="password_error"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field outlined 
                            v-model="confirmpassword" 
                            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'" 
                            :type="show2 ? 'text' : 'password'"
                            @click:append="show2 = !show2" 
                            :label=" loc('Confirm Password') "  
                            @change="validMessage('confirmpassword')" 
                            :error-messages="confirmpassword_error" 
                        ></v-text-field>
                    </v-col>                
                </v-row>
                <v-row class="mb-0 pb-0">
                    <v-col class="mb-0 pb-0 font-weight-bold">
                    <a href="/Terms_of_Use" target="_blank">{{ loc('Terms of Use') }}</a>
                    </v-col>
                </v-row>
                <v-row  class="mt-0 pt-0">
                    <v-col class="mt-0 pt-0">
                        <v-checkbox :label="loc('Yes, I have read and agree to the Terms of Use')" v-model="agreedterms" dense></v-checkbox>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="4">
                        <v-btn class="mr-4" type="submit" id="button-reg-submit" color="primary" >{{ loc("Submit") }}</v-btn> 
                        <v-btn @click="clear" id="button-reg-clear">{{ loc("Clear") }}</v-btn>
                    </v-col>
                    <v-col cols="12" md="8"><router-link :to="'/Login'">{{ loc('If you have an account already please login here') }}</router-link></v-col>
                </v-row>
            </v-form>
        </div>
    </v-container>
</template>

<script>
import { mapState } from 'vuex';
import { validate } from '../lib/filter';
import ContentBlockSimple from './Content_Block_Simple';
import _sortBy from 'lodash/sortBy';
import _filter from 'lodash/filter';
// import siteconfig from '../config/site.js';

const fieldSchema = {
    firstname: {
        type: 'words',
        invalidmessage: "This field is required, and should be a name.",
        minlength: 1
    },
    lastname: {
        type: 'words',
        invalidmessage: "This field is required, and should be a name.",
        minlength: 1
    },
    city: {
        type: 'words',
        invalidmessage: "This field is required",
        minlength: 1
    },
    state: {
        type: 'words',
        invalidmessage: "Please enter a state or province for your location"
    },
    country: { // in the long run, this needs to be a typeahead with a preset list.
        type: 'words',
        invalidmessage: "This field is required."   ,
        minlength: 1     
    },
    email: {
        type: 'email',
        invalidmessage: "An email address is required.",
        minlength: 1 
    },
    confirmemail: {
        type: 'matches',
        matches: 'email',
        invalidmessage: 'Email fields must match'
    },
    phone: {
        type: 'phone',
        invalidmessage: 'The phone number should include numbers and any of these characters: "+-()."'
    },
    password: {
        type: 'password',
        invalidmessage: 'A password is required, and must include at least one special character.'
    },
    confirmpassword: {
        type: 'matches',
        matches: 'password',
        invalidmessage: 'Passwords must match.',
        minlength: 1 
    }
}

export default {
    name: "Register",
    metaInfo () {
        let meta = {
            title: this.loc("Registration")
        };
        if(this.siteconfig && this.siteconfig.sitetitle) meta.titleTemplate = this.siteconfig.sitetitle;
        return meta;
    } ,
    props: {
        after: { type: String },
        record: { type: Object },
        options: { type: Object }
    },
    data () {
        return {
            submitalert: false,
            embed: this.options && this.options.embed,
            classId: this.record && this.record.classId,
            isEditing: false,
            agreedterms: false,

            firstname: '',
            firstname_error: '',
            lastname: '',
            lastname_error: '',
            city: '',
            state: '',
            country: '',
            country_error: '',
            email: '',
            email_error: '',
            confirmemail: '',
            confirmemail_error: '',
            phone: '',
            phone_error: '',
            password: '',
            password_error: '',
            confirmpassword: '',
            confirmpassword_error: '',
            show1: false,
            show2: false,

            sendto: this.after || (this.options && this.options.after),
            content_registerintro: {type:'snippet', _id:'registerintro'}
        }
    },
    components: { ContentBlockSimple },
    computed: {
        ...mapState(['siteconfig', 'users', 'localize', 'content', 'courses', 'classes']),
        introblock: function () {
            return '';
        },
        availableClasses: function(){
            if(!this.classes || !this.classes.list) return [];
            let out = [];
            let list = _filter(this.classes.list, {selfpaced: true})

            for(let i in list){
                let coursetitle = (this.courses && this.courses.list && this.courses.list[list[i].course] && this.courses.list[list[i].course].status !== "Inactive" && this.courses.list[list[i].course].title) || false;
                if(coursetitle) out.push({ value: list[i]._id, text: coursetitle + ": " + list[i].name});
            }
            out = _sortBy(out, ['title'])
            return out;
        },
        countries: function() {
            let list = (this.siteconfig && this.siteconfig.countries);
            if(list && !list.length) list = Object.values(list);
            console.log('countries', list)
            return list || [];
        },    
    },
    methods: {
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },
        clear: function() {
            for(let k in fieldSchema){
                this[k] = '';
                this[k + '_error'] = '';
            }
        },

        validMessage: function(field) {
            this[field + '_error'] = this.loc(validate(this[field], fieldSchema[field], fieldSchema[field].matches && this[fieldSchema[field].matches]) || ''); 
        },
        handleSubmit: function() {
            let valid = true;
            let regobj = {};
            if(!this.agreedterms){
                this.submitalert = this.loc("Please agree to the Terms Of Use");
                return;
            }
            for(let k in fieldSchema){
                this.validMessage(k);
                if(this[k + '_error'] && this[k + '_error'].length ){
                    console.log("invalid:", k, this[k], this[k + '_error']); 
                    valid = false;
                }
                regobj[k] = this[k];
            }
            if(!valid){
                this.submitalert = this.loc("Please correct the highlighted fields before submitting the form.")
            } else {
                this.submitalert = false;
                regobj.regpath = this.$route.path;
                if(this.$route.params && this.$route.params.subid) regobj.regsubid = this.$route.params.subid;
                if(this.classId){ 
                    regobj.classId = this.classId; 
                    regobj.courseId = this.classes.list[this.classId].course;
                }

                this.$store.dispatch("users/register", regobj)
                    .then((result) => {
                        if(result.info || result.error){
                            let message = (result.info && result.info.message) || (result.error && result.error.message)
                            this.submitalert = this.loc(message);
                        } else {
                            console.log('registration component got result', result);
                            this.$router.push(this.sendto || '/');
                        }
                    })
                    .catch((e) => {
                        console.log("error submitting registration", e);
                        this.submitalert = this.loc("There was an error submitting the form.");
                    })
            }
        },
        handleSettingsChange: function(evt){
            // this.$emit('')
            console.log('embedded settings', this.sendto, this.classId)

            let record = {...this.record};
            record.after = this.sendto;
            record.classId = this.classId;
            this.$store.dispatch('content/save', record)
                .then((result) => {
                    console.log("content block save", result)
                    this.isEditing = false;
                })


        },
        handleRemove: function(val){
            this.$emit("actionhandler", "remove", this.item, val);
        },
    },
    mounted () { if(this.after) this.$store.dispatch('users/setAfter', this.after)   },
    beforeDestroy(){
        let scripthead = document.getElementById('scripthead');
        if(scripthead) scripthead.remove();
    },

}
</script>
