import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

const axiosconfig = { headers: {} };

import {filterDate, filterId} from './filter';

export default {
    namespaced: true,
    state: {
        list: false,
        responses: false,
    },
    mutations: {
        removeItem (state, val){
            if(typeof val === "string") val = {_id: val};
            if(state.list[val._id]) Vue.delete(state.list, val._id);            
        },
        setItem (state, val) {
            if(val && val._id){
                if(!state.list) state.list = {};
                Vue.set(state.list, val._id, val);
            }
        },        
        setList (state, val) {
            if(!val) return;
            state.list = state.list || {};
            for(let k in val){
                Vue.set(state.list, val[k]._id, Object.assign({}, state.list[val[k]._id] || {}, val[k]) );
            }
        },
        setResponseList (state, val) {
            if(!val) return;
            state.responses = state.responses || {};
            for(let k in val){
                Vue.set(state.responses, val[k]._id, Object.assign({}, state.responses[val[k]._id] || {}, val[k]) );
            }
        }
    },
    actions: {
        get ({commit, state}, data) {
            if(!data || !data._id) Promise.reject("I am not sure what to get.");

            // if(state.list && state.list[data._id]) return Promise.resolve(state.list[data._id]);

            return axios.post("/app_surveys/getSurvey", data, axiosconfig)
                .then((response) => {
                    let result = response && response.data;
                    commit('setItem', result)
                    return result;
                })
                .catch((e) => {
                    let error = e && e.response;
                    console.log("error getting survey", error)
                    return error;
                })
        },
        getList ({commit, state }, data) {
            data = data || {};
            return axios.post("/app_surveys/listSurveys", data, axiosconfig)
                .then((response) => {
                    let result = response && response.data;
                    commit('setList', result)
                    return result;
                })
                .catch((e) => {
                    console.log("error getting survey list.")
                })
        },
        save ({ commit, state }, data ) {
            if(!data._id) Promise.reject("You had one job...");

            console.log("Saving", data);

            let path = '/app_surveys/saveSurvey';
            return axios.post(path, data, axiosconfig)
                .then((result) => {
                    // console.log(result);
                    let record;
                    if(result && result.data && result.data.value){
                        record = result.data.value;
                    } else if(result && result.data){
                        record = result.data;
                    } else {
                        console.log('error in surveys.save?', result);
                        return Promise.reject("That apparently didn't work");
                    }
                    if(!record.created && record.timestamp) record.created = filterDate(record.timestamp, 'YYYY-MM-DD');
                    commit('setItem', record);
                    if(state.list.new) commit('removeItem', 'new');
                    return record;
                })
                .catch((error) => {
                    console.log('error in surveys.save',error && error.response);
                    return error.response;
                })     
        },
        remove ({ commit, state, dispatch}, data ) {
            if(!data || !data._id  ) return Promise.reject("Invalid survey record.")

            return axios.post("/app_surveys/removeSurvey", data, axiosconfig)
                .then((result) => {
                    let record = result && result.data;
                    commit('removeItem', data);
                    return result;
                })
        },
        getResponses ({ commit, state }, data ) {
            data = data || {};
            return axios.post("/app_surveys/listResponses", data, axiosconfig)
                .then((response) => {
                    let result = response && response.data;
                    commit('setResponseList', result)
                    return result;
                })
                .catch((e) => {
                    console.log("error getting survey response list.")
                })            
        },
        saveResponse ({ commit, state }, data ) {
            if(!data.surveyId) return Promise.reject("You had one job...");

            console.log("Saving", data);
            let path = '/app_surveys/answer';
            return axios.post(path, data, axiosconfig)
                .then((result) => {
                    // console.log(result);
                    let record;
                    if(result && result.data && result.data.value){
                        record = result.data.value;
                    } else if(result && result.data){
                        record = result.data;
                    } else {
                        console.log('error in surveys.save?', result);
                        return Promise.reject("That apparently didn't work");
                    }
                    if(!record.created && record.timestamp) record.created = filterDate(record.timestamp, 'YYYY-MM-DD');

                    return record;
                })
                .catch((error) => {
                    console.log('error in surveys.save',error && error.response);
                    return error.response;
                })
        },
        getReponsesBySurevey ({commit, state}, data) {
            data = data || {};
            return axios.post("/app_surveys/listResponsesBySurvey", data, axiosconfig)
                .then((response) => {
                    let result = response && response.data;
                    // commit('setResponseList', result)
                    return result;
                })
                .catch((e) => {
                    console.log("error getting survey response list.")
                })  
        }
    },
}