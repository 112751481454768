import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

const axiosconfig = { headers: {} };

import {filterDate, filterDateTime} from './filter';

export default {
    namespaced: true,
    state: {
        list: false // list of files in currently listed directory.  This is not a global list.
    },
    mutations: {
        setFile (state, val) {
            // noop for now.
        },
        setList (state, val) {
            state.list = val;
        },
        removeFile (state, val) {
            let idx;
            for(let i in state.list){
                if(state.list[i].filename === val.filename){
                    Vue.delete(state.list, i);
                    return true;
                }
            }
        }
    },
    actions: {
        getList ({ commit, state }, data) {
            commit('setList', [{title: "Loading..."}]);
            return axios.post("/app_files/list", data, axiosconfig)
            .then((response) => {
                let result = response && response.data;
                
                if(result){
                    for(let k in result){
                        // result[k].created = result[k].timestamp ? filterDate(result[k].timestamp, 'YYYY-MM-DD') : "";
                        result[k].modified = result[k].mtime ? filterDateTime(result[k].mtime) : "";
                        result[k].created = result[k].ctime ? filterDateTime(result[k].ctime) : "";
                        if(result[k].filename && !result[k].title) result[k].title = result[k].filename;
                    }
                    console.log('getList', result);
                    commit('setList', result);
                }
                return result;
            })
            .catch((e) => {
                console.log('error getting files list', e)
            })
        },
        getFile ({ commit, state }, data) {
            if(!data.filename) return Promise.reject("You had one job...");

            return axios.post("/app_files/get", data, axiosconfig)
            .then((response) => {
                let result = response && response.data;
                console.log('getFile response', response);
                if(result){
                    if(typeof result === "string"){
                        result = { 
                            body: result ,
                            mime: response.headers && response.headers['content-type'],
                            filename: response.headers && response.headers['content-disposition'] && response.headers['content-disposition'].split('=')[1]
                        }
                    }
                    console.log('getFile', result);
                    commit('setFile', result);
                }
                return result;
            })
            .catch((e) => {
                console.log('error getting course list', e)
            })
        },
        removeFile ({ commit, state }, data){
            if(!data.filename) return Promise.reject("You had one job...");
            commit("removeFile", data);
            return axios.post("/app_files/remove", data, axiosconfig)
                .then((result) => {
                    console.log(result && result.data);
                });
        },
        save ({commit, state }, data) {
            console.log("store.files.save", data);
            if(!data.filename || !data.context) return Promise.reject("You had one job...  Missing params in file save");

            let path = '/app_files/save';
            return axios.post(path, data, axiosconfig)
                .then((result) => {
                    // console.log(result);
                    let record;
                    if(result && result.data && result.data.value){
                        record = result.data.value;
                    } else if(result && result.data){
                        record = result.data;
                    } else {
                        console.log('error in courses.save?', result);
                        return Promise.reject("That apparently didn't work");
                    }
                    if(!record.created && record.timestamp) record.created = filterDate(record.timestamp, 'YYYY-MM-DD');
                    commit('setCourse', record);
                    return record;
                })
                .catch((error) => {
                    console.log('error in courses.save',error, error.response);
                    return error.response;
                })     
        },
        upload ({commit, state}, data) {
            if(!data || !data.thefile || !data.context) return Promise.reject("Missing Required Parameters For Upload");
            let formData = new FormData();
            for(let k in data){
                formData.append(k, data[k]);
            }
            console.log("posting upload", formData);
            return axios.post('/app_files/upload', formData, axiosconfig)
                .then((result) => {
                    console.log(result);
                    return result && result.data;
                })
                .catch((e) => {
                    console.log("ERROR IN FILE UPLOAD", e);
                })
        }
    }
}