<template>
    <div class="bannercontainer" mobile-breakpoint="960" :style="($vuetify.breakpoint.width < 960) ? 'z-index:0' : 'z-index:20'">
        <v-container v-if="showbanner1 && $vuetify.breakpoint.width >= 960">
            <v-row id="banner1" class="banner1" v-if="showbanner1">
                <v-col cols="12" md="auto"><img :src="loc('_sitelogo')" id='sitelogo' to="/" height="80"/></v-col>
                <v-spacer></v-spacer>
                <v-col class="banner1item" cols="12" md="auto" v-for="(item) in navlist" :key="item.key + users.lang">
                    <v-btn text :href="item.url" dark :class="item.cssclass" >{{ item.loc }}</v-btn>
                </v-col>            
            </v-row>
        </v-container>
        <v-container v-if="showbanner1 && $vuetify.breakpoint.width < 960">
            <v-row id="banner1" class="banner1 pl-3" >
                <img :src="loc('_sitelogo')" id='sitelogo' @click="goto('/')" height="80"/>
                <v-spacer></v-spacer>
            </v-row>
        </v-container>        
        <v-app-bar 
            app flat 
            color="#00b8f5" 
            dark dense 
            class='appBanner' 
            style="position: relative; left: 0" 
            :clipped-left="$vuetify.breakpoint.width >= 960"
            :clipped-right="$vuetify.breakpoint.width >= 960"
        >
            <v-btn class="ma-2 dark bannermenubtn" color="primary" @click="handleNavClick" ><v-icon dark left >menu</v-icon>{{ loc('Menu') }}</v-btn>
            <v-spacer ></v-spacer>
            <v-toolbar-items class="pl-3 hidden-sm-and-down"> 
                    <span v-for="(language, key) in langlist" v-bind:key="key" class='selectlang' @click="switchLanguage(key)">
                        <span class="mr-3">|</span>{{language}}
                    </span> 
            </v-toolbar-items>
            <v-toolbar-items v-if="users && users.currentUser" class="pl-3 hidden-sm-and-down" style="padding-top:0;">
                <v-menu open-on-hover light offset-y >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" small v-bind="attrs" v-on="on" id="bannerperson"><v-icon>person</v-icon></v-btn>
                    </template>
                    <v-list>
                        <v-list-item v-if="users && users.currentUser">
                            {{ users.currentUser.firstname || "" }} {{ users.currentUser.lastname || "" }}
                        </v-list-item>                    
                        <v-list-item @click="gotoprofile">
                            {{ loc('Profile') }}
                        </v-list-item>
                        <v-list-item @click="gotoprofile('enrollment')">
                            {{ loc('My Classes') }}
                        </v-list-item>
                        <v-list-item @click="handleOptIn('prompt')" v-if="siteconfig.nevertrue">
                            {{  loc('Reset Cookie Opt In') }}
                        </v-list-item>
                        <v-list-item @click="logout">
                            {{ loc('Logout') }}
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-toolbar-items>
            <v-toolbar-items v-else-if="!isLoginPath" ><v-btn text small class="hidden-sm-and-down text-uppercase" to="/Login" style="margin-top:-6px;"><v-icon left>login</v-icon>{{ loc('login') }}</v-btn></v-toolbar-items>
        </v-app-bar>
        <v-row v-if="showCookieOptIn" class="grey darken-3 text-right">
            <v-col cols="6" md="8" lg="10" class="pt-6 white--text h6">
                <ContentBlockSimple  :record="{type:'snippet', _id:'cookieoptin'}"  />
            </v-col>
            <v-col cols="6" md="4" lg="2" class="pt-4">
                <v-btn class="mx-4 mb-2" @click="handleOptIn('optin')">{{ loc('Opt In') }}</v-btn>
                <v-btn class="mr-4 mb-2" @click="handleOptIn('optout')" style="display:none;">{{ loc('Opt Out') }}</v-btn>
                <v-btn class="mr-4 mb-2" @click="handleOptIn('manage')">{{ loc('Manage Options') }}</v-btn>
            </v-col>
        </v-row>
        <v-row v-else-if="showCookieOptIn && (!users || !users.currentUser)">
            <v-col cols="12" class="py-2 text-right">
                <a href="#" @click="handleOptIn('prompt')" class="text-caption white--text">{{ loc('Reset Cookie Opt In') }}</a>
            </v-col>
        </v-row>
</div>
</template>

<script>

import { mapState } from 'vuex';
import ContentBlockSimple from './Content_Block_Simple';

// I'm not sure there are any situations in which this gets used, because the
// default set is also in the default config, but at the moment it wasn't worth
// optimizing this out.
const fallbacklanguages = {
                en: "English",
                es: "Español",
                pt: "Português",
                fr: "Français",
                // bn: "বাংলা (Bangla)",
                ar: "العربية",
                de: "Deutsch"
            }

export default {
    name: "TopBar",
    metaInfo () {
        let meta = {};
        meta.script = [];
        if(this.users.cookieoptin && this.users.cookieoptin.setting === 'optin'){
            meta.script.push(
                { src: '/assets/thirdparty.js' }
            )
        }
        // console.log("Setting Meta in Topbar", meta, this.users.cookieoptin)
        return meta;
    },
    props: {
        togglenav: {
            type: Function
        }
    }, 
    data () {
        return {
            showbanner1: true
        }
    },
    components: { ContentBlockSimple },
    computed: {
        ...mapState(['users', 'localize', 'content', 'siteconfig', 'navitems']),
        isLoginPath () {
            console.log('current route', !!(this.$route.name === "login"), this.$route);
            return !!(this.$route.name === "login");
        },
        langlist  () {
            let languages = (this.siteconfig && this.siteconfig.publiclanguages) || fallbacklanguages;
            let list = {};
            for(let k in languages){ 
                if(k !== this.users.lang) list[k] = languages[k];
            }
            return list;
        },
        navlist () {
            let out = {...this.navitems.top};
            for(let k in out){
                out[k].lang = this.users.lang;
                out[k].loc = (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][out[k].title]) || out[k].title;
            }
            console.log("navlist", out)
            return out;
            // return this.navitems.top;
        },
        showCookieOptIn () {
            if(this.siteconfig && !this.siteconfig.showCookieOptIn) return;
            return this.users.cookieoptin && (this.users.cookieoptin === 'prompt' || (typeof this.users.cookieoptin === 'object' && this.users.cookieoptin.setting === 'prompt'));
        }
    },
    methods: {
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },        
        gotoprofile (panel) {
            this.$router.push('/users/profile' + ((panel && typeof panel === 'string') ? ('/' + panel) : ''))
        },
        logout () {
            this.$store.dispatch('users/logout')
                .then(() =>{
                    if(this.$route.path !== '/') this.$router.push('/');
                })
        },
        switchLanguage (lang) {
            if(lang === 'de'){
                this.$router.push('/German');
            } else {
                this.$store.dispatch("users/switchLanguage", lang )
                    .then((result) => {
                        console.log('Topbar.switchLanguage', result);
                        this.$vuetify.rtl = !!(lang === 'ar');
                    })
            }
        },
        handleNavClick () {
            this.togglenav()
        },
        handleOptIn (val) {
            if(val === 'manage'){
                this.$router.push(this.siteconfig.managecookiesettings || '/OptOutPreferences');
            } else {
                this.$store.dispatch('users/setCookieOptin', val)
            }
        }
    },
    mounted () {
        if(this.$route && this.$route.query && this.$route.query.setlanguage){
            this.$store.dispatch('users/setLanguage', this.$route.query.setlanguage)
        }
        // this.$store.dispatch('users/getCookieSnippet')
    }
}
</script>


