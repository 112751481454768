<template>
    <div v-if="isEditor && isEditing && contentRecord" class="contentblock">
        <SharedEditor 
            :record="contentRecord" 
            :handleSave="handleSave" 
            :handleClose="toggleEditing"
        />
    </div>
    <div v-else class="contentblock">
        <div  v-html="block || ''"></div>
        <div style="position:relative" v-if="!options.preview && isEditor">
            <v-btn color="secondary" fab absolute small bottom right @click="toggleEditing" >
                <v-icon>edit</v-icon>
            </v-btn>
        </div>        
    </div>
</template>

<script>
import { mapState } from 'vuex';
import SharedEditor from './Shared_Editor';

export default {
    name: "ContentBlockSimple",
    props: {
        record: {
            type: [Object, String]
        },
        options: {
            type: Object,
            default: () => { return {}}            
        }
    },
    data () {
        return {
            recordId: this.record && (this.record._id || this.record),
            contentRecord: false,
            isEditing: false,
            // isEditor: false,
            found: false,
            isLoading: false
        }
    },
    components: { SharedEditor },
    computed: {
        ...mapState(['users', 'content', 'localize']),
        isEditor: function(){
            return this.users && this.users.currentUser && this.users.currentUser.isEditor;
        },
        lang() {
            return this.users.lang;
        },        
        block: function(){
            return (this.contentRecord && (this.contentRecord.body || this.contentRecord.description)) || "";
        }
    },
    methods: {
        getContent: function(opts){
            console.log('ContentBlockSimple.getContent', this.recordId);
            let querydoc = {_id: this.recordId};
            if(this.record && this.record.type) querydoc.type = this.record.type;
            if(opts && (opts.remote || opts.refresh)) querydoc.remote = true;
            this.$store.dispatch('content/get', querydoc)
            .then((result) => {
                // console.log('ContentBlockSimple got a block', this.recordId, result);
                if(result){
                    this.contentRecord = this.contentRecord || {};
                    for(let k in result){
                        if(result[k] != this.contentRecord[k]){
                            // console.log("A change?", k, result[k], this.contentRecord[k]);
                            this.$set(this.contentRecord, k, result[k]);
                        }
                    }
                }
            })            
            .catch((e) => {
                console.log('error in ContentBlockSimple', e);
            })
        },
        toggleEditing () {
            console.log('toggleEditing', this.isEditing, this.contentRecord);
            this.isEditing = !this.isEditing;
            this.$emit("actionhandler", {action: 'toggleedit', editing: this.editing});
        },   
        handleEditorActions: function (action, doc){
            console.log('handleEditorActions', action);     
            switch(action){
                case 'close':
                    this.toggleEditing();
                    break;
                case 'save':
                    this.handleSave(doc)
                case 'toggleedit':
                    this.toggleEditing();
                    break;
            }
        },
        handleSave (change, next){
            console.log('entering handleSave', change);
            this.contentRecord = this.contentRecord || {};
            this.$set(this.contentRecord, 'body', change);

            let doc = Object.assign({}, this.contentRecord, {
                body: change,
                lang: this.users.lang
            });


            this.$store.dispatch('content/save', doc)
                .then((result) => {
                    console.log("ContentBlockSimple.handleSave result", result);
                    for(let k in result){
                        if(result[k] != this.contentRecord[k]){
                            // console.log("A change?", k, result[k], this.contentRecord[k]);
                            this.$set(this.contentRecord, k, result[k]);
                        }
                    }
                    if(next === 'close') this.toggleEditing();
                })
                .catch((err) => {
                    console.log("Error in handleSave", err);
                })
        },
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },

    },
    created (){
        console.log('ContentblockSimple.created', this.record)
        if(!this.options || !this.options.noload){
            this.getContent();
        } else {
            this.contentRecord = (typeof this.record === 'string') ? {_id: this.record} : this.record;
        }
    },
     watch: {
        'lang' (newLang, oldLang){
            console.log('ContentBlockSimple lang change', newLang, oldLang);
            this.getContent({refresh: true});
        }
    }
}

</script>