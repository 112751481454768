<template>
    <div>
        <div style="position:relative" v-if="!options.preview && users.currentUser && users.currentUser.isEditor && !options.embed && options.button !== 'lesson'">
            <v-btn color="secondary" fab absolute small bottom right @click="toggleSettings" title="Survey Block Settings" >
                <v-icon>settings</v-icon>
            </v-btn>
        </div>     
        <div v-if="users.currentUser && users.currentUser.isEditor && options.embed">
            <v-select :label="loc('Survey to Display')" :items="surveySelect" v-model="surveyId" @change="handleSurveySelect" />
        </div>
        <v-card v-else-if="!options.preview && users.currentUser && users.currentUser.isEditor && isEditing">
            <v-card-text>
                <div><h3>{{ loc('Survey properties') }}</h3></div>
                <div>
                    <v-select :label="loc('Survey to Display')" :items="surveySelect" v-model="surveyId" @change="handleSurveySelect" />
                </div>
                <div>{{ context }} : {{ contextid }}</div>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" class="mr-6" @click="handleSettingsSave" >Save</v-btn>
                <v-btn color="secondary" class="mr-6" :to="'/admin/surveys/'+surveyId" v-if="surveyId">Edit Survey</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="error" @click="$emit('actionhandler', {action: 'remove', record: record})">{{ loc("Remove From Page") }}</v-btn>
            </v-card-actions>
        </v-card>
        <v-row v-if="errormessage === 3" class="pt-20">
            <v-col class="error" id="surveyerror">{{  loc("There was an error submitting the form.") }}</v-col>
        </v-row>
        <v-row v-if="surveyDone && !options.embed" class="mt-5">
            <v-col cols="12"><h2>{{ surveyDoc.title }}</h2></v-col>
            <v-col cols="12"><div v-html="surveyDoc.description2"></div></v-col>
        </v-row>
        <v-row v-else-if="surveyDoc && !options.embed" class="mt-5">
            <v-col cols="12"><h2>{{ surveyDoc.title }}</h2></v-col>
            <v-col cols="12"><div v-html="surveyDoc.description"></div></v-col>
            <v-col>
                <v-row v-for="(question, qkey) in surveyDoc.questions" v-bind:key="qkey">
                    <v-col v-if="question.type === 'textfield'">
                        <div v-html="question.question" class="text-h6"></div>
                        <div>
                            <v-text-field v-model="response[qkey]" outlined></v-text-field>
                        </div>
                    </v-col>                    
                    <v-col v-if="question.type === 'text'">
                        <div v-html="question.question" class="text-h6"></div>
                        <div>
                            <v-textarea v-model="response[qkey]" outlined></v-textarea>
                        </div>
                    </v-col>
                    <v-col v-else-if="question.type === 'numeric'">
                        <div v-html="question.question" class="text-h6"></div>
                        <div>
                            <v-text-field style="width:80px" outlined v-model="response[qkey]" :rules="[ v => (!!isValidNumber(v)) || loc('Please enter a positive number.') ]"></v-text-field>
                        </div>
                    </v-col>
                    <v-col v-else-if="question.type === 'select'">
                        <div>
                            <div v-html="question.choicequestion || question.question" class="text-h6"></div>
                        </div>                        
                        <div>
                            <v-select :items="question.choices" :label="answer" v-model="response[qkey]"></v-select>
                        </div>
                    </v-col>
                    <v-col v-else-if="question.type === 'choice'">
                        <div>
                            <div v-html="question.choicequestion || question.question" class="text-h6"></div>
                        </div>
                        <div>
                            <v-radio-group v-model="response[qkey]">
                                <v-radio v-for="(answer, idx) in question.choices" :label="answer" v-bind:key="qkey+'-'+idx" :value="answer"></v-radio>
                            </v-radio-group>
                        </div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-btn color="primary" @click="handleSubmit">{{ loc('Submit Answers') }}</v-btn>
                </v-row>

            </v-col>
        </v-row>   
        
    </div>
</template>

<script>
/* global grecaptcha */
import { mapState } from 'vuex';
import { filterId } from '../lib/filter';
import _sortBy from 'lodash/sortBy';
import _isNaN from 'lodash/isNaN';
import config_system from '../config/system';

export default {
    name: "ContentBlockSurvey",
    props: {
        record: { default: null },
        context: { type: String, default: '' },
        contextid: { type: String, default: '' },
        sid: {type: String, default: 'Contact' },
        options: { type: Object, default: () => ({}) }
    },
    data () { 
        return {
            surveyId: false,
            response: {},
            isLoading: true,
            isEditing: false,
            surveyDone: false,
            recaptchaToken: '',
            errormessage: false
        }
    }, 
    components: {},
    computed: {
        ...mapState(['siteconfig', 'users', 'localize', 'content', 'surveys']),
        surveyDoc: function(){
            const regex = /[\r\n]+/;
            let doc = (this.surveyId && this.surveys && this.surveys.list && this.surveys.list[this.surveyId] && {...this.surveys.list[this.surveyId]}) || false;
            if(doc && doc.questions){
                for(let k in doc.questions){
                    if(doc.questions[k].type === 'choice' || doc.questions[k].type === 'select'){
                        doc.questions[k].choices = doc.questions[k].question.split(regex);
                        doc.questions[k].choicequestion = doc.questions[k].choices.shift()
                    }
                }
            }
            return doc;
        },
        surveySelect: function(){
            if(!this.surveys || !this.surveys.list ) return [];
            let list = [];
            for(let k in this.surveys.list){
                if(this.surveys.list[k].status === 'Active'){
                    list.push({value: k, text:this.surveys.list[k].title })
                }
            }
            list = _sortBy(list, ['title']);
            return list;
        }
    },
    methods: {
        getSurvey: function (){
            this.$store.dispatch('surveys/getList')
        },
        handleSubmit: function(){
            let record = {
                surveyId: this.surveyId,
                answers: this.response,
                lang: (this.surveyDoc && this.surveyDoc.lang) || this.users.lang || null,
                context: this.context,
                contextid: this.contextid

            }
            console.log("Submitting survey responses", record)

            return this.executeRecaptcha()
                .then((token) => {
                    // console.log('token', token);
                    record.grtoken = token;
                    this.$store.dispatch('surveys/saveResponse', record)
                        .then((result) => { 
                            console.log("Survey response result", result);
                            if(result && result.status && result.status !== 200){
                                this.errormessage = "Error";
                                window.scrollTo(0,100)
                            } else {
                                this.surveyDone = true;
                            }
                        });
            })
        },
        handleSurveySelect: function(val){
            console.log("handleSurveySelect", val, this.surveyId);
            this.$emit('actionhandler', val)
        },
        handleSettingsSave: function(){
            let context = this.context;
            
            let record;
            if(context === 'Page'){
                record = {...this.record};
                record.title = "Survey: " + this.surveyDoc.title;
                record.body = this.surveyId;
                this.$store.dispatch('content/save', record)
                    .then((result) => {
                        console.log("content block save", result)
                        this.isEditing = false;
                    })                
            }
        },
        toggleSettings: function(){
            console.log("toggleSettings", this.isEditing, !this.isEditing)
            this.isEditing = !this.isEditing;
        },
        init: function () {
            console.log(this.$route)
            let pathMatch =(this.$route.params && this.$route.params.pathMatch);
            if(this.sid){
                this.surveyId = this.sid;
            } else if(pathMatch){   
                    let path = pathMatch.split('/');
                    let id = filterId(path[0]);
                    if(id) this.surveyId = id;
            } else {
                this.isEditing = true;
            }
            this.getSurvey();

        },
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },        
        isValidNumber: function(v){
            console.log("validateNumber", _isNaN(v - 0))
            return !_isNaN(v - 0);
        },
        injectRecaptcha: function() {
            if(!config_system || !config_system.integrations || !config_system.integrations.recaptcha){ 
                console.log("Can't find the config for recaptcha", config_system)
                return;
            }
            let head = document.head;
            let script = document.createElement('script');
            script.id = 'recaptcha-v3-script';
            script.src = 'https://www.google.com/recaptcha/api.js?render='+config_system.integrations.recaptcha.key;
            head.appendChild(script);
        },
        removeRecaptcha: function(){
            let script = document.getElementById('recaptcha-v3-script');
            script.parentNode.removeChild(script);
        },
        executeRecaptcha() {
            if(!config_system.integrations.recaptcha){
                return Promise.resolve(true);
            }
            return new Promise((resolve, reject) => {
                if (typeof grecaptcha !== 'undefined') {
                    grecaptcha.ready(function () {
                        grecaptcha.execute(config_system.integrations.recaptcha.key, { action: 'submit' }).then(function (token) {
                            resolve(token);
                        });
                    });
                } else {
                    reject(new Error('reCAPTCHA has not been loaded yet.'))
                }
            })
        },
    },
    created (){
        this.init();
        this.injectRecaptcha()
    },
    mounted (){
        if(!this.surveys || !this.surveys.list) this.init()
    },
    destroyed (){
        this.removeRecaptcha();
    }
}
</script>