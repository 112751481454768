<template>
    <div class='contentblock mt-4' id="exam-wrapper">
        <div v-if="showing === 'posting'">
            <v-row v-html="loc('Processing...')"></v-row>
        </div>
        <div v-if="!questions && (showing === 'exam' || showing === 'answers')">
            <v-row v-html="loc('Loading...')"></v-row>
        </div>
        <div v-else-if="showing === 'exam'" class=" examwrapper elevation-2">
            <v-row class='examdescription' v-html="record.description" ></v-row>
            <v-row v-for="(item, idx) in questions" :key="idx" :id="'exam-'+idx" class="examquestionwrapper">
                <v-col class="examquestion" cols="12">
                    {{idx + 1}}. {{item.question}}
                </v-col>
                <v-col class="examanswerswrapper" cols="12">
                    <v-radio-group v-model="item.youranswer" class="examanswers">
                        <v-radio 
                            v-for="(answer, n) in item.answers" 
                            class='examanswer' 
                            :key="'exam-'+idx+'-'+n" 
                            :label="Alpha[n] + ':\xa0\xa0\xa0' + answer"
                            :value="Alpha[n]"
                        > 
                        </v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
            <v-row class="ma-4 pb-4">
                <v-btn color="primary" @click="submitexam" class="mr-3" >{{ loc("Submit Answers")}}</v-btn>
                <v-btn @click="showing = 'intro';scrollToQuiz();" >{{ loc("Cancel")}}</v-btn>
            </v-row>
            <v-row v-if="users && users.currentUser && users.currentUser.isAdmin" class="ma-4 pb-4">
                <v-btn :to="'/admin/lessonmaterials/' + lesson.course + '/' + record.lesson + '/' + record._id" class="mr-4">Exam Settings</v-btn>
                <v-btn @click="adminSetAnswers">Fill Answers</v-btn>
            </v-row>
        </div>
        <div v-else-if="showing === 'answers'" class=" examwrapper elevation-2">
            <v-row v-if="record.description2 && examstatus !== 'passed'" class='examdescription' v-html="record.description2" ></v-row>
            <v-row v-for="(item, idx) in questions" :key="idx" :id="'exam-'+idx" class="examquestionwrapper">
                <v-col class="examquestion" cols="12">
                    {{idx + 1}}. {{item.question}}
                </v-col>
                <v-col class="examanswerswrapper" cols="12">
                    <v-row>
                        <v-col cols="2"><h4>{{ loc('Your Answer') }}</h4></v-col> 
                        <v-col cols="10" :class="(lessonprogress.answers[idx] != lessonprogress.correctanswers[idx]) ? 'error--text' : '' ">{{ item.answers[Alpha.indexOf(lessonprogress.answers[idx])]}}</v-col>
                    </v-row>
                    <v-row v-if="lessonprogress.answers[idx] != lessonprogress.correctanswers[idx]">
                        <v-col cols="2"><h4 >{{ loc('Correct Answer') }}</h4></v-col> 
                        <v-col cols="10" class="font-weight-bold">{{ item.answers[Alpha.indexOf(lessonprogress.correctanswers[idx])]}}</v-col>
                    </v-row>
                    <v-row v-else class="success--text">
                        <v-col cols="2">{{ loc('Correct!') }}</v-col> 
                    </v-row>
                </v-col>
            </v-row>            
            <!-- <v-row>
                <pre>
                    {{progress}}
                </pre>
            </v-row> -->
            <v-row class="ma-4 pb-4">
                <v-btn @click="showing = 'intro'" >{{ loc("Close")}}</v-btn>
            </v-row>
        </div>        
        <div v-else-if="examstatus === 'passed'" class="pa-4 examwrapper elevation-2">
            <h3>{{ loc('You have successfully completed the exam') }}</h3>
            {{ loc("You answered")}} {{lessonprogress.score}} {{ loc('out of') }} {{lessonprogress.possible}} {{ loc('correct') }} 
            <v-row v-if="record.description1"  >
                <v-col class='examdescription' v-html="record.description1"></v-col>
            </v-row>
            <v-row class='pa-4'>
                <v-col>
                    <v-btn color="primary" @click="showing = 'answers'" class="mt-4 mr-4">{{ loc("Show Answers")}}</v-btn> <v-btn color="primary" @click="showing = 'exam'" class="mt-4">{{ loc("Retake the exam")}}</v-btn>
                </v-col>
            </v-row>
        </div>        
        <div v-else-if="examstatus === 'not passed'" class="pa-4 examwrapper elevation-2">
            <h3>{{ loc('You have completed the exam') }}</h3>
            {{ loc("You answered")}} {{lessonprogress.score}} {{ loc('out of') }} {{lessonprogress.possible}} {{ loc('correct') }} 
            <v-row v-if="record.description2" >
                <v-col class='examdescription' v-html="record.description2"></v-col>
            </v-row>
            <v-row class='pa-4'>
                <v-col>
                    <v-btn color="primary" @click="showing = 'answers'"  class="mt-4 mr-4">{{ loc("Show Answers")}}</v-btn> <v-btn color="primary" @click="showing = 'exam'" class="mt-4">{{ loc("Retake the exam")}}</v-btn>
                </v-col>
            </v-row>
        </div>        
        <div v-else class="ma-4 examintro pa-4">
            <v-row v-if="record.description" >
                <v-col  class='examdescription' v-html="record.description"></v-col>
            </v-row>
            <v-row class='pa-4'>
                <v-col>
                    <v-btn color="primary" @click="showing = 'exam'" >{{ loc("Start Exam")}}</v-btn>
                </v-col>
            </v-row>
            <!-- <div><pre>{{progress}}</pre></div> -->
        </div>
        <div style="position:relative" v-if="!options.preview && users.currentUser && users.currentUser.isEditor">
            <v-btn color="secondary" fab absolute small bottom right :to="'/admin/lessonmaterials/' + lesson.course + '/' + record.lesson + '/' + record._id" >
                <v-icon>settings</v-icon>
            </v-btn>
        </div>        
        <div style="display:none">
            {{showing}}
        </div>

    </div>
    
</template>

<script>
import { mapState } from 'vuex';
// import ContentBlockSimple from './Content_Block_Simple';
// import ContentBlock from './Content_Block';

export default {
    name: "LessonExamBlock",
    props: {
        lesson: { type: [Object, Boolean]},
        record: { type: [Object, Boolean]},
        classid: { type: String },
        progress: { type: [Object, Boolean]}
    }, 
    data() {
        return {
            questions: false,
            Alpha: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ', //['A', 'B', 'C','D','E','F', 'G', 'H'],
            showing: 'intro',
            examprogress: false,
        }
    },
    components: {  },
    computed: {
        ...mapState(['localize', 'enrollment', 'users', 'siteconfig']),
        examstatus: function() {
            let exampasspercent = (this.siteconfig && this.siteconfig.exampasspercent && this.siteconfig.exampasspercent - 0) || 80
            if(this.examprogress && this.examprogress.percent >= exampasspercent){
                return 'passed';
            }else if(this.progress && this.progress[this.lesson._id] && this.progress[this.lesson._id].percent ){
                return (this.progress[this.lesson._id].percent >= exampasspercent) ? 'passed' : 'not passed';
            } else {
               return false;
            }
        },
        lessonprogress: function(){ 
            return this.progress && this.progress[this.lesson._id] && this.progress[this.lesson._id];
        },        
        options: function() {
            return {}
        }
    },
    methods: {
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },    
        loadExam: function (opts){
            if(opts && opts.retries > 3){ console.log("Failed to get exam because course ID is missing."); return;}
            if(!this.lesson && !this.lesson.course){
                return setTimeout(this.loadExam({retries: (opts.retries && opts.retries++) || 1}), 300);
            }
            this.$store.dispatch("lessons/getExam", {course: this.lesson.course, isAdmin: (this.users && this.users.currentUser && this.users.currentUser.isAdmin)} )
                .then((result) => {
                    if(result && result.length) this.questions = result;
                })
        },            
        submitexam: function(){
            console.log("Submitting exam", this.questions);
            this.showing === "posting";
            let answers = [];
            for(let i in this.questions){
                answers.push(this.questions[i].youranswer);
            }
            let doc = {
                _id: this.record._id,
                user: this.users.currentUser._id,
                classid: this.classid,
                courseid: this.lesson.course,
                lessonid: this.lesson._id,
                answers: answers
            };
            this.$store.dispatch("enrollment/submitexam", doc)
                .then((result) => {
                    console.log("Lesson_Exam submitted", result);
                    this.examprogress = result;
                    this.showing = false;
                    setTimeout(this.scrollToQuiz, 100)
                })

        },
        adminSetAnswers: function(){
            if(this.questions){
                for(let i in this.questions){
                    console.log('setting', i, 'to', this.questions[i].iscorrect)
                    if(this.questions[i].iscorrect !== undefined) this.$set(this.questions[i], 'youranswer', this.Alpha[this.questions[i].iscorrect - 1])
                }
            } else {
                console.log("this.questions is false", this.questions)
            }


        },
        scrollToQuiz: function(){
            let el = document.getElementById('exam-wrapper');
            if(el) el.scrollIntoView({behavior: 'smooth'})
        }
    },
    created () {
        this.loadExam();
    } 
}
</script>