import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

const axiosconfig = { headers: {} };

import {filterDate, filterId} from './filter';
import _find from 'lodash/find';

export default {
    namespaced: true,
    state: {
        list: false
    },
    mutations: {
        removeClass (state, val) {
            if(typeof val === "string") val = {_id: val};
            if(state.list[val._id]) Vue.delete(state.list, val._id);
        },
        setClass (state, val) {
            if(val && val._id){
                if(!state.list) state.list = {};
                Vue.set(state.list, val._id, val);
            }
        },
        setList (state, val) {
            state.list = state.list || {};
            for(let k in val){
                Vue.set(state.list, val[k]._id, Object.assign({}, state.list[val[k]._id] || {}, val[k]) );
            }
        }
    },
    actions: {
        getList ({ commit, state }, data) {
            data = data || {};
            console.log("store_classes.getList", data);
            if(!data.nocache && state.list){
                return Promise.resolve(state.list);
            }
            if(data.nocache) delete data.nocache;
            return axios.post("/app_classes/list", data, axiosconfig)
                .then((response) => {
                    let result = response && response.data;
                    
                    if(result){
                        for(let k in result){
                            result[k].created = result[k].timestamp ? filterDate(result[k].timestamp, 'YYYY-MM-DD') : "";
                        }
                        console.log('classes.getList', result);
                        commit('setList', result);
                    }
                    return result;
                })
                .catch((e) => {
                    console.log('error getting class list', e)
                })
        },
        get ({ commit, state, dispatch }, data){
            if(!data) return Promise.reject("Missing parameters for getting class");
            if(typeof data === 'string') data = { _id: data };
            data._id = data._id || data.classid;
            console.log("getting class", data);

            if(data._id && state.list && state.list[data._id]) return Promise.resolve(state.list[data._id]);
            if(data._id === "selfpaced" && data.lang){
                let sp = _find(state.list, {selfpaced: true, lang: data.lang});
                if(sp) return Promise.resolve(sp);
            }

            return dispatch('getList', data)
                .then((result) => {
                    console.log('called getList from classes.get', result)
                    return (!result[data._id] && data._id === 'selfpaced' && data.lang) ? _find(result, {selfpaced: true, lang: data.lang}) : result[data._id];
                });
        },
        remove ({commit, state}, data) {
            if(!data || !data._id) return Promise.reject("Missing ID");

            console.log("Removing content", data._id);
            commit('removeClass', data);
            return axios.post("/app_classes/remove", data, axiosconfig)
                .then((response) => {
                    console.log('class removal posted', response);
                    let result = response && response.data;
                    return result;
                })
                .catch((e) => {
                    console.log('error removing class', e);
                })
        },
        save ({commit, state }, data) {
            if(!data._id) Promise.reject("You had one job...");
            console.log("Saving class in store action", data);
            let path = '/app_classes/save';
            return axios.post(path, data, axiosconfig)
                .then((result) => {
                    console.log('save response', result);
                    let record;
                    if(result && result.data && result.data.value){
                        record = result.data.value;
                    } else if(result && result.data){
                        record = result.data;
                    } else {
                        console.log('error in classes.save?', result);
                        return Promise.reject("That apparently didn't work");
                    }
                    if(!record.created && record.timestamp) record.created = filterDate(record.timestamp, 'YYYY-MM-DD');
                    commit('setClass', record);
                    if(state.list.new) commit('removeClass', 'new');
                    return record;
            })
                .catch((error) => {
                    console.log('error in classes.save',error, error.response);
                    return error.response;
                })     
        }
    }
}