import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

const axiosconfig = { headers: {} };

import {filterDate, filterDateTime, filterId} from './filter';
import _filter from 'lodash/filter';
import _size from 'lodash/size';

/**
 * state.list is an object.  
 *    list: { 
 *              [userid]: {
 *                            [classid]: {Class Object}
 *                        }
 *          }
 */

export default {
    namespaced: true,
    state: {
        list: false

    },
    mutations: {
        removeEnrollment (state, val) {
            if(!state.list || !val || !val.user || !val.classid) return;
            if(state.list[val.user] && state.list[val.user] && state.list[val.user][val.classid]) Vue.delete(state.list[val.user], val.classid);
        },
        setEnrollment (state, val) {
            if(val && val.user){
                if(!state.list) state.list = {};
                if(!state.list[val.user]) Vue.set(state.list, val.user, {});
                Vue.set(state.list[val.user], val.classid, val);
            }
        },
        setList (state, val) {
            state.list = state.list || {};
            for(let uid in val){
                // console.log("enrollment.setList", uid, val[uid])
                if(!state.list[uid]) Vue.set(state.list, [uid], {});
                for(let classid in val[uid]){
                    if(!state.list[uid][classid]) Vue.set(state.list[uid], classid, {});
                    for(let k in val[uid][classid]){
                        // console.log("enrollment.setList settings", uid, classid, k, val[uid][classid][k]);
                        Vue.set(state.list[uid][classid], k, val[uid][classid][k])
                    }
                }
                // Vue.set(state.list, uid, Object.assign({}, state.list[uid] || {}, val[uid]) );
            }
        },
        setProgress (state, val) {
            if(val && val.user && val.classid && val.progress){
                console.log('store setProgress', val);
                if(!state.list) Vue.set(state, 'list', {});
                if(!state.list[val.user]) Vue.set(state.list, val.user, {});
                if(!state.list[val.user][val.classid]) Vue.set(state.list[val.user], val.classid, {});
                if(!state.list[val.user][val.classid].progress) Vue.set(state.list[val.user][val.classid], 'progress', {});
                for(let lessonid in val.progress){ 
                    if(typeof val.progress[lessonid] === 'object'){
                        if(!state.list[val.user][val.classid].progress[lessonid]) Vue.set(state.list[val.user][val.classid].progress, lessonid, {});
                        for(let n in val.progress[lessonid]){
                            if(n === 'materials'){
                                state.list[val.user][val.classid].progress[lessonid].materials = state.list[val.user][val.classid].progress[lessonid].materials || {};
                                for(let m in val.progress[lessonid].materials){
                                    console.log("set m", m)
                                    Vue.set(state.list[val.user][val.classid].progress[lessonid].materials, m, val.progress[lessonid].materials[m] )
                                }
                            } else {
                                Vue.set(state.list[val.user][val.classid].progress[lessonid], n, val.progress[lessonid][n]);
                                console.log('set 2', n, val.progress[lessonid][n]);
                            }
                        }
                    } else {
                        console.log('set 1', lessonid, val.progress[lessonid])
                        Vue.set(state.list[val.user][val.classid].progress, lessonid, val.progress[lessonid]);
                    }
                }
            } else {
                console.log("cannot setProgress without missing params", val);
            }
        },
        setViewed (state, val) {
            console.log('enrollment.setViewed mutating', val);
            if(val && val.user && val.class && val.lesson){
                let timestamp = filterDateTime( new Date().toUTCString() );
                state.list = state.list || {};
                state.list[val.user] = state.list[val.user] || {};
                if(val.class !== 'none') state.list[val.user][val.class] = state.list[val.user][val.class] || {};
                state.list[val.user][val.class].progress = state.list[val.user][val.class].progress || {};
                state.list[val.user][val.class].progress[val.lesson] = state.list[val.user][val.class].progress[val.lesson] || {};
                Vue.set(state.list[val.user][val.class].progress[val.lesson], 'lastview', timestamp);
                if(val.materialid){
                    state.list[val.user][val.class].progress[val.lesson].materials = state.list[val.user][val.class].progress[val.lesson].materials || {};
                    Vue.set(state.list[val.user][val.class].progress[val.lesson].materials, val.materialid, timestamp); 
                }
                console.log('enrollment viewed state set', state.list[val.user][val.class].progress[val.lesson])
            } else {
                console.log("cannot setViewed without missing params", val);
            }
        },
        setUnviewed (state, val) {
            console.log("setUnviewed mutation", val);
            if( state.list[val.user] && 
                state.list[val.user][val.classid] && 
                state.list[val.user][val.classid].progress && 
                state.list[val.user][val.classid].progress[val.lessonid] &&  
                state.list[val.user][val.classid].progress[val.lessonid].materials  && 
                state.list[val.user][val.classid].progress[val.lessonid].materials[val.materialid]){ 
                    console.log('deleting ', val.materialid)
                    Vue.delete(state.list[val.user][val.classid].progress[val.lessonid].materials, val.materialid);
                }
        },
        // ,
        // replaceProgress (state, val) {
        //     if(val && val.user && val.classid && val.progress) return;
        //     if(!state.list) Vue.set(state, 'list', {});
        //     if(!state.list[val.user]) Vue.set(state.list, val.user, {});
        //     if(!state.list[val.user][val.classid]) Vue.set(state.list[val.user], val.classid, {});
        //     if(!state.list[val.user][val.classid].progress) Vue.set(state.list[val.user][val.classid], 'progress', {});
        //     for(let k in val.progress){
        //         Vue.set(state.list[val.user][val.classid].progress, k, val.progress[k]);
        //     }
        // }

    },
    actions: {
        getList ({ commit, state }, data) {
            console.log("store_enrollment.getList", data);
            return axios.post("/app_enrollment/list", data, axiosconfig)
                .then((response) => {
                    let result = response && response.data;
                    
                    if(result){
                        for(let k in result){
                            for(let c in result[k]){
                                if(result[k][c].ts && !result[k][c].regdate) filterDate(result[k][c].ts);
                            }
                        }
                        console.log('enrollment.getList', result);
                        commit('setList', result);
                    }
                    return result;
                })
                .catch((e) => {
                    console.log('error getting enrollment list', e)
                })
        },
        get ({ commit, state, dispatch }, data){
            if(!data) return Promise.reject("Missing parameters for getting enrollment");
            if(typeof data === 'string') data = { _id: data };
            data._id = data._id || data.enrollmentid;
            console.log("getting enrollment", data);

            if(data._id && state.list && state.list[data._id]) return Promise.resolve(state.list[data._id]);

            return dispatch('getList', data)
                .then((result) => {
                    console.log('called getList from enrollment.get', result)
                    return result[data._id];
                });
        },
        set({ commit, state }, data){
            if(!data) return Promise.reject("Missing data");
            // console.log("data in enrollment.set", data);
            if(data.classes){ 
                let temp = {}
                temp[data._id] = data.classes;
                data = temp;
            }
            // console.log("munged data in enrollment.set", data);
            commit('setList', data);
            return Promise.resolve(data);
        },
        remove ({commit, state}, data) {
            if(!data || !data._id) return Promise.reject("Missing ID");

            console.log("Removing enrollment record", data._id);
            commit('removeEnrollment', data);
            return axios.post("/app_enrollment/remove", data, axiosconfig)
                .then((response) => {
                    console.log('enrollment removal posted', response);
                    let result = response && response.data;
                    return result;
                })
                .catch((e) => {
                    console.log('error removing content', e);
                })
        },
        enroll ({commit, state}, data) {
            if(!data || !data.classid || !data.courseid) return Promise.reject("Missing ID for enroll");
            let path = '/app_enrollment/enroll/' + data.classid;
            return axios.post(path, data, axiosconfig)
                .then((response) => {
                    let result = response && response.data;
                    console.log('enrollment.enroll posted', result || response);
                    if(result && result.value) commit('setEnrollment', result.value);
                    return result;
                })
                .catch((e) => {
                    console.log('error enrolling', e);
                })

        },
        save ({commit, state }, data) {
            console.log("store_enrollment.save", data);
            if(!data._id) Promise.reject("You had one job...");

            commit('setEnrollment', data);

            let path = '/app_enrollment/save';
            return axios.post(path, data, axiosconfig)
                .then((result) => {
                    // console.log(result);
                    let record;
                    if(result && result.data && result.data.value){
                        record = result.data.value;
                    } else if(result && result.data){
                        record = result.data;
                    } else {
                        console.log('error in enrollment.save?', result);
                        return Promise.reject("That apparently didn't work");
                    }
                    if(!record.created && record.timestamp) record.created = filterDate(record.timestamp, 'YYYY-MM-DD');
                    commit('setEnrollment', record);
                    if(state.list.new) commit('removeEnrollment', 'new');
                    return record;
            })
                .catch((error) => {
                    console.log('error in enrollment.save',error, error.response);
                    return error.response;
                })     
        },
        submitquiz ({ commit, state }, data) {
            console.log("store_enrollment.submitquiz");

            if(!data.courseid || !data.classid || !data._id) return Promise.Reject({error: "Missing parameters"});

            let record = {
                key: data.courseid + "-" + data.classid // I don't think we use this?
            }

            return axios.post('/app_enrollment/quiz', data, axiosconfig)
                .then((response) => {
                    let result = response && response.data;
                    record.progress = result.progress || result;
                    record.user = data.user;
                    record.classid = data.classid;
                    console.log('quiz submitted', result, record)
                    commit('setProgress', record);
                    console.log('submitquiz results', result, record);
                })
                .catch((error) => {
                    console.log('error in enrollment.submitquiz',error, error.response);
                    return error.response;
                });                
        },
        submitexam ({ commit, state }, data){
            console.log("store_enrollment.submitexam");
            if(!data.courseid || !data.classid || !data._id) return Promise.Reject({error: "Missing parameters"});
            
            let record = {
                key: data.courseid + "-" + data.classid // I don't think we use this?
            }

            return axios.post('/app_enrollment/exam', data, axiosconfig)
                .then((response) => {
                    let result = response && response.data;
                    record.progress = {};
                    record.progress[data.lessonid] = result.progress || result;
                    record.user = data.user;
                    record.classid = data.classid;
                    if(record.materials) delete record.materials;
                    console.log('exam submitted', result, record)
                    commit('setProgress', record);                    
                    console.log('submitexam results', result);
                    return result;
                })
                .catch((error) => {
                    console.log('error in enrollment.submitexam',error, error.response);
                    return error.response;
                });

        },
        setViewed ({commit, state}, data) {
            // if(val && val.user && val.class && val.lesson)
            console.log("Setting viewed lesson", data);
            if(!data || !data.user || !data.class || !data.lesson) return Promise.reject({error: "Missing values"});
            if(data || data.cached){ // I think we always want to set viewed on the server.
                let viewed = {
                    classid: data.class,
                    lessonid: data.lesson
                };
                if(data.course) viewed.course = data.course;
                if(data.materialid) viewed.materialid = data.materialid;
                return axios.post('/app_enrollment/viewed', viewed)
                    .then((response) => {
                        console.log('setViewed response', response && response.data, response.status);
                        commit('setViewed', data);
                        return response;
                    })
                    .catch((e) => {
                        console.log('error marking lesson as viewed', data, e);
                    })
            } else {
                commit('setViewed', data);
                return true;
            }
        },
        setUnviewed ({commit, state}, data) {

            if(!data || !data.user || !data.class || !data.lesson) return Promise.reject({error: "Missing values"});
            let record = {
                classid: data.class,
                lessonid: data.lesson
            }
            if(data.course) record.course = data.course;
            if(data.materialid) record.materialid = data.materialid;
            commit('setUnviewed', record);
            return axios.post('/app_enrollment/unview', record)
                .then((response) => {
                    console.log('setUnviewed response', response && response.data, response.status);
                    let result = response && response.data;
                    if(result && result[record.classid] && result[record.classid].progress){
                        let update = {
                            user: data.user,
                            classid: record.classid,
                            progress: {}
                        };
                        update.progress[record.lessonid] = result[record.classid].progress[record.lessonid];
                        record.user = data.user;
                        commit('setUnviewed', record);
                        return update;
                    }
                })
                .catch((e) => {
                    console.log('error marking lesson as unviewed', data, e);
                })
        },
        getClassEnrollment ({commit, state}, data) {
            console.log("Getting Class Enrollment", data);
            if(!data || !data.classid) return Promise.reject({error: "Missing Class ID"});
            return axios.post('/app_enrollment/classEnrollment', data)
                .then((response) => {
                    let result = response && response.data;
                    return result;
                })
                .catch((error) => {
                    console.log('error in enrollment.getClassEnrollment',error, error.response);
                    return error.response;
                });
        }
    },

}