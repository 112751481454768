<template>
    <div>
        <v-row >
            <v-dialog v-model="linkdialog" persistent max-width="600px">
                <v-card>
                    <v-card-title>
                        <span class="text-h5">{{ loc('Link') }}</span>
                    </v-card-title>
                    <v-card-text>
                        <v-text-field label="URL" v-model="linkurl" placeholder="https://"></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="handleLink">{{ loc('Apply') }}</v-btn>
                        <v-btn @click="isClosing = true;linkdialog = null">{{ loc('Cancel') }}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>        
        <v-row >
            <v-dialog v-model="imagedialog" persistent max-width="600px">
                <v-card>
                    <v-card-title>
                        <span class="text-h5">{{ loc('Image') }}</span>
                    </v-card-title>
                    <v-card-text>
                        <v-text-field label="URL" v-model="imageurl" placeholder="https://"></v-text-field>
                        <v-text-field label="Style" v-model="imagestyle" ></v-text-field>
                        <div class='tip'>{{ loc("for example") }}: float:right; width: 300px;</div>
                        <v-row v-if="imageurl">
                            <v-img :src="imageurl" :style="imagestyle" ></v-img>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="handleImage">{{ loc('Apply') }}</v-btn>
                        <v-btn @click="isClosing = true;imagedialog = null">{{ loc('Cancel') }}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>  
        <v-card
            class="elevation-0 richeditorcontainer px-2 pb-4 mt-12"
            style="width: 100%; min-height: 266px; height: 96%"
            ref="richeditorcard"
        >
            <v-row class="pb-6 px-2 ">
                <v-col >
                    <v-toolbar dense class="pl-0">
                    
                        <v-btn-toggle multiple class="mr-4">
                            <v-btn :class="btnState('bold')" @click="handleBtnClick('bold')">
                                <v-icon>format_bold</v-icon>
                            </v-btn>
                            <v-btn :class="btnState('italic')" @click="handleBtnClick('italic')">
                                <v-icon>format_italic</v-icon>
                            </v-btn>
                            <v-btn :class="btnState('underline')" @click="handleBtnClick('underline')">
                                <v-icon >format_underlined</v-icon>
                            </v-btn>
                        </v-btn-toggle>

                        <v-btn-toggle multiple class="mr-4">
                            <v-btn v-model="imagedialog" @click="openImage" >
                                <v-icon>insert_photo</v-icon>
                            </v-btn>  
                            <v-btn v-model="linkdialog" @click="openLink"  >
                                <v-icon>link</v-icon>
                            </v-btn>                     
                        </v-btn-toggle>
                    </v-toolbar>    
                </v-col>
                <v-col>
                    <v-toolbar dense class="pl-0">
                        <v-btn-toggle class="mr-4" >
                            <v-btn :class="btnState('align', 'left')" @click="handleBtnClick('align', 'left')">
                                <v-icon >format_align_left</v-icon>
                            </v-btn>

                            <v-btn :class="btnState('align', 'center')" @click="handleBtnClick('align', 'center')">
                                <v-icon>format_align_center</v-icon>
                            </v-btn>

                            <v-btn :class="btnState('align', 'right')" @click="handleBtnClick('align', 'right')">
                                <v-icon>format_align_right</v-icon>
                            </v-btn>
                        </v-btn-toggle>

                        <v-btn-toggle  class="mr-4">
                            <v-btn :class="btnState('ul')" @click="handleBtnClick('ul')">
                                <v-icon>format_list_bulleted</v-icon>
                            </v-btn>
                            <v-btn :class="btnState('ol')" @click="handleBtnClick('ol')">
                                <v-icon>format_list_numbered</v-icon>
                            </v-btn>
                            <v-btn :class="btnState('blockquote')" @click="handleBtnClick('blockquote')">
                                <v-icon>format_quote</v-icon>
                            </v-btn>
                        </v-btn-toggle>   
                    </v-toolbar>    
                </v-col>
                <v-col>
                    <v-toolbar dense class="pl-0">                                
                        <v-btn-toggle class="mr-4">
                            <v-btn :class="btnState('heading', { level: 1 })" @click="handleBtnClick('heading', { level: 1 })">H1</v-btn>
                            <v-btn :class="btnState('heading', { level: 2 })" @click="handleBtnClick('heading', { level: 2 })">H2</v-btn>
                            <v-btn :class="btnState('heading', { level: 3 })" @click="handleBtnClick('heading', { level: 3 })">H3</v-btn>
                            <v-btn :class="btnState('paragraph')" @click="handleBtnClick('paragraph')">{{ loc('Normal') }}</v-btn>
                        </v-btn-toggle>
                        
                            <v-overflow-btn
                                :items="dropdown_size"
                                :label="loc('Font Size')"
                                @change="handleSize"
                                single-line
                                v-model="textSize"
                                class="pl-0 pt-6"
                                prepend-icon="format_size"
                            > </v-overflow-btn>  
                        
                    </v-toolbar>
                </v-col> 
                <v-spacer></v-spacer>                 
            </v-row>

            <editor-content :editor="editor" />
        </v-card>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import TextAlign from "@tiptap/extension-text-align";
import Image from '@tiptap/extension-image';
import Link from '@tiptap/extension-link';
import Paragraph from '@tiptap/extension-paragraph';
// import {TextClass} from '../lib/tiptap_TextClass';
import { formatCapitalizeFirst } from '../lib/filter';

/*
    @TODO 
        - if you move a link into "focus" using the keyboard do we want the dialog to open?
        - the link dialog should probably have the link text editable, which makes the first point less important
        - in some cases opening the dialogs takes a double click, depending on what was clicked last.
*/

const CustomImage = Image.extend({
    addGlobalAttributes() {
        return [{
            types: [
                'heading',
                'paragraph',
                'textAlign',
                'textStyle'
            ],
            attributes: {
                class: {
                    default: '',
                    renderHTML: attributes => {
                        // console.log('global renderHTML', this, attributes);
                        return { 'class': attributes.class  };
                    },
                    parseHTML: element => { 
                        // console.log('global parseHTML', this, element);
                        return element.getAttribute('class')
                        // return element.class && element.class.textClass && null 
                    },
                }
            }    
        }]
    },
    addAttributes() {
        return {
            src: {
              default: null,
            },
            alt: {
              default: null,
            },
            title: {
              default: null,
            },
            height: {
                default: null,
            },
            width: {
                default: null,
            },
            class: {
                default: 'contentimage'
            },   
            style: {
                default: null
            }         
          }
    }
})

const CustomParagraph = Paragraph.extend({
    addAttribures(){
        return {'class': { default: '' }}
    },
    addCommands(){
        return {
            setParagraphClass: (str) => ({state, commands, chain}) => {
                // console.log("Setting paragraph class", str)
                return commands.updateAttributes('paragraph', {class: str});
            }
        }
    }
})

const sizeClasses = {
    "Small": "ql-size-small",
    "Normal": '',
    "Large": "ql-size-large",
    "Huge": "ql-size-huge"
}

export default {
    components: {
        EditorContent,
    },

    props: {
        value: {
            type: String,
            default: "",
        },
        handleChange: {
            type: Function,
            default: (v) => ({}),
        },
    },

    data() {
        return {
            editor: null,
            hasfocus: false,
            imagedialog: false,
            imagealt: "",
            imageclass: "contentimage",
            imageheight: "",
            imagestyle: "",
            imagetitle: "",
            imageurl: "",
            imagewidth: "",
            isClosing: false,
            linkdialog: false,
            linkurl: '',
            textSize: 'Normal',
            paragraphClass: false

        };
    },
    computed: {
        ...mapState(['localize', 'users']),
        dropdown_size: function(){
            return [
                { value: "Small",  text: this.loc("Small") },
                { value: "Normal", text: this.loc("Normal") },
                { value: "Large",  text: this.loc("Large") },
                { value: "Huge",   text: this.loc("Huge") },
            ];
        }
    },
    methods: {
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },
        handleBtnClick: function (key, params){
            // console.log("Editor handleBtnClick", key, params);

            switch(key){
                case 'bold':
                    this.editor.chain().focus().toggleBold().run();
                    break;
                case 'italic':
                    this.editor.chain().focus().toggleItalic().run();
                    break;
                case 'underline':
                    this.editor.chain().focus().toggleUnderline().run();
                    break;
                case 'align':
                    this.editor.chain().focus().setTextAlign(params).run();
                    break;
                case 'ul':
                    this.editor.chain().focus().toggleBulletList().run();
                    break;
                case 'ol':
                    this.editor.chain().focus().toggleOrderedList().run();
                    break;    
                case 'blockquote': 
                    this.editor.chain().focus().setBlockquote().run();
                    break;
                case 'heading':
                    this.editor.chain().focus().setHeading(params).run();
                    break;
                case 'paragraph':
                    this.editor.chain().focus().setParagraph().run();
            }

        },
        btnState: function(key, opts){
             return (this.editor && this.editor.isActive(key, opts)) ? 'btn_on' : 'btn_off';
        },
        btnStateSize: function(key, opts){
            // console.log(this.editor.chain().focus());
        },
        openImage: function(){
            // console.log('Editor',this.editor);

            if(!document.hasFocus() || !this.hasfocus) return;

            let attrs = this.editor.getAttributes('image');

            this.imageurl = attrs.src || "";
            this.imagestyle = attrs.style || "";
            this.imagealt = attrs.alt || "";
            this.imagetitle = attrs.title || "";

            // this.imagewidth = attrs.width || ( styles.width && (styles.width.indexOf('px') > 0) && styles.width.replace('px', '') ) || "";
            // this.imageheight = attrs.height || ( styles.height && (styles.height.indexOf('px') > 0) && styles.height.replace('px', '') ) || "";
            // this.imageclass = attrs.class || "contentimage"

            // console.log("Image attrs", attrs);
            this.imagedialog = true;
            this.linkdialog = false;
        },
        handleImage: function(){
            // console.log("Image Submit", this.imageurl, this.imagestyle, this.imagewidth, this.imageclass)
            this.imagedialog = null;
            if(this.imageurl){
                let attrs = { src: this.imageurl };
                if(this.imagestyle) attrs.style = this.imagestyle;
                this.editor.chain().focus().setImage(attrs).run();
            }
        },
        openLink: function(){
            // console.log('Editor',this.editor);
            if(!document.hasFocus() || !this.hasfocus) return;

            // this.editor.focus();
            let attrs = this.editor.getAttributes('link');
            // console.log("Link attrs", attrs);
            this.linkurl = attrs.href || "";

            this.imagedialog = false;
            this.linkdialog = true;

        },
        handleLink: function(){
            // console.log("Link Submit", this.linkurl);
            if(!this.linkurl){
                this.editor.commands.unsetLink();
            } else {
                // this.editor.commands.setLink({ href:  this.linkurl });
                this.editor.chain().focus().extendMarkRange('link').setLink({ href: this.linkurl }).run();
            }
            this.isClosing = true;
            this.linkurl = '';
            this.linkdialog = null;
        },
        handleSize: function(evt, params){
            // console.log("handleSize", evt, this.paragraphClass);
            // this.textSize = evt;
            let sizeClass = sizeClasses[this.textSize] || '';
            // this.editor.chain().focus().setClass(sizeClass);

            let newClass = this.paragraphClass || '';
            newClass = ( newClass && newClass.match(/ql-size-(small|large|huge)/) ) ? 
                newClass.replace(/ql-size-(small|large|huge)/, sizeClass) : 
                sizeClass;

            this.editor.chain().focus().setParagraphClass(newClass).run();
        },
        setFocus: function(evt){
            let type = evt.type;
            // console.log(evt.type);
            this.hasfocus = !!(type === 'focusin');
        }
    },

    watch: {
        value: {
            handler: function (value) {
                if(this.editor && this.editor.getHTML){

                    // JSON.  This needs some optimizing, but JSON can be handled with something like
                    // (JSON.stringify(this.editor.getJSON()) === JSON.stringify(value))

                    // HTML
                    if (this.editor.getHTML() !== value) this.editor.commands.setContent(value, false);

                }
            },
            immediate: false
        },
    },

    mounted() {
        this.editor = new Editor({
            content: this.value,
            extensions: [
                StarterKit.configure({
                    paragraph: false
                }), 
                CustomParagraph,
                TextAlign.configure({
                    types: ['heading', 'paragraph'],
                }),
                CustomImage.configure({
                    inline: true
                }),
                Link.configure({
                    openOnClick: false,
                }),
                // TextClass
            ],
            onUpdate: () => {
                let strHTML = this.editor.getHTML();
                // console.log("tiptap.onUpdate", strHTML);
                this.handleChange(strHTML);
                // HTML
                // this.$emit('input', this.editor.getHTML())

                // JSON
                // this.$emit('input', this.editor.getJSON())
            },
            onFocus: ({editor, event}) => { 
                // console.log("Focus!", document.hasFocus(), editor, event); 
                // this.isClosing = false; 
                this.hasfocus = true;
            },
            onBlur: ({editor}) => { 
                // console.log("Blurry!", document.hasFocus(), editor); 
                this.hasfocus = false;
            },
            // onSelectionUpdate: ({ editor, event }) => {
            //     console.log("selectionUpdate", editor, event);
            // },

            onTransaction: ({editor, transaction}) => { 
                // console.log("Transaction!", document.hasFocus(),  editor, editor.getAttributes('paragraph')); 
                if(!this.hasfocus) return;

                let p = editor.getAttributes('paragraph');
                this.paragraphClass = p.class;
                let pmatch = p.class && p.class.match(/ql-size-(small|large|huge)/)
                if(pmatch && pmatch.length){
                    let c = formatCapitalizeFirst(pmatch[1]);
                    this.textSize = c;
                } else {
                    this.textSize = "Normal";
                }

                // let a = editor.
                let t = editor.getAttributes('image');
                if(t.src !== undefined){ 
                    if(this.isClosing){
                        this.isClosing = false;
                    } else {
                        // console.log("An image?", t)
                        this.openImage(); 
                    }
                } else {
                    t = editor.getAttributes('link');
                    if(t.href !== undefined){
                        if(this.isClosing){
                            this.isClosing = false;
                        } else {
                            // console.log("A link?", t);
                            this.openLink();
                        }
                    }
                }

            },
        });
        // console.log("Mounted", this.editor);
        this.editor.on('focus', ()=>{ this.hasfocus = true; })
        this.editor.on('blur', ()=>{ this.hasfocus = false; })
        // console.log(this.$refs.richeditorcard)
        this.$refs.richeditorcard.$el.addEventListener('focusin', this.setFocus);
        this.$refs.richeditorcard.$el.addEventListener('focusout', this.setFocus);
    },

    beforeDestroy() {
        this.editor.destroy();
        // this.$refs.richeditorcard.removeEventListener('focusin', this.setFocus)
        // this.$refs.richeditorcard.removeEventListener('focusout', this.setFocus);
    },
};
</script>

<style scoped>
.btn_off:before {
    background-color: currentColor;
    border-radius: inherit;
    bottom: 0;
    color: inherit;
    content: "";
    left: 0;
    opacity: 0 !important;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity 0.2s cubic-bezier(0.4, 0, 0.6, 1);
}
.btn_on:before {
    background-color: currentColor;
    border-radius: inherit;
    bottom: 0;
    color: inherit;
    content: "";
    left: 0;
    opacity: .18 !important;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity 0.2s cubic-bezier(0.4, 0, 0.6, 1);
}
</style>

<style>
.ProseMirror {
    padding: 4px;
    min-height: 240px;
}
.ProseMirror-focused {
    outline-color: #ddd;
    outline-width: 1px;
}
.richeditorcontainer .v-toolbar__content { 
    padding: 0 !important; 
}

</style>

<style lang="scss">
/* Basic editor styles */
.ProseMirror {
  img {
    max-width: 100%;
    height: auto;


    &.ProseMirror-selectednode {
      outline: 3px solid #68CEF8;
    }
  }
}
</style>