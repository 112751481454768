<template>
    <v-card class="mx-auto mb-12 classmeta" v-if="classRecord && classRecord._id">
        <v-card-title>
            <span class="font-weight-bold">{{ loc('Class') }}: {{ classRecord.name }}</span>
        </v-card-title>

        <v-card-text v-if="users.currentUser && classRecord.instructor === users.currentUser._id">
            <span style="font-weight: bold">{{ loc("You are the instructor for this class.") }}</span>
        </v-card-text>

        <v-card-actions>
                <v-row>
                    <v-col v-if="users.currentUser && classRecord.instructor === users.currentUser._id" >
                        <v-btn color="primary" :to="'/classes/enrollment/' + classRecord._id"  >
                            {{ loc("View Class Members") }}
                        </v-btn>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col align-self="end" class="col-md-2" v-if="enrolledList && enrolledList.length">
                        <v-select :items="classSelect()" v-model="classid" :label="loc('Switch Classes')" style="width: 16em;" @change="setRoute({classid: classid})"></v-select>
                    </v-col>
                </v-row>                
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: "ClassInstructor",
    props: {
        // courseRecord: {
        //     type: Object,
        //     default: () => ({}),
        // },
        classRecord: {
            type: [Object, Boolean],
            default: () => ({}),
        },
        enrolledList: {
            type: Array,
            default: () => ([])
        }
    },
    data() {
        return {
            classid: this.classRecord && this.classRecord._id
        };
    },
    computed: {
        ...mapState(["users", "localize"]),
    },
    methods: {
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },   
        classSelect: function (list) {
            list = list || this.enrolledList;
            let out = [];
            for(let i in list){
                out.push({
                    text: list[i].name || (this.classes && this.classes.list && this.classes.list[list[i].classid] && this.classes.list[list[i].classid].name) || list[i].classid , 
                    value: list[i].classid || list[i]._id
                })
            }
            // console.log("classSelect", list, out);
            return out;
        },
        setRoute: function(opts){
            if(!this.classRecord) return;
            opts = opts || {};
            const sendroute = (newpath) => {
                if(newpath !== this.$route.path) this.$router.push(newpath);
            }

            opts.courseid = (opts.courseid !== undefined )   ? opts.courseid :this.classRecord.course;
            opts.classid = (opts.classid !== undefined )    ? opts.classid   : this.classRecord._id;
            opts.lessonid = (opts.lessonid !== undefined )   ? opts.lessonid : this.$route.params.lessonid;
            opts.materialid = (opts.materialid !== undefined ) ? opts.materialid : this.$route.params.materialid;

            if(!opts.courseid) return sendroute('/Courses');
            if(!opts.lessonid) return sendroute('/Course/' + opts.courseid + ((opts.classid) ? ('/' + opts.classid) : ''));
            let path = '/lessons/' + opts.courseid + '/' + opts.lessonid;
            if(!opts.materialid && !opts.classid) return sendroute(path)
            if(!opts.materialid) return sendroute(path + '/' + opts.classid);
            return sendroute(path + '/' + opts.classid + '/' + opts.materialid);
        }
    },
};
</script>