import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import store from './store';

const axiosconfig = { headers: {} };
const basepath = "/app_integrations/vcrm/";

export default {
	namespaced: true,
	state: {
		list: {},
		individual: false
	},
	mutations: {
		setIndividual(state, val) {
			state.individual = val;
		}
	},
	actions: {
		getIndividual({ commit, state }, data ) {
			console.log("store_integration_vcrm.getIndividual", data);
			commit('setIndividual', null);

            if(typeof data === "object") data = data.email;
            if(!data || (typeof data !== "number" && data.indexOf("@") < 0)){
                // commit('setAdminMessage', {message: "Invalid contact identifier"});
                // setTimeout(function(){ commit('setAdminMessage', false); }, 3000);
                return Promise.reject("Invalid contact identifier");
            }

            // commit('setAdminMessage', {message: "Fetching VCRM Contact Individual", style: "info"});

            let path = (typeof data === "number") ? "getIndividual" : ("getContactByEmailQuery" );
            let query = (typeof data === "number") ? {id: data} : {email: data};
            return axios.post(basepath + path, query, axiosconfig)
                .then((response) => {
                    if(response && response.data){
                        commit('setIndividual', response.data)
                        // commit('setAdminMessage', false);
                    }
                })
                .catch((error) => {
                    console.log("ERROR IN getContactByEmail", error);
                    // commit('setAdminMessage', {message: "Error Gettng Contact", style: "error"});
                })			
		},
		populateIds({ commit, state }, data ) {
			console.log("store_integration_vcrm.populateIds", data);

		},
		postBatch({ commit, state }, data ) {
			console.log("store_integration_vcrm.postBatch", data);
            return axios.post(basepath + 'postBatch')
                .then((response) => {
                    return response && response.data;
                })
                .catch((e) => {
                    console.log("Error in VCRM Integration postBatch", e);
                })
		},
		runSyncByEmail({ commit, state }, data ) {
			console.log("store_integration_vcrm.runSyncByEmail", data);

            return axios.post(basepath + 'syncRecentUsers')
                .then((response) => {
                    return response && response.data;
                })
                .catch((e) => {
                    console.log("Error in VCRM Integration syncRecentContacts", e);
                })

		},
		syncByEmail({ commit, state }, data ) {
			console.log("store_integration_vcrm.syncByEmail", data);
            return axios.post(basepath + 'syncUser', data)
                .then((response) => {
                    return response && response.data;
                })
                .catch((e) => {
                    console.log("Error in VCRM Integration syncUser", e);
                })
		},
	}
}