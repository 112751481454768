<template>
    <div class='lessoncardparent'>
        <v-card class="mx-auto mb-12 lessoncard"  >
            <v-card-title v-if="options.isEnrolled" >
                <template v-if="options.linktitle || !item.type"><router-link :to="lessonlink">{{item.title}}</router-link> </template>
                <template v-else><router-link :to="lessonlink">{{ loc(item.type) }} {{item.norder}}</router-link> {{'\xa0'}} - {{item.title}} </template>
            </v-card-title>
            <v-card-title v-else >
                <span v-if="section === 'Lessons'">{{ loc(item.type) }} {{item.norder}} - </span> 
                {{item.title}} 
            </v-card-title>
            <v-card-text>
                <v-row class='lessonstate'></v-row>
                <v-row class='lessondescription'><v-col cols="12" v-html="item.description"></v-col></v-row>
                <v-row class='actionbar' v-if="options.isEditor">
                    <v-btn color="secondary" fab absolute small bottom right @click="toggleEditing" >
                        <v-icon>edit</v-icon>
                    </v-btn>
                </v-row>
                
                <!-- <v-row>                    
                    <pre>{{options}}</pre>
                </v-row> -->
            </v-card-text>
        </v-card>
    </div>

</template>

<script>
import { mapState } from 'vuex';

export default {
    name: "LessonListingBlock",
    props: {
        item: {
            type: Object,
            default: () => ({})
        },
        options: {
            type: Object,
            default: () => ({})
        },
        section: {
            type: String,
            default: "Lessons"
        }
    },
    computed: {
        ...mapState(['siteconfig', 'users', 'localize']),
        // ...mapState(['users']),
        // isEnrolled: function(){
        //     this.users && this.users.currentUser && this.users.currentUser.classes
        // }
        lessonlink: function(){
            let url = '/lessons/' + this.item.course + '/' + this.item._id;
            if(this.options.classId) url += '/' + this.options.classId;
            return url;
        }
},
    methods: {
        toggleEditing: function(){
            this.$router.push({path:"/admin/lessons/" + this.item.course + "/" + this.item._id, query:{after: 'course'}});
        },
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },   
    }
    
}
</script>