<template>
    
        <v-col>
            <v-card :loading="loading" class="mx-auto my-12 coursecard">
<!--                 <v-img
                    height="250"
                    :src="item.smallbanner || defaultsmallbanner || '/images/monday-church-small.jpg'"
                ></v-img> -->
                <SharedMediaPlayer 
                    :record="item.smallbanner || defaultsmallbanner || '/images/monday-church-small.jpg'" 
                    
                />
                <v-card-text class='coursestate'>
                    <CourseProgress :courseRecord="item" />
                </v-card-text>
                <v-card-title>{{item.title}}</v-card-title>
                <v-card-text>
                    <v-row align="center" class="mx-0" >            
                        <div class='coursedescription' v-html="item.description"></div>
                    </v-row>
                    <v-row class='courseblockcta py-6' >
                        <div style="width:100%" class='text-center' v-if="btnView === 'multiple'">
                            <v-btn color="primary" :to="'/Course/' + item._id">{{ loc('Multiple') }}</v-btn>
                        </div>
                        <div style="width:100%" class='text-center' v-else-if="btnView === 'started'">
                            <v-btn color="primary" :to="'/lessons/' + item._id + '/next'">{{ loc('Continue') }}</v-btn>
                        </div>                        
                        <div style="width:100%" class='text-center' v-else-if="btnView === 'enrolled'">
                            <v-btn color="primary" :to="'/Course/' + item._id">{{ loc('Get Started') }}</v-btn>
                        </div>
                        <div style="width:100%" class='text-center' v-else>
                            <v-btn color="primary" :to="'/Course/' + item._id">{{ loc('View Course') }}</v-btn>
                        </div>

                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    
</template>

<script>
import { mapState } from 'vuex';
import CourseProgress from './Course_Progress';
import SharedMediaPlayer from './Shared_Media_Player';
import _filter from 'lodash/filter';
// import siteconfig from '../config/site.js';

export default {
    name: "CourseListingBlock",
    props: {
        item: {
            type: Object,
            default: () => ({})
        }
    },
    data () {
        return {
            loading: false,
            defaultsmallbanner: this.siteconfig && this.siteconfig.defaultcoursesmall
        }
    },
    components: { CourseProgress, SharedMediaPlayer },
    computed: {
        ...mapState(['siteconfig', 'users', 'localize', 'courses', 'enrollment', 'lessons']),
        // enrollment: function() {
        //     return false;
        // }, 
        btnView: function() {
            // if(!this.item || !this.item) 
            if(!this.enrollment || !this.enrollment.list){ return []; }
            let userid = this.users.currentUser._id;
            let list = _filter(this.enrollment.list[userid], {course: this.item._id});
            if(!list || !list.length) return this.item;
            if(list.length && list.length > 1) return 'multiple';
            if(list && list[0] && list[0].progress) return 'started';
            if(list && list[0] && list[0].regdate) return 'enrolled';
            return 'notenrolled';
        }
    },
    methods: {
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        }
    }
}
</script>