<template>
    <div class="lessonlisting" v-if="this.lessons.list">
        <div v-for="(section, idx) in lessonsList" v-bind:key="idx" class="justify-space-between">
            <h2 v-if="idx !== '_default'" class="mt-12 mb-10 lessonsectionstitle">{{ loc(idx) }}</h2>
            <LessonsListingBlock 
                v-for="item in section" 
                :item="item" 
                :id="item._id" 
                :key="item._id+item.modified" 
                :section="idx"
                :options="{...{'linktitle': (idx !== 'Lessons')}, ...options}" 
            />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import _filter from 'lodash/filter';
import _sortBy from 'lodash/sortBy';
import _trim from 'lodash/trim'
import LessonsListingBlock from './Lessons_Listing_Block.vue'

export default {
    name: "LessonsListing",
    props: {
        course: {
            type: Object,
            default: () => ({})
        },
        options: {
            type: Object,
            default: () => ({})
        }
    },
    data () { return {

    }},
    components: { LessonsListingBlock },
    computed: {
        ...mapState(['users', 'courses', 'content', 'lessons', 'localize']),
        lessonsList(){
            if(!this.lessons.list) return [];

            let list = _filter(this.lessons.list, {course: this.course._id});
            list = _sortBy(list, ['sortorder', 'order']);
            console.log("Prepping lessonsList", list, this.course);
            let out = {
                Lessons: _filter(list, (o) => { return !o.sorder; })
            };

            if(this.course && this.course.sections){
                let sections =  this.course.sections.split(',');
                
                for(let i in sections){
                    let section = _trim(sections[i]);
                    console.log("prepping", section);
                    out[section] = _filter(list, {sorder: (section === 'Lessons') ? 'Lesson' : section});
                }
            }
            return out;
        },
        // courseLessonSections(){
        //     let sections = [];
        //     if(this.course && this.course.sections){
        //         sections =  this.course.sections.split(',');
        //         for(let i in sections){
        //             let sections[i] = _trim(sections[i]);
        //         }
        //     }
        //     sections.unshift('_default');
        // }

    },
    methods: {
        loadLessons: function(course){
            this.$store.dispatch('lessons/getList', {course: course})
                .then((result) => {
                    this.isLoading = false;
                    this.lessonrecords = result;
                    console.log("loaded lessons", result );
                })
        },
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        }
    },
    created () {
        let lessonslist = this.lessons.list;
        console.log("Lessons at list created", lessonslist);
        if( !Object.values(lessonslist).length ){
            this.loadLessons();
        }
    }
    
}
</script>