<template>
    <v-app id="coramdeo" :class="(users && users.lang === 'ar') ? 'rtl' : ''">
        <app-navigation :togglenav="showdrawer" :toggleaction="togglenav"/>
        <app-topbar :togglenav="togglenav" />
        <app-alerts />
        
        <v-main class="pt-2 pt-md-0">
            <v-btn class="bannermenubtn" v-scroll="onScroll" v-show="showdrawerbtn && !showdrawer" fab dark fixed top left color="primary" @click="showdrawer = !showdrawer" >{{ loc('Menu') }}</v-btn>
            <router-view />
        </v-main>

        <app-footer />
    </v-app>
</template>

<script>
import { mapState } from 'vuex';
// import AppBanner from '@/components/App_Banner'
import AppTopbar from "@/components/App_Topbar";
import AppAlerts from "@/components/App_Alerts";
import AppNavigation from "@/components/App_Navigation";
import AppFooter from "@/components/App_Footer";

export default {
    data() {
        return {
            showdrawer: false,
            showdrawerbtn: false,
        };
    },
    computed: {
        ...mapState(['users', 'localize'])
    },
    components: {
        AppNavigation,
        AppTopbar,
        AppFooter,
        AppAlerts,
    },
    methods: {
        togglenav: function () {
            this.showdrawer = !this.showdrawer;
        },
        onScroll (e) {
            if (typeof window === 'undefined') return
            const top = window.pageYOffset ||   e.target.scrollTop || 0
            this.showdrawerbtn = top > 120
        },     
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },            
    }
};
</script>

<style lang="scss">
$body-font-family: "Lato";
.body, 
.v-application {
    font-family: "Lato", "Popping", sans-serif;
}
</style>

<style src="../assets/app.css" ></style>