<template>
    <div id='alertcontainer'>
        <v-alert v-for="alert in statusMessages" :type="alert.type || 'info'" :key="alert.key" dense dismissable outlined >
            {{alert.message}}
        </v-alert>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
       ...mapState(["currentUser", "statusMessages", "now"])
    }

}
</script>