<template>
    <v-container v-if="display  && layout === 'vertical'">
        <v-list-group prepend-icon="language" v-model="showlanguages" >
            <v-list-item-content class="pl-8">
                <v-list-item-title>{{ loc('Choose a Language') }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item v-for="(language, key) in langlist" v-bind:key="key">
                <v-list-item-content class='pl-8'>
                    <v-list-item-title class='selectlang' @click="switchLanguage(key)">
                        {{ language }}
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>

        </v-list-group>
        <div style="position:relative" v-if="!isEditing && !options.preview && users.currentUser && users.currentUser.isEditor">
            <v-btn color="secondary" fab absolute small bottom right @click="toggleEditing" >
                <v-icon>edit</v-icon>
            </v-btn>
        </div>
    </v-container>
    <v-container v-else-if="display">
        <v-row>
            <v-col cols="12" ><h2>{{ loc('Choose a Language') }}</h2></v-col>
        </v-row>
        <v-row>
            <v-col v-for="(language, key) in langlist" v-bind:key="key">
                <v-btn text class="selectlang" @click="switchLanguage(key)">{{ language }}</v-btn>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: "LanguageList",
    props: {
        record: { type: Object }, 
        options: { type: Object, default: () => {return {}} }
    },
    data() {
        return {
            showlanguages: true,
            isEditing: false,
        }
    },
    computed: {
        ...mapState(['users', 'localize', 'navitems', 'siteconfig']),
        langlist: function() {
            return this.siteconfig && this.siteconfig.publiclanguages || [];
        },
        display: function() {
            return this.$vuetify.breakpoint.smAndDown
        }
    },
    methods: {
        loc: function (str) {
            return (this.localize && this.localize.languages && this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },
        switchLanguage: function(lang) {
            this.$store.dispatch("users/switchLanguage", lang )
                .then((result) => {
                    console.log('Nav.switchLanguage', result);
                    this.$vuetify.rtl = !!(lang === 'ar');
                })
        },
        toggleEditing: function() {
            this.$router.push("/admin/content/"+this.record._id)
        },
    }
}

</script>