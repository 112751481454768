<template>
    <div class="courselisting" v-if="this.courses.list">
        <v-row v-if="record && record.title" class="courselistingtitle mx-auto">
            <h1>{{record.title}}</h1>
        </v-row>
        <v-row class="justify-space-between" align="stretch" >
            <CoursesListingBlock v-for="item in courseList" :item="item" :id="item._id" :key="item._id+item.modified" />
            <div style="position:relative" v-if="!options.preview && users.currentUser && users.currentUser.isEditor">
                <v-btn color="secondary" fab absolute small top right :to="'/admin/content/'+record._id" >
                    <v-icon>settings</v-icon>
                </v-btn>
            </div>            
        </v-row>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import CoursesListingBlock from './Courses_Listing_Block';
import _filter from 'lodash/filter';
import _sortBy from 'lodash/sortBy';

const navgroup = [
    {title: "Courses", icon: "book", goto: "/Courses", group: "courses"}
]

export default {
    name: "CoursesListing",
    props: {
        record: { type: Object, default: () => ({}) },
        options: { type: Object, default: () => ({}) }
    },
    data () {
        return {};
    },
    components: {
        CoursesListingBlock
    },
    computed: {
        ...mapState(['navitems', 'users', 'courses', 'content', 'lessons', 'localize']),
        courseList(){
            let list = Object.values(this.courses.list);
            let lang = (this.users && this.users.lang) || 'en';
            list = _filter(list, (o) => { return o.status !== 'Inactive' && (o.lang === lang || (lang === "en" && !o.lang)) }) ;
            list = _sortBy(list, ['order', 'title']);
            return list;
        }
    },
    methods: {
        loadCourses: function(opts){
            this.$store.dispatch("courses/getList", opts)
                .then( (result) => {
                    // if(this.navitems && !this.navitems.courses) {
                    //     let coursenav = [...navgroup];
                    //     for(let i in result){
                    //         coursenav.push({title: result[i].title, goto:"/Course/"+result[i]._id})
                    //     }
                    //     this.$store.dispatch('setNav',{key: "courses", 'items': coursenav}, { root: true });
                    // } else {
                    //     console.log("Courses navitems already set", this.navitems);
                    // }
                    console.log('CourseListing', this.courses.list);
                })
        }
    },
    created () {
        let courselist = this.courses.list;
        console.log("Courses at list created", courselist);
        
        if( !Object.values(courselist).length ){
            this.loadCourses();
        }
    }
    
}
</script>