<template>
    <v-container>
        <v-row>
            <v-col id="register-intro" style="min-height:130px;" class='feature' >
                <ContentBlockSimple record="userpasswordchange" />
            </v-col>
        </v-row>
        <v-form ref="passwordchange" @submit.prevent="handleSubmit"  autocomplete="off">
            <v-row style='height:30px;' class='ma-3'>
                <v-alert v-if="submitalert" dense type="error" outlined>{{submitalert}}</v-alert>
            </v-row>
            <v-row>
                <v-col cols="12" >
                    <v-text-field 
                        outlined 
                        full-width 
                        prepend-icon="lock" 
                        name="password" 
                        v-model="password" 
                        autocomplete="false" 
                        :hint=" loc('At least 8 characters') "
                        :label="loc('Password')" 
                        id="password" 
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="show1 = !show1"
                        :type="show1 ? 'text' : 'password'"
                        @change="validMessage('password')" :error-messages="loc(password_error)"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" >
                    <v-text-field 
                        outlined 
                        full-width 
                        prepend-icon="lock" 
                        name="confirmpassword" 
                        v-model="confirmpassword" 
                        autocomplete="false" 
                        :label="loc('Confirm Password')" 
                        id="confirmpassword" 
                        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="show2 = !show2"
                        :type="show2 ? 'text' : 'password'"
                        @change="validMessage('confirmpassword')" 
                        :error-messages="loc(confirmpassword_error)"                         
                    ></v-text-field>                    
                </v-col>
            </v-row>
            <v-row>
                <v-btn class="mr-4" type="submit" color="primary" >{{ loc("Submit") }}</v-btn> 
                <v-btn class="mr-4" color="secondary" :to="'/'" >{{ loc("Leave") }}</v-btn>
            </v-row>
        </v-form>
    </v-container>
</template>

<script>
import { mapState } from 'vuex';
import ContentBlockSimple from './Content_Block_Simple';
import { validate } from '../lib/filter';

const fieldSchema = {
    password: {
        type: 'password',
        invalidmessage: 'A password is required, and must include at least one special character.'
    },
    confirmpassword: {
        type: 'matches',
        matches: 'password',
        invalidmessage: 'Passwords must match.'
    }    
}

export default{
    name: "PasswordChange",
    data () {
        return {
            submitalert: false,
            email: '',
            email_error: '',
            password: '',
            password_error: '',
            confirmpassword: '',
            confirmpassword_error: '',
            show1: false,
            show2: false,            
            sendto: false
        }
    },
    components: { ContentBlockSimple },
    computed: {
        ...mapState(['users', 'localize', 'content'])
    },
    methods: {
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },
        validMessage: function(field) {
            this[field + '_error'] = validate(this[field], fieldSchema[field], fieldSchema[field].matches && this[fieldSchema[field].matches]) || ''; 
        },
        handleSubmit: function() {
            let valid = true;
            let regobj = {};

            for(let k in fieldSchema){
                if(this[k + '_error'] && this[k + '_error'].length ) valid = false;
                regobj[k] = this[k];
            }

            if(!valid){
                this.submitalert = this.loc("Please correct the highlighted fields before submitting the form.")
                return;
            }

            let doc = {
                email: this.users.currentUser.email,
                password: this.password
            }
            this.$store.dispatch("users/passwordReset", doc)
                .then((result) => {
                    if(result.info || result.error){
                        let message = (result.info && result.info.message) || (result.error && result.error.message)
                        this.submitalert = this.loc(message);
                    } else {
                        console.log('password reset component got result', result);
                        // this.$router.push(this.sendto || '/login');
                    }
                })
                .catch((e) => {
                    console.log("error submitting password reset", e);
                    this.submitalert = this.loc("There was an error submitting the form.");
                })            

        }
    }
}

</script>
