<template>
    <span class="optoutspan">
        <a href="{{ linkURI }}" v-if="showCookieOptin" @click="handleClick" >{{ linkText }}</a>
        <a href="{{ linkURI }}" v-if="!showCookieOptin" @click="handleClick">{{ linkText }}</a>
</span>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: "ContentCookeOptoutLink",
    props: {},
    data (){ 
        return {} 
    },
    computed: {
        ...mapState(['users', 'content', 'localize']),
        showCookieOptIn () {
            return this.users.cookieoptin && (this.users.cookieoptin === 'prompt' || (typeof this.users.cookieoptin === 'object' && this.users.cookieoptin.setting === 'prompt'));
        },  
        linkURI () {
            return "#toggleoptin"
        },
        linkText () {
            let str = (this.showCookieOptIn) ? "Reset Cookie Opt In" : "You are opted out";
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },
    }, 
    methods: {
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },   
        handleClick: function(evt) {
            console.log("Click!", evt)
        }
    },
    created () {},
    watch: {},
}
</script>