import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

const axiosconfig = { headers: {} };

import {filterDate, filterId} from './filter';
import _filter from 'lodash/filter';

export default {
    namespaced: true,
    state: {
        list: false,
        materials: false,
        materialslist: false,
        gotAll: false,
    },
    mutations: {
        removeLesson (state, val) {
            if(typeof val === "string") val = {_id: val};
            if(state.list[val._id]) Vue.delete(state.list, val._id);
        },
        setLesson (state, val) {
            if(val && val._id){
                if(val.materials) delete val.materials;
                if(!state.list) state.list = {};
                Vue.set(state.list, val._id, val);
            }
        },
        setList (state, val) {
            state.list = state.list || {};
            for(let k in val){
                if(val[k].materials) delete val[k].materials;
                Vue.set(state.list, val[k]._id, Object.assign({}, state.list[val[k]._id] || {}, val[k]) );
            }
        },
        setMaterials (state, val) {
            state.materials = state.materials || {};
            
            for(let k in val){
                if(val[k] && val[k].lesson){ 
                    state.materials[val[k].lesson] = state.materials[val[k].lesson] || {};
                    Vue.set(state.materials[val[k].lesson], val[k]._id, val[k] );
                } else {
                    console.log("setMaterials missing lessonid?", val[k]);
                }
            }
        },
        setMaterialsList (state, val) {
            state.materials = Vue.set(state, 'materialslist', val)
        },
        removeMaterials (state, val) {
            if(val && val._id && val.lesson){
                if(state.materials[val.lesson] && state.materials[val.lesson][val._id]){
                    Vue.delete(state.materials[val.lesson], val._id);
                } else {
                    console.log("can't find", val, state.materials);
                }
            } else {
                console.log('invalid val in mutation');
            }
        },
        toggleGotAll (state, val) {
            state.gotAll = state.gotAll || {};
            state.gotAll[val] = !state.gotAll[val];
        }
    },
    actions: {
        getList ({ commit, state }, data) {
            if(data && data.lessonid) delete data.lessonid;
            return axios.post("/app_lessons/list", data, axiosconfig)
                .then((response) => {
                    let result = response && response.data;
                    // console.log("getList response", data, response);
                    if(result){
                        let lessonslist = {}
                        for(let k in result){
                            lessonslist[k] = {...result[k]};
                            result[k].created = result[k].timestamp ? filterDate(result[k].timestamp, 'YYYY-MM-DD') : "";
                            if(result[k].materials) commit('setMaterials', Object.assign({}, result[k].materials));
                        }
                        commit('setList', {...lessonslist});
                    }
                    // console.log("getList result returning", result);
                    return result;
                })
                .catch((e) => {
                    console.log('error getting lesson list', e)
                })
        },
        get ({ commit, state, dispatch }, data) {
            if(!data) return Promise.reject("Missing parameters for getting lesson");
            if(typeof data === 'string') data = { _id: data };

            if(data.nocache){
                delete data.nocache;
            } else if(data._id && state.list && state.list[data._id] && state.materials && state.materials[data._id]){ 
                let record = Object.assign({}, state.list[data._id]);
                record.materials = Object.assign({}, state.materials[data._id]);
                record.cached = true;
                return Promise.resolve(record);
            }

            return axios.post("/app_lessons/get", data)
                .then((response) => {
                    let result = response && response.data;
                    if(result){
                        let record = Object.assign({}, result);
                        if(record.materials){
                            commit('setMaterials', Object.assign({}, record.materials));
                        }
                        commit('setLesson', record);
                        // if(!state.gotAll[result.course]){ Vue.$nextTick(() => dispatch('getAll', result.course));}
                    }
                    return result;
                })
                .catch((e) => {
                    console.log('error getting lesson', data, e);
                })
        },
        preload ({ commit, state, dispatch }, data) {
            if(!data || state.gotAll[data]) return;
            commit('toggleGotAll', data);
            let list = _filter(state.list, {course: data});
            if(!list || !list.length){
                console.log("No lessons to preload", list);
                return Promise.reject({error: 'nothing to do?'});
            }
            // let timer;
            const getOne = () => {
                if(!list || !list.length){  return true; }
                let lrec = list.pop();
                if(!state.materials || !state.materials[lrec._id]) dispatch('get', lrec._id);
                return getOne();
                // timer = setTimeout(getOne, 100);
            }
            return getOne();
        },
        remove ({commit, state}, data) {
            if(!data || !data._id) return Promise.reject("Missing ID");

            commit('removeLesson', data);
            return axios.post("/app_lessons/remove", data, axiosconfig)
                .then((response) => {
                    let result = response && response.data;
                    return result;
                })
                .catch((e) => {
                    console.log('error removing content', e);
                })
        },
        save ({commit, state }, data) {
            if(!data._id || !data.course) Promise.reject("You had two jobs...");

            let path = '/app_lessons/save';
            return axios.post(path, data, axiosconfig)
                .then((result) => {
                    let record;
                    if(result && result.data && result.data.value){
                        record = result.data.value;
                    } else if(result && result.data){
                        record = result.data;
                    } else {
                        console.log('error in lessons.save?', result);
                        return Promise.reject("That apparently didn't work");
                    }
                    if(!record.created && record.timestamp) record.created = filterDate(record.timestamp, 'YYYY-MM-DD');
                    commit('setLesson', record);
                    if(state.list.new) commit('removeLesson', 'new');
                    return record;
            })
                .catch((error) => {
                    console.log('error in lessons.save',error, error.response);
                    return error.response;
                })     
        },
        saveMaterials ({commit, state}, data) {
            if(!data || !data._id || !data.lesson) return Promise.reject("Invalid lesson materials record.")

            return axios.post("/app_materials/save", data, axiosconfig)
                .then((result) => {
                    result = result && result.data;
                    let record = {};
                    record[result._id] = result;               
                    commit('setMaterials', record);
                    return result;
                })
        },
        removeMaterials ({commit, state}, data) {
            if(!data || !data._id || !data.lesson ) return Promise.reject("Invalid lesson materials record.")

            return axios.post("/app_materials/remove", data, axiosconfig)
                .then((result) => {
                    let record = result && result.data;
                    commit('removeMaterials', data);
                })
        },
        /**
         * This is used to get a list of materials, not to get all materials.  Use get
         * to get the lesson and its materials.
         */
        listMaterials ({commit, state}, data) {

            return axios.post("/app_materials/list", {shortfields: true})
                .then((result) => {
                    let record = result && result.data;
                    commit('setMaterialsList', record);
                    return record;
                });
        },
        getExam ({commit, state}, data) {
            if(!data || !data.course) return Promise.reject("A course ID is required to get an exam.");
            let opts = {course: data.course};
            if(data.isAdmin) opts.isAdmin = true;
            return axios.post("/app_materials/exam", opts)
                .then((result) => {
                    let record = result && result.data;
                    // commit('setMaterialsList', record);
                    return record;
                });
        }
    }
}
