<template>
    <v-container class="pt-8">
        <div v-if="isLoading">
            {{ loc('Loading...') }}
        </div>
        <div v-else-if="panel === 'enrollment' && classRecord && classRecord._id">
            <ClassesEnrollment :classRecord="classRecord" />
        </div>
        <div v-else-if="panel === 'edit' && classRecord && classRecord._id">
            <ClassEdit :classRecord="classRecord" v-on:actionhandler="handleAction"/>
        </div>
        <div v-else-if="panel === 'create'" >
            <ClassEdit :classRecord="{_id: 'new'}" v-on:actionhandler="handleAction"/>
        </div>
        <div v-else-if="panel === 'confirmation'">
            <ContentBlock :record="{_id: 'createclassconfirmation'}" :options="getcontentoptions || {}" :fields="confirmationfields" />
        </div>
        <div v-else>
            <v-row>Panel: {{panel}}</v-row>
            <v-row>Class ID: {{classid}}</v-row>
            <v-row>Course ID: {{courseid}}</v-row>
            <v-row v-if="classRecord"><h4>Class Record:</h4><pre>{{classRecord}}</pre></v-row>
            <v-row v-else><h4>Class List:</h4><pre>{{classes}}</pre></v-row>

        </div>
    </v-container>
</template>

<script>
import { mapState } from 'vuex';

import ClassesEnrollment from './Classes_Enrollment.vue';
import ClassEdit from './Class_Edit.vue';
import ContentBlock from './Content_Block';
import { filterId } from '../lib/filter';

let initretries = 0;

export default {
    name: "Classes",
    metaInfo () {},
    data () {
        return {
            classid: false,
            classRecord: false,
            courseid: false,
            isLoading: true, 
            panel: false,
            getcontentoptions: false
        }
    },
    components: { ClassesEnrollment, ClassEdit, ContentBlock },
    computed: {
        ...mapState(['classes', 'localize', 'users', 'siteconfig']),
        confirmationfields: function(){
            if(!this.classrecord || !this.users || !this.users.currentUser) return {};
            return {
                regcode: this.classRecord.regcode || "",
                'account.firstname': this.users.currentUser.firstname || "",
                startdate: this.classRecord.startdate || ""
            }
        }
    },
    methods: {
        handleAction: function(action, params, opts) {
            console.log(opts);
            switch(action){
                case 'setpanel':
                    this.panel = params;
                    this.getcontentoptions = {getprops: {regcode: opts.regcode, render: 'true' }, load: true};
                    console.log('getcontentoptions', this.getcontentoptions)
                    break;
            }
        },
        init: function () {
            console.log('Classes init', this.$route.params, this.$route.query);

            if(this.$route.params.panel && this.$route.params.panel !== this.panel) this.panel = this.$route.params.panel;
            if(this.$route.params.classid && this.$route.params.classid !== this.classid) this.classid = this.$route.params.classid;
            if(this.$route.query && this.$route.query.regcode) this.getcontentoptions = { getprops: { regcode: filterId(this.$route.query.regcode), render: 'true' } };

            if(!this.panel){ this.$router.push('/Courses'); return; }

            if(!this.classes || !this.classes.list && initretries < 4){
                initretries++;
                console.log("classes.init retry", initretries);
                return setTimeout(this.init, 333);
            }
            if(!this.classid && this.classes.list && this.classes.list[this.panel] ){
                this.classid = this.panel;
                this.classRecord = this.classes.list[this.panel];
                this.panel = false;
            } else if(this.classes && this.classes.list && this.classes.list[this.classid]){ 
                this.classRecord = this.classes.list[this.classid]; 
            }
            if(this.classRecord) this.courseid = this.classRecord.course;

            if(
                (this.users.currentUser && this.users.currentUser.isAdmin) || 
                (this.classRecord && this.users && this.users.currentUser && this.users.currentUser._id && this.users.currentUser._id === this.classRecord.instructor) || 
                (this.users && this.users.currentUser && this.users.currentUser.classes && this.users.currentUser.classes[this.classid] ) || 
                (this.users && this.users.currentUser && this.siteconfig && this.siteconfig.createclasswithoutcerts && this.panel === 'create')
            ){
                console.log("let me in!")
            } else {
                console.log('sending to Course page', this.panel)
                this.$router.push('/Course/' + this.courseid);
                return;
            }

            if(this.courseid && !this.panel){
                this.$router.push('/Course/' + this.courseid + ((this.classid) ? ('/' + this.classid) : '' ));
                return;
            }

            this.isLoading = false;
        },
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },   
    },
    mounted () {
        this.init()
    },
    watch: {}
}
</script>