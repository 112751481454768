import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import {filterId,formatDateTime} from './filter';

const axiosconfig = { headers: {} };

export default {
    namespaced: true,
    state: {
        list: false,
        preloaddelay: 500
    },
    mutations: {
        resetList (state, val) {
            // state.list = {};
            Vue.set(state, 'list', {});
        },
        setItem (state, val) {
            state.list = state.list || {};
            if(val && val._id){ 
                val.key = Date.now().toString(36);
                Vue.set(state.list, val._id, val); 
            }
            if(val.cards) Vue.set(state.list[val._id], 'cards', val.cards);
            // console.log("mutated item in content", val);
            // state.list[val._id] = val;
        },
        setList (state, val) {
            // state.list = state.list || {};
            if(!state.list) Vue.set(state, 'list', {});
            for(let k in val){
                Vue.set(state.list, val[k]._id, Object.assign({}, state.list[val[k]._id] || {}, val[k]) );
            }
            console.log('content.setList', val);
        },
        setPreloaddelay (state, val) {
            state.preloaddelay = val || 0;
        },
        removeContent (state, val) {
            if(typeof val === "string") val = {_id: val};
            if(state.list[val._id]) Vue.delete(state.list, val._id);
        }

    },
    actions: {
        clearAll ({commit, state}) {
            console.log("content clearAll")
            commit('setList', false);
            return Promise.resolve();
        },
        preload ({commit, state}) {
            return Promise.resolve();

            // @TODO consider preloading some content pages that are likely to be used.
            
            // let delay = state.preloaddelay && (state.preloaddelay - 0 == state.preloaddelay) ? state.preloaddelay - 0 : 0;

            // setTimeout(() => {
            //     
            // }, delay);

        },
        getList ({ commit, state }, options) {
            console.log('store.content.getList', options);

            options = options || {};
            if(options.reset){
                commit('resetList');
                delete options.reset;
            }

            return axios.post("/app_content/list", options, axiosconfig)
                .then((response) => {
                    let result = response && response.data;
                    let list = {};
                    let llength = result && result.length;
                    if(llength){
                        for(let i=0;i<llength;i++){
                            result[i].timestamp = formatDateTime(result[i].timestamp);

                            list[result[i]._id] = result[i];
                        }
                    }
                    // console.log("content.getList", list);
                    commit('setList', list);
                    return list;
                })
        },
        get ({ commit, state }, options) {
            options = options || {};
            
            console.log("store_content getting item", options);
            if(!options._id) return Promise.reject("Missing ID in content.get");
            const id = options._id;

            if(state.list && state.list[id] && !options.remote){
                return Promise.resolve(state.list[id]);
            }

            // delete options._id;
            if(options.remote) delete options.remote;

            return axios.post("/app_content/get", options, axiosconfig)
                .then((data) => {
                    // console.log('data in content.get', data && (data.data || data));   
                    let result = data && data.data;
                    if(result && typeof string === "object"){
                        result.timestamp = formatDateTime(result.timestamp);
                        if(result) commit('setItem', result);   
                    }  
                    return result;
                })
                .catch((e) => {
                    console.log("ERROR in store_content.get", e);
                    return options;
                })
        },
        loadPage ({ commit, state }, options) {

            if(!options.context || options.context !== filterId(options.context)){ 
                console.log('store.content.loadPage missing params', options);
                return Promise.reject("Context is required.");
            }

            let context = options.context;
            if(options.subid) context += '/' + filterId(options.subid);

            return axios.get("/app_content/getpage/" + context, axiosconfig)
            .then((data) => {
                // console.log(data && (data.data || data));   
                let result = data && data.data;
                let list = {};
                if(result && result.length){
                    let len = result.length;
                    
                    for(let i=0;i<len;i++){
                        result[i].timestamp = formatDateTime(result[i].timestamp);
                        commit('setItem', result[i]);
                        list[result[i]._id] = result[i];
                    }

                }
                return list;
            })            

        },
        save ({ commit, state }, data) {
            if(!data || !data._id) return Promise.reject("Missing ID");

            let item = Object.assign({}, state.list[data._id] || {}, data);
            item.body = data.body;

            console.log("Saving content", item);
            return axios.post("/app_content/save", data, axiosconfig)
                .then((response) => {
                    console.log('content posted', response);
                    let result = response && response.data;
                    if(result){ 
                        if(!result.info && !result.error){
                            console.log("setting content", result);
                            commit('setItem', result);
                            if(state.list.new) commit('removeContent', 'new');
                        }
                        return result;
                    } 
                    return response;                    
                })
                .catch((e) => {
                    console.log('error posting content', e);
                })
        },
        remove ({ commit, state}, data) {
            if(!data || !data._id) return Promise.reject("Missing ID");

            console.log("Removing content", data._id);
            commit('removeContent', data);
            return axios.post("/app_content/remove", data, axiosconfig)
                .then((response) => {
                    console.log('content posted', response);
                    let result = response && response.data;
                    return result;
                })
                .catch((e) => {
                    console.log('error removing content', e);
                })

        },
        addNew ({ commit, state}, data) {
            data = data || 'page';
            console.log('store.content.addNew', data);
            commit('setItem', { 
                _id: 'new',
                type: data
            });
            return state.list.new;            
        },
        vimeo ({ commit, state}, data) {

            return axios.get("https://vimeo.com/api/oembed.json", data)
                .then((response) => {
                    console.log(response);
                })
        }
    },
    getters: {
        // currentUser: state => state.currentUser
        // checkIds: state => (state.checks && Object.keys(state.checks)) || []
    }    
}
