<template>
    <v-container>
        <v-row class="mb-12">
            <v-col><h1>{{loc('State Explorer') + ((panel && ' - ' + panel) || "")  }}</h1></v-col>
            <v-spacer></v-spacer>
            <v-col v-if="panel"><v-btn small @click="panel=false">Close</v-btn></v-col>
        </v-row>
        <v-row>
            <p>This page shows the current data "state" of the application in the browser.  If you refresh the page this will be reset.</p>
        </v-row>
        <v-row v-if="panel">
            <CodeEditor :data="mod(panel)" :handleChange="handleChange" :options="{height:'1200px'}" mode="json" />
        </v-row>
        <div v-else>
            <v-row class="m6-8"><h3>{{ loc('Modules') }}</h3></v-row>
            <v-row>{{ loc('Click a module below to see the data for this module currently loaded in the browser-side application.') }}</v-row>
            <v-row>
                <v-list>
                    <v-list-item v-for="(item, key) in schema" v-bind:key="key" @click="panel = key">
                        <v-list-item-title>{{key}}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-row>
        </div>
    </v-container>
</template>

<script>
import { mapState } from "vuex";

import CodeEditor from "./Shared_Editor_Code";


const schema = {
    users: {},
    content: {},
    localize: {},
    courses: {},
    lessons: {},
    classes: {},
    enrollment: {}, 
    comments: {}, 
    files: {},
    siteconfig: {},
    surveys: {},
    navitems: {},
    statusMessages: {}
}

export default {
    data() {
        return {
            panel: false,
        }
    }, 
    components: {CodeEditor},
    computed: {
        ...mapState([
            "authError", "users", "content", "files","localize", "adminstore",
            "classes","enrollment","comments", "courses", "lessons", "navitems", "siteconfig", "surveys"
        ]),
        schema: function(){ return schema }
    },
    methods: {
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },          
        mod: function(key){
            if(schema[key]){
                return JSON.stringify(this[key], null, "\t");
            }
        },
        handleAction: function(action, params, opts){
            switch(action){
                case 'close':
                    this.panel = false;
                    break;
            }
        },
        handleChange: function(){ return }
    }

}
</script>

<style>

</style>