<template>
    <div v-if="isEditor && isEditing && contentRecord" class="contentblock">
        <SharedEditor 
            :record="contentRecord" 
            :handleSave="handleSave" 
            :handleClose="toggleEditing"
        />
    </div>
    <div v-else class="contentblock">
        <v-expansion-panels class="accordian-panel-container">
            <v-expansion-panel v-for="(item, idx) in blocks" v-bind:key="'item-'+idx" class="accordian-panel">
                <v-expansion-panel-header class="accordian-title" v-html="item.value"></v-expansion-panel-header>
                <v-expansion-panel-content class="accordian-body">
                    <p v-for="(para, pidx) in item.paragraphs" v-bind:key="'item-'+idx+'-'+pidx" v-html="para || ''"></p>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <div style="position:relative" v-if="!options.preview && isEditor">
            <v-btn color="secondary" fab absolute small bottom right @click="toggleEditing" >
                <v-icon>edit</v-icon>
            </v-btn>
        </div>        
    </div>
</template>

<script>
import { mapState } from 'vuex';
import SharedEditor from './Shared_Editor';

export default {
    name: "ContentBlockAccordians",
    props: {
        record: {
            type: [Object, String]
        },
        options: {
            type: Object,
            default: () => { return {}}            
        }
    },
    data () {
        return {
            recordId: this.record && (this.record._id || this.record),
            contentRecord: false,
            isEditing: false,
            // isEditor: false,
            found: false,
            isLoading: false
        }
    },
    components: { SharedEditor },
    computed: {
        ...mapState(['users', 'content', 'localize']),
        isEditor: function(){
            return this.users && this.users.currentUser && this.users.currentUser.isEditor;
        },
        blocks: function(){
            let str = (this.contentRecord && (this.contentRecord.body || this.contentRecord.description)) || "";
            if(!str) return [];
            return this.parseHtml(str);
            // return (this.contentRecord && (this.contentRecord.body || this.contentRecord.description)) || "";
        }
    },
    methods: {
        getContent: function (opts) {
            console.log('ContentBlockSimple.getContent', this.recordId);
            let querydoc = { _id: this.recordId };
            if (this.record && this.record.type) querydoc.type = this.record.type;
            this.$store.dispatch('content/get', querydoc)
                .then((result) => {
                    // console.log('ContentBlockSimple got a block', this.recordId, result);
                    if (result) {
                        this.contentRecord = this.contentRecord || {};
                        for (let k in result) {
                            if (result[k] != this.contentRecord[k]) {
                                // console.log("A change?", k, result[k], this.contentRecord[k]);
                                this.$set(this.contentRecord, k, result[k]);
                            }
                        }
                    }
                })
                .catch((e) => {
                    console.log('error in ContentBlockSimple', e);
                })
        },
        toggleEditing() {
            console.log('toggleEditing', this.isEditing, this.contentRecord);
            this.isEditing = !this.isEditing;
            this.$emit("actionhandler", { action: 'toggleedit', editing: this.editing });
        },
        handleEditorActions: function (action, doc) {
            console.log('handleEditorActions', action);
            switch (action) {
                case 'close':
                    this.toggleEditing();
                    break;
                case 'save':
                    this.handleSave(doc)
                case 'toggleedit':
                    this.toggleEditing();
                    break;
            }
        },
        handleSave(change, next) {
            console.log('entering handleSave', change);
            this.contentRecord = this.contentRecord || {};
            this.$set(this.contentRecord, 'body', change);

            let doc = Object.assign({}, this.contentRecord, {
                body: change,
                lang: this.users.lang,
                subtype: 'accordian'
            });


            this.$store.dispatch('content/save', doc)
                .then((result) => {
                    console.log("ContentBlockSimple.handleSave result", result);
                    for (let k in result) {
                        if (result[k] != this.contentRecord[k]) {
                            // console.log("A change?", k, result[k], this.contentRecord[k]);
                            this.$set(this.contentRecord, k, result[k]);
                        }
                    }
                    if (next === 'close') this.toggleEditing();
                })
                .catch((err) => {
                    console.log("Error in handleSave", err);
                })
        },
        parseHtml: function (htmlString) {
            const result = [];
            const temp = document.createElement('div');
            temp.innerHTML = htmlString;

            let currentHeader = null;
            let currentParagraphs = [];

            let child = temp.firstChild;
            while (child) {
                const tag = child.tagName;
                const value = (tag === 'P') ? child.innerHTML.trim() : child.textContent.trim();

                if (tag === 'H1' || tag === 'H2' || tag === 'H3' || tag === 'H4' || tag === 'H5' || tag === 'H6') {
                    // If there was a previous header and paragraph(s), add them to the result
                    if (currentHeader && currentParagraphs.length > 0) {
                        result.push({ tag: currentHeader.tagName, value: currentHeader.innerHTML.trim(), paragraphs: currentParagraphs });
                    }
                    // Start a new header and clear the current paragraphs array
                    currentHeader = child;
                    currentParagraphs = [];
                } else if (tag === 'P') {
                    currentParagraphs.push(value);
                }

                child = child.nextSibling;
            }

            // Add the last header and paragraph(s) to the result
            if (currentHeader && currentParagraphs.length > 0) {
                result.push({ tag: currentHeader.tagName, value: currentHeader.innerHTML.trim(), paragraphs: currentParagraphs });
            }
            console.log("Returning blocks", result);
            return result;
        },
    },
    created (){
        console.log('ContentblockSimple.created', this.record)
        if(!this.options || !this.options.noload){
            this.getContent();
        } else {
            this.contentRecord = (typeof this.record === 'string') ? {_id: this.record} : this.record;
        }
    } 
}

</script>