<template>
    <v-row class="contentblock" align="stretch" >
        <v-col v-if="isEditor && isEditing && !isSaving" cols="12" class="contentcardstitle">
            <v-text-field name="title" v-model="title" label="Block Title"></v-text-field>
        </v-col>        
        <v-col v-if="record.title" cols="12" class="contentcardstitle">
            <h1>{{record.title}}</h1>
        </v-col>
        <v-card v-if="isSaving" class="contentcard" >
            <v-card-subtitle>{{ loc("Saving") }}</v-card-subtitle>
        </v-card>
        <v-col v-else class="cardcontentcol" v-for="(item, idx) in cardsList" v-bind:key="idx" sm="12" :md="cols">
            <v-card v-if="(!options || !options.preview) && item && isEditor && isEditing && isEditing.key === item.key" class="contentcard" >
                <v-card-title>
                    Editing Card : {{idx}} : {{isEditing.key}} : {{editingIdx}}
                </v-card-title>
                <v-card-text class="pa-4">
                    <v-text-field
                        name="video"
                        v-model="isEditing.video" 
                        :label="loc('Video or Image URL (if relevant)')"
                    ></v-text-field>
                    <SharedEditor 
                        :record="isEditing" 
                        :handleChange="handleBodyChange" 
                        :handleClose="closeEdit"
                        :handleSave="saveCard"
                        field="description" 
                    />
                </v-card-text>
            </v-card>
            <v-card v-else class="contentcard" flat>
                <SharedMediaPlayer v-if="item.video" :record="item" />
                <v-card-text v-html="item.description"></v-card-text>
                <v-card-actions v-if="(!options || !options.preview) && !isEditing && isEditor">
                    
                    <v-btn color="secondary" small @click="isEditing = item">{{ loc('Edit') }}</v-btn>
                    <v-btn color="error" small @click="handleRemoveClick(item, idx)">{{ loc('Remove') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
        <v-col v-if="(!options || !options.preview) && isEditor && !isEditing" style="position:relative" cols="12">
            <v-btn right color="primary" small @click="addCard" id="addcardbtn">{{ loc("Add Card") }}</v-btn>
        </v-col>
        <div style="position:relative" v-if="!options.preview && users.currentUser && users.currentUser.isEditor">
            <v-btn color="secondary" fab absolute small top right :to="'/admin/content/'+record._id" >
                <v-icon>settings</v-icon>
            </v-btn>
        </div>
    </v-row>
    
</template>

<script>
import { mapState } from 'vuex';
import SharedEditor from './Shared_Editor';
import _find from 'lodash/find';
import _filter from 'lodash/filter';

export default {
    name: "ContentBlockCards",
    props: {
        record: {
            type: [Object, String]
        },
        options: {
            type: Object,
            default: () => { return {}}
        }
    },
    data () {
        return {
            recordId: this.record && (this.record._id || this.record),
            // contentRecord: false,
            title: this.record && this.record.title,
            isEditing: this.options && this.options.isEditing,
            editingIdx: this.options && this.options.isEditing && this.options.isEditing.idx,
            isLoading: false,
            isSaving: false,
            removed: false,
            retries: 0
        }
    },
    components: {
        SharedEditor,
        SharedMediaPlayer: () => import(/* webpackPrefetch: true */ './Shared_Media_Player'),
    },
    computed: {
        ...mapState(['users', 'content', 'localize']),
        isEditor: function(){
            return this.users && this.users.currentUser && this.users.currentUser.isEditor;
        },
        cardsList: function(){
            let cards = this.content && this.content.list && this.content.list[this.record._id] && this.content.list[this.record._id].cards;
            let list = (cards) ? [...cards] : [];
            console.log("cards", cards, list);
            if(this.isEditing){
                if( _find(list, {key: this.isEditing.key}) ){
                    console.log("Editing", this.isEditing.key)
                } else {
                    list.push(this.isEditing);
                }
            }
            // the number of elements in these arrays should be fairly small, so we're ok with moderately
            // inefficient handling.
            if(this.removed){
                let remove = false;
                for(let i=0; i<= list.length; i++){
                    if(this.removed && this.removed === list[i].key){ 
                        remove = i; 
                        break; 
                    }
                }
                list.splice(remove,1); 
            }
            for(let i=0; i<= list.length; i++){
                list[i] && (list[i].idx = i);
            }
            return list;
        },
        cols: function(){
            let cards = this.content && this.content.list && this.content.list[this.record._id] && this.content.list[this.record._id].cards && this.content.list[this.record._id].cards.length;
            console.log("reticulating cols", cards);
            if(cards < 2) return 12;
            if(cards == 2) return 6;
            if(cards == 4) return 3;
            if(cards < 6) return 4;
            return 6;
        }
    },
    methods: {
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },     

        addCard: function(oldkey){
            let key =  Date.now().toString(36);
            let item = {
                key: key,
                video: "",
                description: "foo"
            };
            this.isEditing = item;
            this.editingIdx = (this.record && this.record.cards && this.record.cards.length && this.record.cards.length) || 0
            return item;
        },
        closeEdit: function(){
            this.editingIdx = false;
            this.isEditing = false;
        },
        saveCard: function(description){
            console.log('saving', idx, this.isEditing);

            let order = this.record.order;
            if(!order){
                let filter = {context: this.record.context};
                if(this.record.lang || this.users.lang) filter.lang = this.record.lang || this.users.lang;
                let contextcontent = this.content.list && _filter(this.content.list, filter);
                order = (contextcontent) ? (contextcontent.length + 1) : 1;
            }

            let doc = {
                _id: this.record._id,
                title: this.title,
                context: this.record.context,
                type: this.record.type || "block",
                subtype: this.record.subtype || "cards",
                lang: this.record.lang || this.users.lang,
                order: order,
                cards: this.cardsList || [],
                timestamp: this.record.timestamp || Date.now()
            };
            this.isEditing.description = description;
            let idx = this.editingIdx || this.isEditing.idx || (this.record && this.record.cards && (this.record.cards.length - 1)) || 0;
            if(!idx || idx < 0) idx = 0;
            doc.cards[idx] = this.isEditing;
            this.isSaving = true;
            this.$store.dispatch('content/save', doc)
                .then((result) => {
                    console.log("ContentBlockCards.saveCard result", result);
                    // this.isEditing = false;
                    // this.contentRecord.cards = [...result.cards];
                    this.isSaving = false;
                });

        },
        handleRemoveClick: function(item, idx){
            console.log("handleRemoveClick", idx, item);
            if((!idx && idx !== 0) || !this.record || !this.record.cards || !this.record.cards[idx] || item.key !== this.record.cards[idx].key) return;
            let doc = {
                _id: this.record._id,
                cards: [...this.record.cards]
            };
            doc.cards.splice(idx, 1);
            this.isSaving = true;
            this.removed = item.key;
            this.$store.dispatch('content/save', doc)
                .then((result) => {
                    console.log("ContentBlockCards.handleRemoveClick result", result);
                    // this.isEditing = false;
                    // this.contentRecord.cards = [...result.cards];
                    this.isSaving = false;
                });
            
        },
        handleBodyChange: function(a,b,c){
            // console.log('handleBodyChange',a,b,c)
            this.isEditing.description = a;
        },
        init: function(){
            if(!this.isEditor){ 
                console.log("User is not an editor?", this.isEditor);
                return; 
            }

            if(!this.record){
                if(this.retries > 3) return;
                this.retries++;
                setTimeout(this.init, 500)
                console.log("ContentBlockCards waiting for a record", this.retries);
            } else {
                if(!this.record.cards || !this.record.cards.length) this.addCard();
            }

        }

    },
    created (){
        this.init();
    }
}
</script>