<template>
<v-container>
        <v-row id="banner2" class="banner2" v-if="showbanner2">
            <v-col><img :src="loc('_sitelogo')" id='sitelogo' @click="goto('/')" /></v-col>
            <v-spacer></v-spacer>
            <v-col class="banner2item " cols="12" md="auto">
                <v-btn text to="/HowItWorks" dark :class="(activetab == 'HowItWorks' && 'activebanneritem')">{{ loc("How It Works") }}</v-btn>
            </v-col>
            <v-col class="banner2item" cols="12" md="auto">
                <v-btn text to="/Courses" dark :class="(activetab == 'Courses' && 'activebanneritem')">{{ loc("Our Courses") }}</v-btn>
            </v-col>
            <v-col class="banner2item" cols="12" md="auto" >
                <v-btn text to="/Resources" dark :class="(activetab == 'Resources' && 'activebanneritem')">{{ loc("Resources") }}</v-btn>
            </v-col>
            <v-col class="banner2item" cols="12" md="auto">
                <v-btn text to="/About" dark :class="(activetab == 'About' && 'activebanneritem')">{{ loc("About") }}</v-btn>
            </v-col>

        </v-row>
        <v-row v-else>
            &nbsp;
        </v-row>
    </v-container>
</template>

<script>

import { mapState } from 'vuex';

export default {
    props: {
        
    }, 
    data () {
        return {
            showbanner2: false
        }
    },
    computed: {
        ...mapState(['users', 'localize', 'content']),
        activetab() {
            return this.$route.params.id;
        }
        
    },
    methods: {
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },
        goto: function (str) {
            return this.$router.push(str);
        }
    },
    
}
</script>

