export default {
    icons: {
        // these icons are from https://material.io/tools/icons/
        // that is controlled in the vuetify iconfont setting below
    },
    vuetify: {
        icons: {
            iconfont: 'md'  // https://vuetifyjs.com/en/customization/icons
        },
        theme: {
            // Documentation: https://vuetifyjs.com/en/customization/theme
            primary: '#1976D2',
            secondary: '#424242',
            accent: '#82B1FF',
            error: '#FF5252',
            info: '#2196F3',
            success: '#4CAF50',
            warning: '#FFC107',
        },
    }
}
