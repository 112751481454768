<template>
    <v-container>
        <h2>{{ loc("Password Reset") }}</h2>
        <v-row>
            <v-col id="register-intro" style="min-height:130px;" class='feature' >
                <ContentBlockSimple :record="{type:'snippet', _id:'passwordreset', body:loc('Please enter your email below.  We will send you a new password.')}" :options="{load: true}"  />
            </v-col>
        </v-row>
        <v-form ref="passwordreset" @submit.prevent="handleSubmit">
            <v-row style='height:30px;' class='ma-3'>
                <v-alert v-if="submitalert" dense type="error" outlined>{{submitalert}}</v-alert>
            </v-row>
            <v-row>
                <v-col cols="12" md="4">
                    <v-text-field outlined v-model="email" :label=" loc('Email Address') "  @change="validMessage('email')" :error-messages="email_error"></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-btn class="mr-4" type="submit" color="primary" >{{ loc("Submit") }}</v-btn> 
            </v-row>
        </v-form>
    </v-container>
</template>

<script>
import { mapState } from 'vuex';
import ContentBlockSimple from './Content_Block_Simple';

export default{
    name: "PasswordReset",
    data () {
        return {
            submitalert: false,
            email: '',
            email_error: '',
            password: false,
            sendto: false
        }
    },
    components: { ContentBlockSimple },
    computed: {
        ...mapState(['users', 'localize', 'content'])
    },
    methods: {
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },
        validMessage: function() {
            // do we want to validate this?
        },
        handleSubmit: function() {
            let data = {
                email: this.email,
                password: this.password
            }
            this.$store.dispatch("users/passwordReset", data)
                .then((result) => {
                    if(result.info || result.error){
                        let message = (result.info && result.info.message) || (result.error && result.error.message)
                        this.submitalert = this.loc(message);
                    } else {
                        console.log('password reset component got result', result);
                        this.$router.push({
                            name: 'login',
                            params: {initialmessage: this.loc("If we found that email address we have sent it a temporary password.")}
                        });
                    }
                })
                .catch((e) => {
                    console.log("error submitting password reset", e);
                    this.submitalert = this.loc("There was an error submitting the form.");
                })            

        }
    }
}

</script>
